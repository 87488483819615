import React, {useState} from 'react'
import './FacilitySection.css'
import { faCalendarDays, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import Breadcrumb_holder from '../../Common_Component/Breadcrumb_holder/Breadcrumb_holder'
import CommonSuccessModal from '../../Common_Component/CommonSuccessModal/CommonSuccessModal'

const FacilitySection = () => {
    const [modalShow, setModalShow] = useState(false);
    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: "MBA in Human Resource Management" },
    ];
    return (
        <section className='faicility-section'>
            <Container>
                <Breadcrumb_holder breadcrumbs={breadcrumbs}  />
                <Row className='justify-content-center mt-5'>
                    <Col lg={5}>
                        <div className='search-fieldd'>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Search by Course / Specialization / University"
                                    aria-label="Search by Course / Specialization / University"
                                    aria-describedby="basic-addon2"
                                />
                                <InputGroup.Text id="basic-addon2"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                            </InputGroup>

                        </div>
                    </Col>
                </Row>
               
                <div className='mt-5'>
                    <Row >
                        <Col xl={10} className='mx-auto'>
                            <Row className='justify-content-center'>
                                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <p className='text-main'>Learn about HR analytics</p>
                                </Col>
                                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <p className='text-main'>1:1 mentoring sessions</p>
                                </Col>
                                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <p className='text-main'>Soft-skill enhancement</p>
                                </Col>
                                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <p className='text-main'>Understand organizational structuring</p>
                                </Col>
                                <Col xl={2} lg={2} md={3} sm={6} xs={6}>
                                    <div className='main-div-class'>
                                        <p className='txttt'><FontAwesomeIcon icon={faCalendarDays} className='me-1' /> 24 Months Program</p>
                                    </div>
                                </Col>
                                <Col xl={2} lg={2} md={3} sm={6} xs={6}>
                                    <div className='main-div-class'>
                                        <p className='txttt'> <img src={process.env.PUBLIC_URL + '/assests/Images/icon/record.png'} className='me-1 icon-imggg' /> 200+ Live & Recorded Classes</p>
                                    </div>
                                </Col>
                                <Col xl={2} lg={2} md={3} sm={6} xs={6}>
                                    <div className='main-div-class'>
                                        <p className='txttt'><img src={process.env.PUBLIC_URL + '/assests/Images/icon/casestudy.png'} className='me-1 icon-imggg' /> 100+ Case Studies & Study Guides</p>
                                    </div>
                                </Col>
                                <Col xl={2} lg={2} md={3} sm={6} xs={6}>
                                    <div className='main-div-class'>
                                        <p className='txttt'><img src={process.env.PUBLIC_URL + '/assests/Images/icon/seminar.png'} className='me-1 icon-imggg' /> 12+ Masterclasses</p>
                                    </div>
                                </Col>
                                <Col xl={2} lg={2} md={3} sm={6} xs={6}>
                                    <div className='main-div-class'>
                                        <p className='txttt'><img src={process.env.PUBLIC_URL + '/assests/Images/icon/naac.png'} className='me-1 icon-imggg' /> NAAC A+ Accreditation</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className='text-center mt-4'>
                    <button className='white-btnnn' onClick={() => setModalShow(true)}>Schedule a Call</button>
                </div>
                <CommonSuccessModal
                    modalheading={'Thank You for Showing Interest'}
                    modalsubheading={'Our Expert will get back to you soon to wipe off a confusion!'}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </Container>
        </section>
    )
}

export default FacilitySection