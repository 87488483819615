import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./IndustryExpert.css";
import Heading_holder from "../../Common_Component/Heading_holder/Heading_holder";
const IndustryExpert = () => {
  const IndustryExpert = [
    {
      image:
        process.env.PUBLIC_URL +
        "/assests/Images/Hompage/IndustryExpert/expert-name.png",
      text: "Lorem Ipsum",
      subtext:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea ex eacommodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assests/Images/Hompage/IndustryExpert/expert-name.png",
      text: "Lorem Ipsum",
      subtext:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea ex eacommodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assests/Images/Hompage/IndustryExpert/expert-name.png",
      text: "Lorem Ipsum",
      subtext:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea ex eacommodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assests/Images/Hompage/IndustryExpert/expert-name.png",
      text: "Lorem Ipsum",
      subtext:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea ex eacommodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
  ];
  return (
    <>
      <section className="IndustryExpert">
        <Container>
          <Heading_holder headingtext={"Industry Experts"} />

          <Swiper
            pagination={{ clickable: true, dynamicBullets: true }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {IndustryExpert.map((item, index) => (
              <SwiperSlide>
                <div className="expert-back">
                  <Row>
                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={3}>
                      <div className="main">
                        <div className="quatient">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assests/Images/Hompage/IndustryExpert/expert.png"
                            }
                            className="quatient "
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xxl={1} xl={1} lg={1} md={3} sm={3} xs={6}>
                      <div className="image-person">
                        <img src={item.image} className="quatient-main" />
                      </div>
                    </Col>
                    <Col xxl={9} xl={9} lg={9} md={9} sm={12} xs={12}>
                      <div className="content">
                        <p className="sub-text-main">
                          {item.text}
                          <span className="text">
                            (Designation: Lorem Ipsum)
                          </span>
                        </p>
                        <p className="text-main">{item.subtext}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </section>
    </>
  );
};

export default IndustryExpert;
