import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import UsuallyAskedQuetion from '../CollegeInfo/PopularProgramm/UsuallyAskedQuetion'
import CommonCourse from '../CollegeInfo/PopularProgramm/CommonCourse'
import ApplyNowBanner from '../../../../Common_Component/ApplyNowBanner/ApplyNowBanner'
import FeeStructure from './FeeStructure/FeeStructure'

const CoursesAndFees = () => {
  return (
    <section className='common-univeresity'>
      <Container>
        <Row>
          <Col xl={9} lg={8} md={8} sm={12} xs={12}>
            <div>
              <h4 className='uni-sec-title'>DY Patil Pune Courses and Fees 2024</h4>
              <p>DY Patil Pune offers over 208 courses at UG, PG and other levels. DY Patil Pune course admission is subject to merit in the last qualifying exam or entrance accepted across different programmes. The university accepts entrance exams, such as CAT, NEET, NEET PG for admission to different courses at the University. The below table mentions a list of DY Patil Pune courses along with their eligibility and fee:</p>
            </div>
            <FeeStructure />
            <UsuallyAskedQuetion />
            <div>
              <h4 className='uni-sec-title'>DY Patil Pune Popular Programmes 2024</h4>
            </div>
            <CommonCourse />
            <CommonCourse />
            <CommonCourse />
            <div className='text-center'>
              <button className='white-btnnn'>View all courses</button>
            </div>
          </Col>

          <Col xl={3} lg={4} md={4} sm={12} xs={12}>
            <ApplyNowBanner />
            <div className='mt-2'>
              <ApplyNowBanner />
            </div>
            <div className='mt-2'>
              <ApplyNowBanner />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CoursesAndFees