import React from "react";
import "./App.css";
import "./components/Common_Component/FormCss/FormCss.css"
import "./index.css";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollTotop/ScrollTotop";
import Header from "./components/Header/Header";
import CourcePage from "./components/CourcePage/CourcePage";
import Contact_us from "./components/Contact_us/Contact_us";
import HomePage from "./components/HomePage/HomePage";
import Footer from "./components/Footer/Footer";
import CourseDetailPage from "./components/CourseDetailPage/CourseDetailPage";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./components/TermsAndCondition/TermsAndCondition";
import LatestNewsArticals from "./components/LatestNewsArticals/LatestNewsArticals";
import Profile from "./components/Profile/Profile";
import Faq from "./components/Faq/Faq";
import EditProfilePage from "./components/Profile/EditProfilePage/EditProfilePage";
import Setting from "./components/Profile/Setting/Setting";
import ActivityStatus from "./components/Profile/Setting/SettingModal/ActivityStatus/ActivityStatus";
import UniversityDetailsPage from "./components/UniversityDetailsPage/UniversityDetailsPage";
import UniversityPage from "./components/UniversityPage/UniversityPage";
import TrendingCoursesDetails from "./components/TrendingCoursesDetails/TrendingCoursesDetails";
import WithoutLoginTrendingCourse from "./components/WithoutLoginTrendingCourse/WithoutLoginTrendingCourse";
import WithoutLoginUniversity from "./components/WithoutLoginUniversity/WithoutLoginUniversity";



function App() {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/cources" element={<CourcePage />} />
        <Route path="/contact-us" element={<Contact_us />} />
        <Route path="/course-detail" element={<CourseDetailPage />} />
        <Route path="/university-detail" element={<UniversityDetailsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/terms-condition" element={<TermsAndCondition/>} />
        <Route path="/latest-news-articals" element={<LatestNewsArticals />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit-profile" element={<EditProfilePage/>} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/activity-status" element={<ActivityStatus />} />
        <Route path="/faq" element={<Faq/>} />
        <Route path='/university' element={<UniversityPage/>} />
        <Route path='/trending-course-details' element={<TrendingCoursesDetails />} />
        <Route path='/without-login-course' element={<WithoutLoginTrendingCourse />} />
        <Route path='/without-login-university' element={<WithoutLoginUniversity />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
