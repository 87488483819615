import React from "react";
import "./LatestNews.css";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Heading_holder from "../../Common_Component/Heading_holder/Heading_holder";
import { Link } from "react-router-dom";

const LatestNews = () => {
    const LatestNews = [
        {
            image: process.env.PUBLIC_URL + "/assests/Images/Hompage/LatestNews/latest-image.png",
            time: "2 hrs ago",
            subtext: "Top 10 High-Paying Skills to Learn Online",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Images/Hompage/LatestNews/latest-image.png",
            time: "20 Jul, 2024",
            subtext: "Top 10 High-Paying Skills to Learn Online",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Images/Hompage/LatestNews/latest-image.png",
            time: "19 Jul, 2024",
            subtext: "Top 10 High-Paying Skills to Learn Online",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Images/Hompage/LatestNews/latest-image.png",
            time: "2 hrs ago",
            subtext: "Top 10 High-Paying Skills to Learn Online",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Images/Hompage/LatestNews/latest-image.png",
            time: "20 Jul, 2024",
            subtext: "Top 10 High-Paying Skills to Learn Online",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Images/Hompage/LatestNews/latest-image.png",
            time: "19 Jul, 2024",
            subtext: "Top 10 High-Paying Skills to Learn Online",
        },
    ];
    return (
        <>
            <section className="LatestNews">
                <Container>
                    <Heading_holder headingtext={"Latest News & Articles"} />

                    <Swiper
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            486: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            992: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1400: {
                                slidesPerView: 4,
                                spaceBetween: 15,
                            },
                            1900: {
                                slidesPerView: 4,
                                spaceBetween: 15,
                            },
                        }}
                        pagination={{ clickable: true, dynamicBullets: true, }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {LatestNews.map((item, index) => (
                            <SwiperSlide>
                                <Link to='/latest-news-articals'>
                                    <div className="late-main">
                                    <div className="img-holder">
                                        <div className="main-image">
                                            <img
                                                src={item.image}
                                                className="image-lates"

                                            />
                                        </div>
                                        <div className="offer text">{item.time}</div>
                                    </div>
                                        <p className="sub-text">{item.subtext}</p>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))}

                    </Swiper>
                </Container>
            </section>
        </>
    );
};

export default LatestNews;
