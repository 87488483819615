import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const EligibilityCrieteria = () => {
    return (
        <section className='common-univeresity'>
            <h5 className='heading mb-5'>DY Patil Pune Admission 2024: Eligibility & Selection Criteria</h5>
            <p >DY Patil College Pune admissions to all its courses is done through the entrance exam. Eligible candidates can register online by visiting the official website of the university. Check below the eligibility & selection criteria for the DY Patil Pune course admissions 2024 for all the UG and PG courses:</p>
            <div className='fee-structure mt-5'>
                <div className='white-back'>
                    <div className='yellow-bgggg'>
                        <Row>
                            <Col xl={4} lg={4} md={4}>
                                <p className='bold-txtt-blue'>Popular Courses</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='bold-txtt-blue'>Eligibility</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='bold-txtt-blue'>Selection Criteria</p>
                            </Col>
                        </Row>
                    </div>
                    <div className='white-bggg-inner'>
                        <Row>
                            <Col xl={4} lg={4} md={4}>
                                <p className='blue-txtt'>MBA</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>UG degree with minimum 50% aggregate (45% aggregate for reserved category)</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>CAT/ MAT/ XAT/ CMAT/ ATMA/ MAH CET + GD/PI or DPU-AIET</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={4} lg={4} md={4}>
                                <p className='blue-txtt'>BSc Nursing</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>Pass Class 12 with minimum 45% aggregate in PCB & English subjects The minimum age must be 17 years</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>University-level entrance exam (AIAHET)</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={4} lg={4} md={4}>
                                <p className='blue-txtt'>BSc</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>Class 12 with minimum 50% aggregate in the respective subjects</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>University-level entrance exam (AICET)</p>
                            </Col>
                        </Row>
                        <div className='text-center mt-4'>
                            <button className='view-course-btnnn'>View all courses <FontAwesomeIcon icon={faChevronDown} /></button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EligibilityCrieteria