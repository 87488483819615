import React from 'react'
import './CourcesBanner.css'
import CommonBanner from '../../Common_Component/CommonBanner/CommonBanner'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

const CourcesBanner = () => {
  return (
    <section className='courcesbanner'>
      <CommonBanner
        bannerimg={'/assests/Images/Cources/banner.png'}
        bannertext={"Courses"} />
      <div className='main-search-field'>
        <Row className='justify-content-center'>
            <div className='search-fieldd'>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search by Course / Specialization / University"
                  aria-label="Search by Course / Specialization / University"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Text id="basic-addon2"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
              </InputGroup>
            </div>
        </Row>
      </div>
    </section>
  )
}

export default CourcesBanner