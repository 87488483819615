
import React, { useState } from 'react'
import '../../../../EditProfilePage/EditProfile/EditProfile.css'
import { Container, Form } from 'react-bootstrap'
import { faPen, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PhoneInput from "react-phone-input-2";
import Select from 'react-select';



const options = [
    { value: 'Nashik', label: 'Nashik' },
    { value: 'Pune', label: 'Pune' },
    { value: 'Mumbai', label: 'Mumbai' },
];

const ActivityStatusForm = () => {
    const [phone, setPhone] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    return (
        <section className='edit-profile mb-5'>
            <Container>
                <div className='white-bggg'>
                    <div className='d-flex justify-content-between mb-4'>
                        <h2 className='title-activity'> Activity Status</h2>
                        <div>
                            <FontAwesomeIcon icon={faXmark} className='cross-icon' />
                        </div>
                    </div>

                    <Form className="form-holder  common-modalll-class">
                        <Form.Group className="mb-4" controlId="formBasicName">
                            <Form.Label>Contributions:</Form.Label>
                            <Form.Control type="name" placeholder="Enter Full Name*" />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicPhone">
                            <Form.Label>Contact:</Form.Label>
                            <PhoneInput
                                country={"eg"}
                                enableSearch={true}
                                value={phone}
                                onChange={(phone) => setPhone(phone)}
                                containerClass="phone-input-container"
                                inputClass="phone-input-field"
                                buttonClass="phone-input-button"
                            />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control type="email" placeholder="Enter Email" />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                            <Form.Label>Date of Birth (D.O.B.):</Form.Label>
                            <Form.Control type="date" placeholder="Enter Email" />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                            <Form.Label>City:</Form.Label>
                            <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                            />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                            <Form.Label>Gender</Form.Label>
                            <div className='d-flex'>
                                <Form.Check
                                    type='radio'
                                    label='Male'
                                    className='me-3'
                                />
                                <Form.Check
                                    type='radio'
                                    label='Female'
                                    className='me-3'
                                />
                                <Form.Check
                                    type='radio'
                                    label='Transgender'
                                    className='me-3'
                                />
                            </div>

                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicMsg">
                            <Form.Label>Write about your achievements in education, work and other areas.</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder="Write here...."
                            />
                        </Form.Group>
                        <div className='text-center mt-5'>
                            <button className='orange-btnnn me-2'>Update Profile</button>
                            <button className='white-btnnn'>Cancel</button>
                        </div>
                    </Form>
                </div>
            </Container>
        </section>
    )
}

export default ActivityStatusForm