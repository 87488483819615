import React from "react";
import "./RecentNews.css"
import RecentNewsCard from "../../../Common_Component/RecentNewsCard/RecentNewsCard";

const RecentNews = () => {
  return (
    <>
      <section className="RecentNews">
        <div className="RecentNews-swiper-holder">
          <div className="RecentNews-swiper-card-holder">
            <RecentNewsCard />
          </div>
          <div className="RecentNews-swiper-card-holder">
            <RecentNewsCard />
          </div>
          <div className="RecentNews-swiper-card-holder">
            <RecentNewsCard />
          </div>
          <div className="RecentNews-swiper-card-holder">
            <RecentNewsCard />
          </div>
          <div className="RecentNews-swiper-card-holder">
            <RecentNewsCard />
          </div>
          <div className="RecentNews-swiper-card-holder">
            <RecentNewsCard />
          </div>
          <div className="RecentNews-swiper-card-holder">
            <RecentNewsCard />
          </div>
        </div>
      </section>
    </>
  );
};

export default RecentNews;
