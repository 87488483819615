import React, { useState } from "react";
import "./Contact_us.css";
import CommonBanner from "../Common_Component/CommonBanner/CommonBanner";
import { Form } from "react-bootstrap";
import Common_btn from "../Common_Component/Common_btn/Common_btn";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import CommonSuccessModal from "../Common_Component/CommonSuccessModal/CommonSuccessModal";

function Contact_us() {
  const [phone, setPhone] = useState("");
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <section className="Contact_us">
        <div className="Contact_us_holder">
          <CommonBanner
            bannerimg={'/assests/Images/Cources/courcesbanner.png'}
            bannertext={
              "Get our experts to answer your questions within 24 Hrs."
            }
          />
          <div className="Contact_us_form_holder">
            <div className="container">
           
              <div className="Contact_us_form">
                <Form className="form-holder">
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control type="name" placeholder="Enter Full Name*" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="email" placeholder="Enter Email" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPhone">
                    <PhoneInput
                      country={"eg"}
                      enableSearch={true}
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      containerClass="phone-input-container" 
                      inputClass="phone-input-field" 
                      buttonClass="phone-input-button" 
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicMsg">
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Write your message here"
                    />
                  </Form.Group>

                  <Common_btn className={"send-btn"} text={"Send Message"}    onClick={() => {
                        
                        setModalShow(true);
                      
                    }} />
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CommonSuccessModal show={modalShow} onHide={() => setModalShow(false)}   modalheading={"Glad to know your interest to explore!"}  modalsubheading={"Soon we will get back to you!"}/>
    </>
  );
}

export default Contact_us;
