import React from 'react'
import { Col, Row } from 'react-bootstrap'

const InfrasecondTab = () => {
  return (
      <section className='common-univeresity'>
          <div className='mt-5'>
              <p>Dr D Y Patil Vidyapeeth covers an area of 816 square kilometres. The facilities offered by the university are:</p>
              <span className='blod-normal-txtt'>Dr D Y Patil Medical College:</span>
              <p> The college has 5 well-maintained lecture halls. There are 2 state-of-the-art auditoriums. The college has an excellent, sprawling library. The total collection of books in the library is more than 32,000. The college offers separate hostel facilities.</p>
              <span className='blod-normal-txtt'>Dr D Y Patil Institute of Management and Research:</span>
              <p> The institute has a centrally air-conditioned seminar hall. The Institute’s library contains more than 24,591 books, 84 journals, and magazines. A spacious reading hall with ample lighting provides the necessary ambience.</p>
              <span className='blod-normal-txtt'>Dr D Y Patil Institute of Technology:</span>
              <p> The campus has well-ventilated, spacious classrooms, seminar rooms, laboratories, a library, an administrative block, and a conference hall. The campus provides the in-house xeroxing facility, bank, student's cooperative society, cafeteria, etc.</p>
          </div>
          <div className='blk-border mt-5'></div>
      </section>
  )
}

export default InfrasecondTab