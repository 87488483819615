import React from 'react'
import UniversityDetailsBanner from './UniversityDetailsBanner/UniversityDetailsBanner'
import UniversityDetailsTab from './UniversityDetailsTab/UniversityDetailsTab'

const UniversityDetailsPage = () => {
    return (
        <>
            <UniversityDetailsBanner />
            <UniversityDetailsTab />
        </>
    )
}

export default UniversityDetailsPage