import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Container, Form, InputGroup, Pagination, Row } from 'react-bootstrap'
import ApplyNowBanner from '../../Common_Component/ApplyNowBanner/ApplyNowBanner'
import CommonCourceCard from '../../Common_Component/CommonCourceCard/CommonCourceCard'
import './UniversitySecond.css'

const courseData = [
    {
        imgSrc: "/assests/Images/Hompage/trending-cources/img1.png",
        courserating: 4.8,
        universityLogo:
            "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png",
        universityName: "Dr. D. Y. Patil University",
        courseName: "MBA in Human Resource Management",
    },
    {
        imgSrc: "/assests/Images/Hompage/trending-cources/img2.png",
        courserating: 4.5,
        universityLogo:
            "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png",
        universityName: "Dr. D. Y. Patil University",
        courseName: "MBA in Business and Finance",
    },
    {
        imgSrc: "/assests/Images/Hompage/trending-cources/img3.png",
        courserating: 4.7,
        universityLogo: "/assests/Images/Hompage/trending-cources/cu-logo.png",
        universityName: "Chandigarh University",
        courseName: "BBA in Human Resources Management",
    },
    {
        imgSrc: "/assests/Images/Hompage/trending-cources/img1.png",
        courserating: 4.8,
        universityLogo:
            "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png",
        universityName: "Dr. D. Y. Patil University",
        courseName: "MBA in Human Resource Management",
    },
    {
        imgSrc: "/assests/Images/Hompage/trending-cources/img2.png",
        courserating: 4.5,
        universityLogo:
            "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png",
        universityName: "Dr. D. Y. Patil University",
        courseName: "MBA in Business and Finance",
    },
    {
        imgSrc: "/assests/Images/Hompage/trending-cources/img3.png",
        courserating: 4.7,
        universityLogo: "/assests/Images/Hompage/trending-cources/cu-logo.png",
        universityName: "Chandigarh University",
        courseName: "BBA in Human Resources Management",
    },
];


const UniversitySecond = () => {
    return (
        <section className='university-second'>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={5}>
                        <div className='search-fieldd'>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Search by Course / Specialization / University"
                                    aria-label="Search by Course / Specialization / University"
                                    aria-describedby="basic-addon2"
                                />
                                <InputGroup.Text id="basic-addon2"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
                <div>
                    <button className='back-btnnn'>Back</button>
                    <h5 className='title-txtttt mt-5'><span className='border-orange'>M</span>BA</h5>
                </div>
                <Row className='mt-5'>
                    <Col xl={9} lg={8} md={8} sm={12} xs={12}>
                        <Row>
                            {courseData.map((course, index) => (
                                <Col xl={4} lg={4} md={6} sm={12} xs={12} key={index}>
                                    <CommonCourceCard
                                        imgSrc={course.imgSrc}
                                        courserating={course.courserating}
                                        courseName={course.courseName}
                                    />
                                </Col>
                            ))}
                        </Row>
                        <div className='main-pagination'>
                            <Pagination>
                                <Pagination.Item active>{1}</Pagination.Item>
                                <Pagination.Item>{2}</Pagination.Item>
                            </Pagination>
                        </div>
                    </Col>
                    <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                        <ApplyNowBanner />
                        <div className='mt-2'>
                            <ApplyNowBanner />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default UniversitySecond