import React from 'react'
import ActivityStatusBanner from './ActivityStatusBanner/ActivityStatusBanner'
import ActivityStatusForm from './ActivityStatusForm/ActivityStatusForm'

const ActivityStatus = () => {
    return (
        <>
            <ActivityStatusBanner />
            <ActivityStatusForm />
        </>
    )
}

export default ActivityStatus