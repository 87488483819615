import React from "react";
import "./PrivacyPolicy.css";
import CommonBanner from "../Common_Component/CommonBanner/CommonBanner";
import Breadcrumb_holder from "../Common_Component/Breadcrumb_holder/Breadcrumb_holder";

function PrivacyPolicy() {
  const breadcrumbs = [
    { label: "Home", path: "/" },
    { label: "Privacy Policy" }
  ];
  return (
    <>
      <div className="PrivacyPolicy">
        <CommonBanner
          bannerimg={'/assests/Images/All-Banner/terms-banner.png'}
          bannertext={"Privacy Policy"} />
        <div className="container">
          <Breadcrumb_holder breadcrumbs={breadcrumbs} />

          <div className="PrivacyPolicy_holder">
            <div className="text-holder">
              <h3 className="PrivacyPolicy_heading_text">Introduction</h3>
              <p className="PrivacyPolicy_content_text">
                These terms and conditions (collectively, the "Terms"), along
                with all other terms and legal notices located on
                www.upgraddisha.com (the "Website"), govern your use of the
                Website. The Website is owned and operated by Talentedge
                Education Ventures Pvt. Ltd. a subsidiary of upGrad Education
                Private Limited with its registered business address at Ground
                Floor, Nishuvi 75 Dr. Annie Besant Road Worli, Mumbai – 400018
                (the “Company”) and a part of the upGrad group.
                <br />
                <br />
                These Terms of Use ("Terms") constitute a legally binding
                agreement ("Agreement") between you ("User" or "You") and the
                Company governing your access to and use of the upGrad Disha
                platform, including any subdomains thereof, and any other
                websites through which the platform makes its services available
                (collectively, the "Platform").
              </p>
            </div>

            <div className="text-holder">
              <h3 className="PrivacyPolicy_heading_text">
                Personal Information (PI)
              </h3>
              <p className="PrivacyPolicy_content_text">
                {" "}
                Personal information refers to any information relating to an
                identified or identifiable living person. This includes but is
                not limited to a person's name, email address, telephone number,
                educational qualification, and any other information as per
                applicable laws and regulations.
              </p>
            </div>

            <div className="text-holder">
              <h3 className="PrivacyPolicy_heading_text">Core Purpose</h3>
              <p className="PrivacyPolicy_content_text">
                {" "}
                We provide a platform to assist students in discovering,
                researching, and connecting with universities and educational
                programs. The core purpose of the platform is to facilitate the
                exchange of information between students and educational
                institutions. User's contactable information may be shared with
                educational institutions for admission purposes.
              </p>
            </div>

            <div className="text-holder">
              <h3 className="PrivacyPolicy_heading_text">
                Information Collection
              </h3>
              <h3 className="PrivacyPolicy_heading_text starting-space">
                We collect information about you or your usage to provide better
                services. Information is collected through various methods:
              </h3>

              <ul>
                <li>
                  <p className="PrivacyPolicy_content_text">
                    Registration/sign up: Personal information such as name,
                    email address, and telephone number is collected to
                    create/update your account.
                  </p>
                </li>

                <li>
                  <p className="PrivacyPolicy_content_text">
                    Profile information: Additional details like educational
                    background and work experience may be collected to provide
                    tailored services.
                  </p>
                </li>

                <li>
                  <p className="PrivacyPolicy_content_text">
                    Usage information: Data on the services you use and how you
                    use them is collected for analysis and improvement.
                  </p>
                </li>

                <li>
                  <p className="PrivacyPolicy_content_text">
                    Communication: Information is collected when you communicate
                    with upGrad Disha or other users on the platform.
                  </p>
                </li>
              </ul>
            </div>

            <div className="text-holder">
              <h3 className="PrivacyPolicy_heading_text">
                {" "}
                Processing Personal Information:
              </h3>
              <h3 className="PrivacyPolicy_heading_text starting-space">
                {" "}
                We process personal information for various purposes including
                but not limited to:{" "}
              </h3>
              <ul>
                <li>
                  <p className="PrivacyPolicy_content_text">
                    Improving user experience through cookies and other
                    technologies.
                  </p>
                </li>

                <li>
                  <p className="PrivacyPolicy_content_text">
                    Providing customized search results, recommendations, and
                    promotions.
                  </p>
                </li>

                <li>
                  <p className="PrivacyPolicy_content_text">
                    Sending alerts, newsletters, and marketing communications.
                  </p>
                </li>

                <li>
                  <p className="PrivacyPolicy_content_text">
                    Conducting market research and surveys.
                  </p>
                </li>

                <li>
                  <p className="PrivacyPolicy_content_text">
                    Preventing, detecting, and investigating crimes.
                  </p>
                </li>

                <li>
                  <p className="PrivacyPolicy_content_text">
                    Cookies and Other Tracking Technologies:
                    <br />
                    We utilize cookies and tracking technologies to enhance
                    website functionality and user experience. These
                    technologies may record information such as IP addresses,
                    browser software, and clickstream patterns. Users have the
                    option to control cookies through browser settings.
                  </p>
                </li>
              </ul>
            </div>

            <div className="text-holder">
              <h3 className="PrivacyPolicy_heading_text">
                Information Sharing
              </h3>

              <p className="PrivacyPolicy_content_text">
                Personal information may be shared with trusted partners and
                educational institutions for relevant services. We do not rent,
                sell, or share personal information for unrelated purposes
                unless required by law.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
