import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import RecentNews from "./RecentNews/RecentNews";
import RequestCallBack from "./RequestCallBack/RequestCallBack";
import "./NewsDescription.css";
import LatestNewsDescription from "./LatestNewsDescription/LatestNewsDescription";
import Breadcrumb_holder from "../../Common_Component/Breadcrumb_holder/Breadcrumb_holder";

export const NewsDescription = () => {
  const breadcrumbs = [
    { label: "Home", path: "/" },
    { label: "Latest News & Articles" }
  ];
  return (
    <section className="news-description">
      <Container>
        <Breadcrumb_holder breadcrumbs={breadcrumbs} />
        <Row>
          <Col xl={8} lg={8} md={8} sm={12} xs={12}>
            <LatestNewsDescription />
          </Col>
          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <RecentNews />
            <RequestCallBack />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
