import React from 'react'
import CommonBanner from '../../Common_Component/CommonBanner/CommonBanner'

const ProfileBanner = () => {
  return (
    <section className='profile-banner'>
      <CommonBanner
        bannerimg={'/assests/Images/All-Banner/profile.png'}
      />
    </section>
  )
}

export default ProfileBanner