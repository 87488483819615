import React from 'react'
import { Col, Row } from 'react-bootstrap'

const SecondSection = () => {
    return (
        <section className='common-univeresity'>
            <div className='fee-structure mt-5'>
                <div className='white-back'>
                    <div className='yellow-bgggg'>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='bold-txtt-blue'>Name of the Full time teacher</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='bold-txtt-blue'>Designation</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='bold-txtt-blue'>Department</p>
                            </Col>
                        </Row>
                    </div>
                    <div className='white-bggg-inner'>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Dr. P. R. Manvikar</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Prof & HOD</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Anatomy</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Dr. Mrs. Vaishaly K. Bharambe</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Professor</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Anatomy</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Dr. Vilas Jaltade</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Prof & HOD</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'> Physiology</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Dr. Vilas Jaltade</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Prof & HOD</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'> Physiology</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Dr. Vilas Jaltade</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Prof & HOD</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'> Physiology</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Dr. Vilas Jaltade</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'>Prof & HOD</p>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                                <p className='normal-txttt'> Physiology</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SecondSection