import React from 'react'
import LatestNewsBanner from './LatestNewsBanner/LatestNewsBanner'
import { NewsDescription } from './NewsDescription/NewsDescription'

const LatestNewsArticals = () => {
    return (
        <>
            <LatestNewsBanner />
            <NewsDescription />
        </>
    )
}

export default LatestNewsArticals