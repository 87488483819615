import React from 'react'
import CourcesBanner from './CourcesBanner/CourcesBanner'
import MainCources from './MainCources/MainCources'

const CourcePage = () => {
    return (
        <>
            <CourcesBanner bannertext={"Courses"} />
            <MainCources />
        </>
    )
}

export default CourcePage