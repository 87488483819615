import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './ChangePasswordModal.css';

const ChangePasswordModal = (props) => {
    const [showPassword, setShowPassword] = useState({
        current: false,
        new: false,
        confirm: false
    });

    const toggleShowPassword = (field) => {
        setShowPassword({
            ...showPassword,
            [field]: !showPassword[field]
        });
    };

    return (
        <section>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className='change-pass-modal common-modalll-class'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='modal-header-class'>
                        Change Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='form-holder'>
                        <Form.Group className="mb-3" controlId="currentPassword">
                            <Form.Label>Current Password</Form.Label>
                            <div className='password-input'>
                                <Form.Control
                                    type={showPassword.current ? "text" : "password"}
                                    placeholder="Enter Current Password"
                                />
                                <div className='eye-icon' onClick={() => toggleShowPassword('current')}>
                                    {showPassword.current ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="newPassword">
                            <Form.Label>New Password</Form.Label>
                            <div className='password-input'>
                                <Form.Control
                                    type={showPassword.new ? "text" : "password"}
                                    placeholder="Enter New Password"
                                />
                                <div className='eye-icon' onClick={() => toggleShowPassword('new')}>
                                    {showPassword.new ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="confirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <div className='password-input'>
                                <Form.Control
                                    type={showPassword.confirm ? "text" : "password"}
                                    placeholder="Enter Confirm Password"
                                />
                                <div className='eye-icon' onClick={() => toggleShowPassword('confirm')}>
                                    {showPassword.confirm ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </div>
                        </Form.Group>
                        <div className='text-center'>
                            <button className='orange-btnnn me-2'>Save</button>
                            <button className='white-btnnn'>Cancel</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </section>
    );
};

export default ChangePasswordModal;
