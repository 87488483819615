import React from 'react'
import { Col, Row } from 'react-bootstrap'
import CommonCourceCard from '../../../Common_Component/CommonCourceCard/CommonCourceCard'
import './InnerCources.css'
import Pagination from 'react-bootstrap/Pagination';

const courses = [
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/img1.png',
        courserating: '4.3',
        courseName: 'BBA in Human Resources Management',
    },
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/img2.png',
        courserating: '4.6',
        courseName: 'MBA in Human Resources Management',
    },
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/img3.png',
        courserating: '4.7',
        courseName: 'M.Com in Human Resources Management',
    },
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/img1.png',
        courserating: '4.3',
        courseName: 'BBA in Human Resources Management',
    },
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/img2.png',
        courserating: '4.6',
        courseName: 'MBA in Human Resources Management',
    },
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/img3.png',
        courserating: '4.7',
        courseName: 'M.Com in Human Resources Management',
    },
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/img1.png',
        courserating: '4.3',
        courseName: 'BBA in Human Resources Management',
    },
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/img2.png',
        courserating: '4.6',
        courseName: 'MBA in Human Resources Management',
    },
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/img3.png',
        courserating: '4.7',
        courseName: 'M.Com in Human Resources Management',
    },
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/img1.png',
        courserating: '4.3',
        courseName: 'BBA in Human Resources Management',
    },
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/img2.png',
        courserating: '4.6',
        courseName: 'MBA in Human Resources Management',
    },
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/img3.png',
        courserating: '4.7',
        courseName: 'M.Com in Human Resources Management',
    },
]

const InnerCources = () => {
    return (
        <section className='innercources'>
            <div className='trending-cources'>
                <Row>
                    {courses.map((course, index) => (
                        <Col key={index} xxl={4} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <CommonCourceCard
                                imgSrc={course.imgSrc}
                                courserating={course.courserating}
                                courseName={course.courseName}
                            />
                        </Col>
                    ))}
                    <div className='main-pagination'>
                        <Pagination>
                            <Pagination.Item active>{1}</Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
                        </Pagination>
                    </div>
                </Row>
            </div>
        </section>
    )
}

export default InnerCources
