import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ApplyNowBanner from '../../../../Common_Component/ApplyNowBanner/ApplyNowBanner'
import InfraReviews from './InfraReviews/InfraReviews'
import StudentReviews from './StudentReviews/StudentReviews'
import InfrasecondTab from './InfrasecondTab/InfrasecondTab'

const InfrastructureTab = () => {
    return (
        <section className='common-univeresity'>
            <Container>
                <Row>
                    <Col xl={9} lg={8} md={8} sm={12} xs={12}>
                        <InfraReviews />
                        <InfrasecondTab />
                        <StudentReviews />
                    </Col>

                    <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                        <ApplyNowBanner />
                        <div className='mt-2'>
                            <ApplyNowBanner />
                        </div>
                        <div className='mt-2'>
                            <ApplyNowBanner />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default InfrastructureTab