import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './LoginSignupCommon.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ForgetPassword from './ForgetPassword';
import Signup from './Signup';

const Login = (props) => {
  const [loginMethod, setLoginMethod] = useState('email');
  const [forgetmodalShow, setForgetModalShow] = useState(false);
  const [signupmodalShow, setSignupModalShow] = useState(false);
  const [phone, setPhone] = useState("");

  const handleRadioChange = (e) => {
    setLoginMethod(e.target.value);
  };

  const handleForgetPasswordClick = () => {
    props.onHide();
    setForgetModalShow(true);
  };

  const handleSignupClick = () => {
    props.onHide();
    setSignupModalShow(true);
  };

  return (
    <section>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='common-modalll-class'
      >
        <Modal.Body>
          <Row>
            <Col xl={5} lg={5} md={12} sm={12} xs={12}>
              <div className='first-section'>
                <h3 className='signup-txttt'><span className='orange-border'>Log</span> In</h3>
                <p className='below-txttt '>Welcome back to make your mark!</p>
              </div>
            </Col>
            <Col xl={5} lg={7} md={12} sm={12} xs={12}>
              <div className='orange-bgg'>
                <div className='white-bggg'>
                  <h3 className='signup-txtttttt mb-3'>Log In</h3>
                  <div className='form-main'>
                    <Form className='form-holder'>
                      <div className='d-flex mb-5'>
                        <Form.Check
                          type='radio'
                          label='Email'
                          className='me-3'
                          value='email'
                          checked={loginMethod === 'email'}
                          onChange={handleRadioChange}
                        />
                        <Form.Check
                          type='radio'
                          label='Mobile'
                          value='mobile'
                          checked={loginMethod === 'mobile'}
                          onChange={handleRadioChange}
                        />
                      </div>
                      {loginMethod === 'email' ? (
                        <div className='email-section'>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control type="email" placeholder="Enter Email" />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control type="password" placeholder="Enter Password" />
                            <div className='text-end'>
                              <span className='form-txttt' onClick={handleForgetPasswordClick}>Forgot Password?</span>
                            </div>
                          </Form.Group>
                          <button className='get-btnnn mb-5'>Log In</button>
                          <p className='form-txttt mt-5'>New to Profcyma College Compare? <span className='login-txtt' onClick={handleSignupClick}>Sign Up</span></p>
                        </div>
                      ) : (
                        <div className='mobile-section'>
                          <Form.Group className="mb-3" controlId="formBasicPhone">
                            <PhoneInput
                              country={"eg"}
                              enableSearch={true}
                              value={phone}
                              onChange={(phone) => setPhone(phone)}
                              containerClass="phone-input-container"
                              inputClass="phone-input-field"
                              buttonClass="phone-input-button"
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Control type="password" placeholder="Enter Password" />
                            <div className='text-end'>
                              <span className='form-txttt'  onClick={handleForgetPasswordClick}>Forgot Password?</span>
                            </div>
                          </Form.Group>
                          <button className='get-btnnn mb-5'>Continue</button>
                        </div>
                      )}
                    </Form>
                  </div>
                  <img src={process.env.PUBLIC_URL + '/assests/Images/Login-Signup/bg-img.png'} className='bg-img' alt='background' />
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <ForgetPassword
        show={forgetmodalShow}
        onHide={() => setForgetModalShow(false)}
      />

      <Signup
        show={signupmodalShow}
        onHide={() => setSignupModalShow(false)}
      />
    </section>
  );
}

export default Login;
