import React from 'react'
import CourseDetailsBanner from './CourseDetailsBanner/CourseDetailsBanner'
import CourseDetailsTab from './CourseDetailsTab/CourseDetailsTab'


const CourseDetailPage = () => {
  return (
    <>
      <CourseDetailsBanner />
      <CourseDetailsTab />
    </>
  )
}

export default CourseDetailPage