import React from "react";
import { Modal } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Universities_Modal.css";
import "swiper/css";
import Universities_card from "../../Universities_card/Universities_card";
import { Link } from "react-router-dom";

function Universities_Modal(props) {
  const universities = [
    {
      universityimage: process.env.PUBLIC_URL + "/assests/Images/University/DY.png",
      universitylogo: process.env.PUBLIC_URL + "/assests/Images/Hompage/TopApproved/dpu.png"
    },
    {
      universityimage: process.env.PUBLIC_URL + "/assests/Images/University/Chandigarh.png",
      universitylogo: process.env.PUBLIC_URL + "/assests/Images/Hompage/TopApproved/Chandigarh.png"
    },
    {
      universityimage: process.env.PUBLIC_URL + "/assests/Images/University/Amity.png",
      universitylogo: process.env.PUBLIC_URL + "/assests/Images/Hompage/TopApproved/amity.png"
    },
    {
      universityimage: process.env.PUBLIC_URL + "/assests/Images/University/Symbosys.png",
      universitylogo: process.env.PUBLIC_URL + "/assests/Images/Hompage/TopApproved/Symbosys.png"
    }
    // Add more universities as needed
  ];

  // Function to close the modal
  const closeModal = () => {
    props.onHide();
  };

  return (
    <>
      <div className="Universities_Modal-holder">
        <div className="container">
          <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="Universities_Modal"
          >
            <Modal.Body>
              <div className="Universities-slider-section-holder">
                <Swiper
                  slidesPerView={3.5}
                  spaceBetween={15}
                  className="mySwiper Universities-slider"
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    485: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    576: {
                      slidesPerView: 1.5,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1200: {
                      slidesPerView: 3.5,
                      spaceBetween: 20,
                    },
                    1440: {
                      slidesPerView: 3.5,
                      spaceBetween: 15,
                    },
                  }}
                >
                  {universities.map((university, index) => (
                    <SwiperSlide key={index}>
                      <div className="Universities-slider">
                        <div className="Universities-slider-card-holder">
                          <Link to='/university-detail' onClick={closeModal}>
                            <Universities_card
                              universityimage={university.universityimage}
                              universitylogo={university.universitylogo}
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Universities_Modal;
