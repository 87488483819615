import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import './OfferingCourse.css';

const OfferingCourse = () => {
    const universities = [
        '/assests/Images/University/dpu.png',
        '/assests/Images/University/cu-logo.png',
        '/assests/Images/University/amityyyy.png',
        '/assests/Images/University/logonew.png',
        '/assests/Images/University/dpu.png',
        '/assests/Images/University/cu-logo.png',
        '/assests/Images/University/amityyyy.png',
        '/assests/Images/University/logonew.png'
    ];

    return (
        <section className='offering-course'>
            <div>
                <h5 className='heading'>Colleges Offering Online MBA Courses in India</h5>
            </div>
            <Swiper
                slidesPerView={5}
                spaceBetween={50}
                pagination={{ clickable: true, dynamicBullets: true }}
                breakpoints={{
                    0: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    991: { slidesPerView: 2 },
                    1440: { slidesPerView: 3 },
                    1600: { slidesPerView: 5 },
                }}
                modules={[Pagination]}
                className="myswiper mt-5"
            >
                {universities.map((src, index) => (
                    <SwiperSlide key={index} className='mb-5'>
                        <img src={process.env.PUBLIC_URL + src} className='uni-img' alt={`university-${index}`} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
};

export default OfferingCourse;
