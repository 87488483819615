import React from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import PgCources from './PgCources/PgCources'
import './MainCources.css'

const MainCources = () => {
    return (
        <section className='main-cources'>
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col xxl={2} xl={3} lg={3} md={12} sm={12} xs={12}>
                            <div className='yellow-bggg'>
                                <Nav variant="pills" className="tabs-link-holder">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" className="custom-nav-link ">
                                            PG Cources
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second" className="custom-nav-link ">UG Cources</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third" className="custom-nav-link ">Certification</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="four" className="custom-nav-link ">PGDM</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </Col>
                        <Col xxl={10} xl={9} lg={9} md={12} sm={12} xs={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first"><PgCources /></Tab.Pane>
                                <Tab.Pane eventKey="second">Second</Tab.Pane>
                                <Tab.Pane eventKey="third">Third</Tab.Pane>
                                <Tab.Pane eventKey="four">Four</Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </section>
    )
}

export default MainCources