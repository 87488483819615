import React from 'react'
import CommonBanner from '../../Common_Component/CommonBanner/CommonBanner'

const LatestNewsBanner = () => {
    return (
        <CommonBanner
            bannerimg={'/assests/Images/All-Banner/terms-banner.png'}
            bannertext={'Latest News & Articles'} />
    )
}

export default LatestNewsBanner