import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Row } from 'react-bootstrap'


const FeeStructure = () => {
    return (
        <section className='common-univeresity fee-structure'>
            <div className='white-back'>
                <div className='yellow-bgggg'>
                    <Row>
                        <Col xl={4} lg={4} md={4}>
                            <p className='bold-txtt-blue'>Courses</p>
                        </Col>
                        <Col xl={4} lg={4} md={4}>
                            <p className='bold-txtt-blue'>Tuition Fees</p>
                        </Col>
                        <Col xl={4} lg={4} md={4}>
                            <p className='bold-txtt-blue'>Eligibility</p>
                        </Col>
                    </Row>
                </div>
                <div className='white-bggg-inner'>
                    <Row>
                        <Col xl={4} lg={4} md={4}>
                            <p className='blue-txtt'>MBA/PGDM</p>
                            <span className='brown-txtt'>(5 courses)</span>
                        </Col>
                        <Col xl={4} lg={4} md={4}>
                            <p className='normal-txttt'>₹ 1.3 L - 6.1 L</p>
                        </Col>
                        <Col xl={4} lg={4} md={4}>
                            <p className='normal-txttt'>Graduation : 50 % </p>
                            <p className='normal-txttt'>Exams : MAH CET, MAT <span className='blue-txtt-small'>(5 more)</span></p>
                        </Col>
                    </Row>
                    <div className='dash-border mt-2'></div>
                    <Row>
                        <Col xl={4} lg={4} md={4}>
                            <p className='blue-txtt'>B.E. / B.Tech</p>
                            <span className='brown-txtt'>(5 courses)</span>
                        </Col>
                        <Col xl={4} lg={4} md={4}>
                            <p className='normal-txttt'>₹ 6 L - 10 L</p>
                        </Col>
                        <Col xl={4} lg={4} md={4}>
                            <p className='normal-txttt'>10+2 : 45-50 % </p>
                            <p className='normal-txttt'>Exams : JEE MainMHT CET <span className='blue-txtt-small'>(3 more)</span></p>
                        </Col>
                    </Row>
                    <div className='dash-border mt-2'></div>
                    <Row>
                        <Col xl={4} lg={4} md={4}>
                            <p className='blue-txtt'>BPT</p>
                            <span className='brown-txtt'>(1 course)</span>
                        </Col>
                        <Col xl={4} lg={4} md={4}>
                            <p className='normal-txttt'>₹ 6 L - 10 L</p>
                        </Col>
                        <Col xl={4} lg={4} md={4}>
                            <p className='normal-txttt'>-/-</p>
                        </Col>
                    </Row>
                    <div className='text-center'>
                        <button className='view-course-btnnn'>View all courses <FontAwesomeIcon icon={faChevronDown} /></button>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default FeeStructure