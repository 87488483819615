import React from 'react'
import { Col } from 'react-bootstrap'
import PlacementRating from '../PlacementTab/PlacementRating/PlacementRating'

const OverallRating = () => {
    return (
        <section className='common-univeresity'>
            <h5 className='heading'>DY Patil, Overall Ratings</h5>
            <PlacementRating />
            <PlacementRating />
            <PlacementRating />

        </section>
    )
}

export default OverallRating