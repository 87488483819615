import React from "react";
import "./RecentNewsCard.css";

function RecentNewsCard() {
  return (
    <>
      <div className="RecentNewsCard">
        <div className="RecentNews-swiper-card">
          <div className="row">
            <div className="col-lg-6 col-md-12 ">
              <div className="main-image">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assests/Images/Hompage/trending-cources/img1.png"
                  }
                  className="news-img"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 ">
              <div className="text-holder">
                <h3>Are online degrees worth it for Career Growth</h3>
                <p>Uploaded : 20 Jul, 2024</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecentNewsCard;
