import React, { useState } from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import './CourseSyallabus.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import CommonSuccessModal from '../../Common_Component/CommonSuccessModal/CommonSuccessModal'

const CourseSyallabus = () => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <section className='course-syllabus Faq'>
            <Container>
                <h5 className='heading'>Course Syllabus</h5>
                <div>
                    <Row>
                        <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                            <div className='title-course ms-2'>
                                <p className='weeks-txtt-4'><FontAwesomeIcon icon={faCalendarDays} className='weeks-txtt-3' /> 24 Months</p>
                            </div>
                        </Col>
                        <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                            <div className='title-course ms-2'>
                                <p className='weeks-txtt-4'><img src={process.env.PUBLIC_URL + '/assests/Images/icon/credit.png'} className='credit-img weeks-txtt-3' /> 30 Credits</p>
                            </div>
                        </Col>
                        <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                            <div className='title-course ms-2'>
                                <p className='weeks-txtt-4'><FontAwesomeIcon icon={faBook} className='weeks-txtt-3' /> 28 Subjects</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='faq-content-holder'>
                    <div className='faq-holder'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    Semester 1
                                    <div className='title-course ms-2'>
                                        <p className='weeks-txtt'><FontAwesomeIcon icon={faCalendarDays} className='weeks-txtt-2' /> 6 Weeks</p>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='bold-noraml-txtt'>Subjects</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='bold-noraml-txtt'>Credits</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Principles of Management</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Organization Behaviour</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Statistical Methods for Managers</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Managerial Economics</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='bold-noraml-txtt' >Subjects</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='bold-noraml-txtt'>Credits</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Principles of Management</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Organization Behaviour</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Statistical Methods for Managers</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main' >Managerial Economics</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Semester 2
                                    <div className='title-course ms-2'>
                                        <p className='weeks-txtt'><FontAwesomeIcon icon={faCalendarDays} className='weeks-txtt-2' /> 6 Weeks</p>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='bold-noraml-txtt'>Subjects</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='bold-noraml-txtt'>Credits</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Principles of Management</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Organization Behaviour</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Statistical Methods for Managers</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Managerial Economics</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='bold-noraml-txtt' >Subjects</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='bold-noraml-txtt'>Credits</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Principles of Management</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Organization Behaviour</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Statistical Methods for Managers</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main' >Managerial Economics</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    Semester 3
                                    <div className='title-course ms-2'>
                                        <p className='weeks-txtt'><FontAwesomeIcon icon={faCalendarDays} className='weeks-txtt-2' /> 6 Weeks</p>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='bold-noraml-txtt'>Subjects</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='bold-noraml-txtt'>Credits</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Principles of Management</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Organization Behaviour</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Statistical Methods for Managers</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Managerial Economics</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='bold-noraml-txtt' >Subjects</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='bold-noraml-txtt'>Credits</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Principles of Management</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Organization Behaviour</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Statistical Methods for Managers</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main' >Managerial Economics</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                    Semester 4
                                    <div className='title-course ms-2'>
                                        <p className='weeks-txtt'><FontAwesomeIcon icon={faCalendarDays} className='weeks-txtt-2' /> 6 Weeks</p>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='bold-noraml-txtt'>Subjects</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='bold-noraml-txtt'>Credits</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Principles of Management</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Organization Behaviour</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Statistical Methods for Managers</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Managerial Economics</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='bold-noraml-txtt' >Subjects</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='bold-noraml-txtt'>Credits</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Principles of Management</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Organization Behaviour</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main'>Statistical Methods for Managers</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={4} xs={2}>
                                                    <p className='text-main' >Managerial Economics</p>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={2}>
                                                    <div className='dashed-border'></div>
                                                </Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <p className='text-main'>4</p>
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
                <div className='text-center'>
                    <button className='orange-btnnn me-3' onClick={() => setModalShow(true)}>Schedule a Call</button>
                    <button className='orange-btnnn '>Download Brochure</button>
                </div>
                <CommonSuccessModal
                    modalheading={'Thank You for Showing Interest'}
                    modalsubheading={'Our Expert will get back to you soon to wipe off a confusion!'}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </Container>
        </section>
    )
}

export default CourseSyallabus