import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../CollegeInfo.css'
import ApplyNowBanner from '../../../../../Common_Component/ApplyNowBanner/ApplyNowBanner'
import CommonCourse from './CommonCourse'
import UsuallyAskedQuetion from './UsuallyAskedQuetion'
import AdmissionDate from './AdmissionDate'
import Placement from './Placement'

const PopularProgramm = () => {
    return (
        <section className='common-univeresity'>
            <Container>
                <div>
                    <h4 className='uni-sec-title'>DY Patil Pune Popular Programmes 2024</h4>
                </div>
                <Row>
                    <Col xl={9} lg={8} md={8} sm={12} xs={12}>
                        <CommonCourse />
                        <CommonCourse />
                        <CommonCourse />
                        <CommonCourse />
                        <UsuallyAskedQuetion/>
                        <CommonCourse />
                        <CommonCourse />
                        <CommonCourse />
                        <div className='text-center'>
                            <button className='white-btnnn'>View all courses</button>
                        </div>
                        <AdmissionDate/>
                        <div className='text-center mb-5'>
                            <button className='white-btnnn'>View all courses</button>
                        </div>
                        <Placement />
                        <div>
                            <p className='note-txttt'>NOTE:<span className='note-span'>The aforementioned details are collated from the NIRF report 2023.</span> </p>
                        </div>
                    </Col>
                    <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                        <ApplyNowBanner />
                        <div className='mt-2'>
                            <ApplyNowBanner />
                        </div>
                        <div className='mt-2'>
                            <ApplyNowBanner />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PopularProgramm