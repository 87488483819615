import React from 'react'
import './SimilarCollege.css'
import { Col, Row } from 'react-bootstrap'
import { faLocationDot, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const similarColleges = [
    { id: 1 },
    { id: 2 },
    { id: 3 }
];

const SimilarCollege = () => {
    return (
        <section className='similar-college'>
            <Row>
                <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                    <div className='first-card'>
                        <div className='white-bgggg text-center'>
                            <img src={process.env.PUBLIC_URL + '/assests/Images/University/dpu.png'} className='uni-img' />
                        </div>
                        <div className='below-section'>
                            <p className='uni-txttt'>Dr. D. Y. Patil Vidyapeeth, Pune</p>
                            <p className='location-txtt'><FontAwesomeIcon icon={faLocationDot} /> Pune</p>
                            <p className='course-txtt'>MBA in Human Resources</p>
                            <div className='text-center'>
                                <button className='white-btnnn'>Modify Selection</button>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xl={9} lg={9} md={8} sm={12} xs={12}>
                    <Row>
                        {similarColleges.map((college) => (
                            <Col key={college.id} xl={4} lg={4} md={4} sm={6} xs={12}>
                                <div className='light-blue-bggg'>
                                    <div className='d-flex justify-content-center'>
                                        <div className='white-bggg mt-3'>
                                            <FontAwesomeIcon icon={faPlus} className='plus-icon' />
                                        </div>
                                    </div>
                                    <p className='text-main mt-3'>or</p>
                                    <button className='similar-btnn mt-3 mb-2'>Add Similar College</button>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </section>
    )
}

export default SimilarCollege