import React from 'react'
import './CourseDetailsBanner.css'
import CommonBanner from '../../Common_Component/CommonBanner/CommonBanner'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const CourseDetailsBanner = () => {
    return (
        <section className='course-details-banner'>
            <CommonBanner
            bannerimg={'/assests/Images/Cources/courcesbanner.png'} 
            bannertext={'MBA'} />
            <div className='course-details-banner-txtt'>
                <h4 >Best MBA Colleges in India 2024</h4>
                <div className='orange-border'></div>
                <p>Ranking, Courses, Admission, Placement, Cutoff</p>
            </div>
            <div className='search-fieldd'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Search by Course / Specialization / University"
                        aria-label="Search by Course / Specialization / University"
                        aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text id="basic-addon2"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                </InputGroup>
            </div>
        </section>
    )
}

export default CourseDetailsBanner