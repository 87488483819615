import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ApplyNowBanner from '../../../../Common_Component/ApplyNowBanner/ApplyNowBanner'
import Highlights from './Highlights/Highlights'
import HighestPackageTrends from './HighestPackageTrends/HighestPackageTrends'
import AveragePackage from './AveragePackage/AveragePackage'
import PackageTrends from './PackageTrends/PackageTrends'
import PlacedTrends from './PlacedTrends/PlacedTrends'
import SalaryStatistic from './SalaryStatistic/SalaryStatistic'
import PlacementBrochure from './PlacementBrochure/PlacementBrochure'
import Alumini from './Alumini/Alumini'
import PlacementRating from './PlacementRating/PlacementRating'

const PlacementTab = () => {
    return (
        <section className='common-univeresity'>
            <Container>
                <Row>
                    <Col xl={9} lg={8} md={8} sm={12} xs={12}>
                        <Highlights />
                        <HighestPackageTrends />
                        <AveragePackage />
                        <PackageTrends />
                        <PlacedTrends />
                        <SalaryStatistic />
                        <PlacementBrochure />
                        <Alumini />
                        <PlacementRating />
                    </Col>

                    <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                        <ApplyNowBanner />
                        <div className='mt-2'>
                            <ApplyNowBanner />
                        </div>
                        <div className='mt-2'>
                            <ApplyNowBanner />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PlacementTab