import React from 'react'
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Col, Row } from 'react-bootstrap';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SalaryStatistic = () => {

  const data = {
    labels: ['Amazon', 'Adobe', 'Ugam', 'Pegasystem', 'Gupshup Technology', 'Enfusion'],
    datasets: [
      {
        label: 'Salary (In INR LPA)',
        data: [45, 41, 18, 15.93, 15.6, 13.66],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
        barThickness: 50,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'DY Patil Talegaon Placement 2022:Salary-wise Statistics',
        font: {
          size: 25,
        },
        color: '#707070',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 50,
        title: {
          display: true,
          text: 'Salary (In INR LPA)',
          font: {
            size: 20,
          },
          color: '#707070',
        },
      },
    },
  };

  return (
    <section className='common-univeresity'>
      {/* Highest Package Trends */}
      <h5 className='heading'>DY Patil Talegaon Placements 2022: Salary-wise Statistics</h5>
      <p>Major companies offered various salary packages and the highest package i.e. INR 49 LPA offered by Paloalto Company. The below info-graphic depicts the detailed company-wise statistics of salary trends:</p>
      <div className='white-bggg mt-5'>
        <Bar data={data} options={options} />
      </div>
      <p className='mt-5'>The highest package offered was INR 45 LPA and was made by Amazon and many other companies offered good packages. List of companies and salary offered were listed below:</p>
      <div className='fee-structure'>
        <div className='white-back'>
          <div className='yellow-bgggg'>
            <Row>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <p className='bold-txtt-blue'>Company</p>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <p className='bold-txtt-blue'>Packages Offered</p>
              </Col>
            </Row>
          </div>
          <div className='white-bggg-inner'>
            <Row>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <p className='normal-txttt'>Amazon</p>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <p className='normal-txttt'>INR 45 LPA</p>
              </Col>
            </Row>
            <div className='dash-border mt-2'></div>
            <Row>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <p className='normal-txttt'>Adobe</p>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <p className='normal-txttt'>INR 41 LPA</p>
              </Col>
            </Row>
            <div className='dash-border mt-2'></div>
            <Row>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <p className='normal-txttt'>Amazon</p>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <p className='normal-txttt'>INR 41 LPA</p>
              </Col>
            </Row>
            <div className='dash-border mt-2'></div>
            <Row>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <p className='normal-txttt'>Adobe</p>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <p className='normal-txttt'>INR 41 LPA</p>
              </Col>
            </Row>
            <div className='dash-border mt-2'></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SalaryStatistic;
