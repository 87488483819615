import React from "react";
import { Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import CommonCourceCard from "../../Common_Component/CommonCourceCard/CommonCourceCard";
import { Link } from "react-router-dom";

const courseData = [
  {
    imgSrc: "/assests/Images/Hompage/trending-cources/img1.png",
    courserating: 4.8,
    universityLogo:
      "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png",
    universityName: "Dr. D. Y. Patil University",
    courseName: "MBA in Human Resource Management",
  },
  {
    imgSrc: "/assests/Images/Hompage/trending-cources/img2.png",
    courserating: 4.5,
    universityLogo:
      "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png",
    universityName: "Dr. D. Y. Patil University",
    courseName: "MBA in Business and Finance",
  },
  {
    imgSrc: "/assests/Images/Hompage/trending-cources/img3.png",
    courserating: 4.7,
    universityLogo: "/assests/Images/Hompage/trending-cources/cu-logo.png",
    universityName: "Chandigarh University",
    courseName: "BBA in Human Resources Management",
  },
  {
    imgSrc: "/assests/Images/Hompage/trending-cources/img1.png",
    courserating: 4.8,
    universityLogo:
      "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png",
    universityName: "Dr. D. Y. Patil University",
    courseName: "MBA in Human Resource Management",
  },
  {
    imgSrc: "/assests/Images/Hompage/trending-cources/img2.png",
    courserating: 4.5,
    universityLogo:
      "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png",
    universityName: "Dr. D. Y. Patil University",
    courseName: "MBA in Business and Finance",
  },
  {
    imgSrc: "/assests/Images/Hompage/trending-cources/img3.png",
    courserating: 4.7,
    universityLogo: "/assests/Images/Hompage/trending-cources/cu-logo.png",
    universityName: "Chandigarh University",
    courseName: "BBA in Human Resources Management",
  },
];

const TrendingCources = () => {
  return (
    <section className="trending-cources">
      <Container>
        <Row>
          <h4 className="heading mb-5">Trending Courses</h4>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            pagination={{ clickable: true, dynamicBullets: true }}
            breakpoints={{
              0: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              991: { slidesPerView: 2 },
              1440: { slidesPerView: 3 },
            }}
            modules={[Pagination]}
            className="myswiper"
          >
            {courseData.map((course, index) => (
              <SwiperSlide key={index}>
                <Link to='/trending-course-details'>
                  <CommonCourceCard
                    imgSrc={course.imgSrc}
                    courserating={course.courserating}
                    universityLogo={course.universityLogo}
                    universityName={course.universityName}
                    courseName={course.courseName}
                  />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </Row>
      </Container>
    </section>
  );
};

export default TrendingCources;
