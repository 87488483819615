import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './InstituteInformation.css'

const InstituteInformation = () => {
    return (
        <section className='institute-information'>
            <div>
                <h5 class="lined-heading">Institute Information</h5>
            </div>

            <Row>
                <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                    <div className='first-card'>
                        <div className='d-flex justify-content-between'>
                            <p className='normal-txtt'>Established Year</p>
                            <p className='normal-txtt'>1958</p>
                        </div>
                        <div className='blk-border'></div>
                        <div className='d-flex justify-content-between'>
                            <p className='normal-txtt'>Ownership</p>
                            <p className='normal-txtt'>Deemed</p>
                        </div>
                        <div className='blk-border'></div>
                        <p className='mt-2 bold-txtt'>Total Courses (115)</p>
                        <div className='d-flex justify-content-between'>
                            <p className='normal-txtt'>B.Tech</p>
                            <p className='normal-txtt'>27 Courses</p>
                        </div>
                    </div>
                </Col>
                <Col xl={9} lg={9} md={8} sm={12} xs={12}>
                    <Row>
                        <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                            <div className='light-blue-bggg'>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                            <div className='light-blue-bggg'>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                            <div className='light-blue-bggg'>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    )
}

export default InstituteInformation