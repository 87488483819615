import React from 'react'
import { Container } from 'react-bootstrap'
import './ProgrammEligibility.css'

const ProgrammEligibility = () => {
    return (
        <section className='programm-eligibility'>
            <Container>
                <h5 className='heading'>Program Eligibility</h5>
                <p className='sub-text mt-4'><img src={process.env.PUBLIC_URL + '/assests/Images/icon/user.png'} className='user-img me-2'  />Minimum Eligibility</p>
                <p className='text-main'> <img src={process.env.PUBLIC_URL + '/assests/Images/icon/Icon feather-check-square.png'} className='check-img me-2' />Any bachelor's degree from a recognised university</p>
            </Container>
        </section>
    )
}

export default ProgrammEligibility