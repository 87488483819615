import React from 'react'
import CommonBanner from '../../../Common_Component/CommonBanner/CommonBanner'

const EditProfileBanner = () => {
    return (
        <section>
            <CommonBanner
                bannerimg={'/assests/Images/All-Banner/profile.png'}
             />
        </section>
    )
}

export default EditProfileBanner