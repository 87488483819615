import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faEnvelope, faPen, faPlus, faSquarePhone } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import WorkExperience from '../ChoosePreference/WorkExperience/WorkExperience';
import LevelOne from '../EducationBackground/LevelOne/LevelOne';
import CourseLevelOne from '../ChoosePreference/CourseLevelOne/CourseLevelOne';

const FilledProfileData = () => {
    const percentage = 10;

    const [isOpen, setIsOpen] = useState(false);
    const [workmodal, setWorkModal] = useState(false);
    const [educationmodal, setEducationModal] = useState(false);
    const [choosemodal, setChooseModal] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    return (
        <section className='profile-data'>
            <Container>
                <div className='white-bggg'>
                    <Row>
                        <Col xl={2} lg={2} md={3} sm={6} xs={12}>
                            <div className='main-circular' style={{ width: 100, height: 100 }}>
                                <CircularProgressbar value={percentage} text={`${percentage}%`} />;
                            </div>
                        </Col>
                        <Col xl={10} lg={10} md={7} sm={6} xs={12}>
                            <div className='d-flex justify-content-between'>
                                <h3 className='user-name mb-4'>Komal Nathe</h3>
                                <div className="dropdown-container">
                                    <p className='three-dots' onClick={toggleMenu}>
                                        <FontAwesomeIcon icon={faEllipsisVertical} />
                                    </p>
                                    {isOpen && (
                                        <div className='light-cream-bggg'>
                                            <Link to='/edit-profile'><p>Edit Profile</p></Link>
                                            <Link to='/setting'><p>Setting</p></Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Row >
                                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                                    <p className='user-deatils'><FontAwesomeIcon icon={faSquarePhone} className='me-2' /> +91 9876543210</p>
                                </Col>
                                <Col xl={5} lg={5} md={6} sm={6} xs={12}>
                                    <p className='user-deatils'><FontAwesomeIcon icon={faEnvelope} className='me-2' /> mandarjoglekar@dummymail.com</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className='white-bggg white-bggg-section'>
                    <p className='title-details'>Personal Information</p>
                    <Row>
                        <Col xl={6}>
                            <div className='d-flex'>
                                <p className='user-deatils-main'>D.O.B. :</p>
                                <p className='user-deatils'>20/05/1999</p>
                            </div>
                            <div className='d-flex'>
                                <p className='user-deatils-main'>Address :</p>
                                <p className='user-deatils' >At Post Bavdan, District Pune, Maharashtra 411021</p>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div className='d-flex'>
                                <p className='user-deatils-main'>Gender :</p>
                                <p className='user-deatils' >Female</p>
                            </div>
                            <div className='d-flex'>
                                <p className='user-deatils-main'>Social Links :</p>
                                <p className='user-deatils'>https://twitter.com/in/mandarjoglekar</p>
                            </div>
                        </Col>
                    </Row>

                </div>
                <div className='white-bggg white-bggg-section'>
                    <div className='d-flex justify-content-between'>
                        <p className='title-details'>Your desired education preferences</p>
                        <p className='blue-txttt' onClick={()=>setChooseModal (true)}>Choose Preference</p>
                    </div>
                    <CourseLevelOne
                        show={choosemodal}
                        onHide={() => setChooseModal(false)}
                    />
                    <p className='user-deatils'>Based on your preferences (e.g stream, course, specialization ) we’ll recommend the best fit colleges.</p>
                    <Row>
                        <Col xl={4} lg={5} md={6} sm={12} xs={12}>
                            <div className='grey-bgggg'>
                                <div className='d-flex justify-content-between'>
                                    <span className='user-deatils'>Education</span>
                                    <FontAwesomeIcon icon={faPen} />
                                </div>
                                <div>
                                    <span className='user-deatils'>Online, Year of Addmission 2024</span>
                                    <h6 className='user-deatils-main'>Executive MBA/PGDM</h6>
                                </div>
                                <div>
                                    <span className='user-deatils'>Specialization</span>
                                    <h6 className='user-deatils-main'>Corporate Finance</h6>
                                </div>
                                <div>
                                    <span className='user-deatils'>Pref. City</span>
                                    <h6 className='user-deatils-main'>Pune</h6>
                                </div>
                                <div>
                                    <span className='user-deatils'>Budget</span>
                                    <h6 className='user-deatils-main'>Less than 5 lakh</h6>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </div>
                <div className='white-bggg white-bggg-section'>
                    <div className='d-flex justify-content-between'>
                        <p className='title-details'>Education Background</p>
                        <p className='blue-txttt' onClick={() => setEducationModal(true)}><FontAwesomeIcon icon={faPlus} /> Add</p>
                    </div>
                    <LevelOne
                        show={educationmodal}
                        onHide={() => setEducationModal(false)}
                    />
                    <Row>
                        <Col xl={4} lg={5} md={6} sm={12} xs={12}>
                            <div className='grey-bgggg'>
                                <div className='d-flex justify-content-between'>
                                    <span className='user-deatils'>BBA Corporate Finance</span>
                                    <FontAwesomeIcon icon={faPen} className='social-icon' />
                                </div>
                                <div>
                                    <span className='user-deatils-secon'>Dr. D.Y. Patil Vidyapeeth, Pune</span>
                                    <p className='user-deatils'>Scored 8.0 CGPA,2023</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='white-bggg white-bggg-section mb-5'>
                    <div className='d-flex justify-content-between'>
                        <p className='title-details'>Work Experience</p>
                        <p className='blue-txttt' onClick={() => setWorkModal(true)}><FontAwesomeIcon icon={faPlus} /> Add</p>
                    </div>
                    <WorkExperience
                        show={workmodal}
                        onHide={() => setWorkModal(false)}
                    />
                    <Row>
                        <Col xl={4} lg={5} md={6} sm={12} xs={12}>
                            <div className='grey-bgggg'>
                                <div className='d-flex justify-content-between'>
                                    <span className='user-deatils'>UI designer</span>
                                    <FontAwesomeIcon icon={faPen} />
                                </div>
                                <div>
                                    <span className='user-deatils-secon'>Profcyma Solutions Pvt. Ltd.</span>
                                    <p className='user-deatils'>Dec 2022</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default FilledProfileData