import React from 'react'
import CommonBanner from '../../../Common_Component/CommonBanner/CommonBanner'

const SettingBanner = () => {
    return (
        <section className='setting-banner'>
            <CommonBanner
                bannerimg={'/assests/Images/All-Banner/profile.png'}
            />
        </section>
    )
}

export default SettingBanner