import React from "react";
import "./Online_degree_card.css";

function Online_degree_card({ image, cardtext }) {
  return (
    <>
      <div className="Online_degree_card">
        <div className="Online_degree_card_holder">
          <div className="img-holder">
            <img src={image} className="online-degree-img" />
          </div>

          <div className="image-text-holder">
            <p>{cardtext}</p>
          </div>

          <div className="overlay-holder"></div>
        </div>
      </div>
    
    </>
  );
}

export default Online_degree_card;
