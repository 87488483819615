import React from 'react'
import EditProfile from './EditProfile/EditProfile'
import EditProfileBanner from './EditProfileBanner/EditProfileBanner'

const EditProfilePage = () => {
    return (
        <>
            <EditProfileBanner />
            <EditProfile />
        </>
    )
}

export default EditProfilePage