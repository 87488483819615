import React from 'react'
import CommonBanner from '../../Common_Component/CommonBanner/CommonBanner'
import { Col, Row } from 'react-bootstrap'
import './TrendingCourseBanner.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark, faDownload, faStar } from '@fortawesome/free-solid-svg-icons'

const TrendingCourseBanner = () => {
  return (
    <section className='university-banner'>
      <CommonBanner bannerimg={'/assests/Images/All-Banner/universitybanner.png'} />
      <div className='banner-divvv'>
        <Row>
          <Col lg={5} md={4} sm={6} xs={3}>
            <div className='main-img'>
              <img src={process.env.PUBLIC_URL + '/assests/Images/University/dpu.png'} className='uni-imgg' alt="university logo" />
            </div>
          </Col>
          <Col lg={7} md={8} sm={6} xs={9}>
            <p className='uni-txtt'>MBA in Human Resource Management</p>
            <div className='d-flex'>
              <p className='txtt me-3'>Dr. D. Y. Patil Univerisity</p>
              <p className='rating-txtt'> <FontAwesomeIcon icon={faStar} /> 4.8</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className='social-icon-divvv'>
        <div className='icon-bgg'>
          <FontAwesomeIcon icon={faBookmark} />
        </div>
        <div className='icon-bgg'>
          <FontAwesomeIcon icon={faDownload} />
        </div>
        <div className='icon-bgg'>
          <img src={process.env.PUBLIC_URL + '/assests/Images/icon/compare.png'} className='icon-imggg-txtt' />
        </div>
      </div>
    </section>
  )
}

export default TrendingCourseBanner