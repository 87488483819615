import React from 'react'
import { Form, Modal } from 'react-bootstrap'

const WorkExperience = (props) => {
    return (
        <section>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className='change-pass-modal common-modalll-class'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='modal-header-class'>
                        Work Experience
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='form-holder'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Is this your current company?</Form.Label>
                            <div className='d-flex'>
                                <Form.Check type="radio" label="Yes" className='me-3' />
                                <Form.Check type="radio" label="No" />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control type="text" placeholder="Total Work Experience" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control type="text" placeholder="Company Name*" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control type="text" placeholder="Designation*" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control type="text" placeholder="Department*" />
                        </Form.Group>
                        <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                            <Form.Label>Date of Joining*</Form.Label>
                            <Form.Control type="date" placeholder="Date of Joining*" />
                        </Form.Group>
                        <div className='text-center '>
                            <button className='orange-btnnn me-2'>Save</button>
                            <button className='white-btnnn'>Cancel</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default WorkExperience