import React from 'react'
import WithoutLoginBanner from './WithoutLoginBanner/WithoutLoginBanner'
import LoginToExplore from '../WithoutLoginTrendingCourse/LoginToExplore/LoginToExplore'
import PopularComparision from '../CourseDetailPage/OverViewTab/PopularComparision/PopularComparision'
import { Container } from 'react-bootstrap'
import SimilarCollege from '../Common_Component/SimilarCollege/SimilarCollege'
import InstituteInformation from './InstituteInformation/InstituteInformation'
import CourseDetails from './CourseDetails/CourseDetails'

const WithoutLoginUniversity = () => {
    return (
        <>
            <section>
                <Container>
                    <SimilarCollege />
                    <InstituteInformation />
                    <CourseDetails />
                    <WithoutLoginBanner />
                    <LoginToExplore />
                    <PopularComparision />
                </Container>
            </section>
        </>
    )
}

export default WithoutLoginUniversity