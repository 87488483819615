import React from "react";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faLinkedin,
  faSquareInstagram,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import Universities_Modal from "../Common_Component/Common_modal/Universities_Modal/Universities_Modal";

const Footer = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <section className="main-footer">
        <Container>
          <div className="footer-bggg">
            <Row className="justify-content-center">
              <Col md={2}>
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assests/Images/Logo/logo.png"
                    }
                    className="logo-img"
                    alt="..."
                  />
                </div>
              </Col>
              <Col md={2}>
                <Link to={"/cources"}>
                  <p className="footer-txttt-white">Courses</p>
                </Link>
              </Col>
              <Col md={2}>
                <p
                  className="footer-txttt-white"
                  onClick={() => setModalShow(true)}
                >
                  Universities
                </p>
              </Col>
              <Col md={2}>
                <Link to={"/contact-us"}>
                  <p className="footer-txttt-white">Contact Us</p>
                </Link>
              </Col>
              <Col md={2}>
                <Link to={"/faq"}>
                  <p className="footer-txttt-white">FAQs</p>
                </Link>
              </Col>
            </Row>
          </div>
          <Row className="mt-5">
            <Col md={6} className="text-center">
              <p className="footer-txttt">
                <FontAwesomeIcon icon={faPhone} className="font-icon" />
                1800-267-8961 / +91 96077 55557
              </p>
            </Col>
            <Col md={6} className="text-center">
              <p className="footer-txttt">
                <FontAwesomeIcon icon={faEnvelope} className="font-icon" />
                inquiry@profcyma.com
              </p>
            </Col>
            <Col md={12} className="text-center">
              <p className="footer-txttt">
                <FontAwesomeIcon icon={faLocationDot} className="font-icon" />{" "}
                2nd floor, Office No. 3, Opp High-Class Soc., M. No. 734 Near
                Suryadutta College, <br /> Bavdhan Khurd, Pune 411021.
              </p>
            </Col>
          </Row>
          <div className="text-center">
            <Link
              to="https://www.linkedin.com/feed/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                className="footer-social-white-icon"
              />
            </Link>
            <Link
              to="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                className="footer-social-white-icon"
              />
            </Link>
            <Link
              to="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faSquareInstagram}
                className="footer-social-white-icon"
              />
            </Link>
            <Link
              to="https://x.com/i/flow/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faSquareXTwitter}
                className="footer-social-white-icon"
              />
            </Link>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <Link to="/terms-condition">
              <p className="term-txttt me-2">Terms & Conditions</p>
            </Link>
            <div className="orange-border"></div>
            <Link to="/privacy-policy">
              <p className="term-txttt ms-1">Privacy Policy</p>
            </Link>
          </div>
          <div className="text-center">
            <p className="footer-txttt mt-3">
              © 2024 All Rights Reserved Terms Of Use | Privacy Policy Powered
              by Profcyma
            </p>
          </div>
        </Container>
      </section>

      <Universities_Modal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Footer;
