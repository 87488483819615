import React, {useState} from 'react'
import './AccountSetting.css'
import { Container } from 'react-bootstrap'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChangePasswordModal from '../SettingModal/ChangePasswordModal'
import DeactivateAccount from '../SettingModal/DeactivateAccount/DeactivateAccount'
import DeleteAccount from '../SettingModal/DeleteAccount/DeleteAccount'

const AccountSetting = () => {
    const [changepasswordModal, SetChangePasswordModal] = useState(false);
    const [deactivateaccountmodal, SetDeactivateAccountModal] = useState (false);
    const [deleteaccountmodal, SetDeleteAccountModal] = useState(false);
  return (
    <section className='account-setting'>
        <Container>
            <div className='white-bggg'>
                  <h4 className='account-title mb-4'>Account Settings</h4>
                  <div className='main-class-setting d-flex justify-content-between' onClick={()=> SetChangePasswordModal(true)}>
                      <p className='setting-txttt'>Change Password</p> 
                      <FontAwesomeIcon icon={faAngleRight} className='right-angal-icon' />
                  </div>
                  <ChangePasswordModal
                      show={changepasswordModal}
                      onHide={() => SetChangePasswordModal(false)}
                  />
                  <div className='main-class-setting d-flex justify-content-between' onClick={()=>SetDeactivateAccountModal(true)}>
                      <p className='setting-txttt' >Deactivate Account</p>
                      <FontAwesomeIcon icon={faAngleRight} className='right-angal-icon' />
                  </div>
                  <DeactivateAccount
                      show={deactivateaccountmodal}
                      onHide={() => SetDeactivateAccountModal(false)}
                  />
                  <div className='main-class-setting d-flex justify-content-between' onClick={() => SetDeleteAccountModal(true)}>
                      <p className='setting-txttt'>Delete Account</p>
                      <FontAwesomeIcon icon={faAngleRight} className='right-angal-icon' />
                  </div>
                  <DeleteAccount
                      show={deleteaccountmodal}
                      onHide={() => SetDeleteAccountModal(false)}
                  />
            </div>
        </Container>
    </section>
  )
}

export default AccountSetting