import React from 'react';
import './GetCounsilling.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import ReactStars from "react-rating-stars-component";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Col, Row } from 'react-bootstrap';

const universities = [
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png',
        name: 'Dr. D. Y. Patil University',
        location: 'Pune',
        rank: '#1 NIRF',
        coursesOffered: 'Courses Offered : 3',
        feeRange: 'Fee Range : ₹ 24.2 L',
    },
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png',
        name: 'Dr. D. Y. Patil University',
        location: 'Pune',
        rank: '#1 NIRF',
        coursesOffered: 'Courses Offered : 3',
        feeRange: 'Fee Range : ₹ 24.2 L',
    },
    {
        imgSrc: '/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png',
        name: 'Dr. D. Y. Patil University',
        location: 'Pune',
        rank: '#1 NIRF',
        coursesOffered: 'Courses Offered : 3',
        feeRange: 'Fee Range : ₹ 24.2 L',
    },
];

const GetCounsilling = () => {
    const ratingChanged = (newRating) => {
        console.log(newRating);
    };

    return (
        <section className='get-counsilling'>
            <div className='main-img-class'>
                <img src={process.env.PUBLIC_URL + '/assests/Images/Cources/counsellingbg.png'} className='bg-counselling' alt="counselling background" />
                <button className='get-councelling'>Get Councelling</button>
            </div>
            <div className='yellow-bgggg'>
                <h5 className='heading'>Explore More MBA Colleges for You</h5>
                <Swiper
                    spaceBetween={50}
                    pagination={{ clickable: true, dynamicBullets: true }}
                    breakpoints={{
                        0: { slidesPerView: 1 },
                        768: { slidesPerView: 2 },
                        991: { slidesPerView: 2 },
                        1440: { slidesPerView: 2 },
                        1600: { slidesPerView: 2.5 },
                    }}
                    modules={[Pagination]}
                    className="myswiper mt-5"
                >
                    {universities.map((university, index) => (
                        <SwiperSlide className='mb-5' key={index}>
                            <div className='university-card'>
                                <Row>
                                    <Col lg={2} md={2} sm={3} xs={3}>
                                        <img src={process.env.PUBLIC_URL + university.imgSrc} className='uni-imgg' alt="university logo" />
                                    </Col>
                                    <Col lg={10} md={10} sm={9} xs={9}>
                                        <p className='uni-txtt'>{university.name}</p>
                                        <div className='d-flex'>
                                            <p className='txtt'><FontAwesomeIcon icon={faLocationDot} /> {university.location}</p>
                                            <div className='blk-border'></div>
                                            <p className='txtt'>{university.rank}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='d-flex justify-content-between mt-4'>
                                    <p className='course-txtt'>{university.coursesOffered}</p>
                                    <p className='fee-txttt'>{university.feeRange}</p>
                                </div>
                                <div className='d-flex justify-content-between mt-3'>
                                    <ReactStars
                                        count={1}
                                        onChange={ratingChanged}
                                        size={15}
                                        activeColor="#ffd700"
                                    />
                                    <FontAwesomeIcon icon={faBookmark} className='book-icon' />
                                    <button className='down-brochure'>Download Brochure</button>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
};

export default GetCounsilling;
