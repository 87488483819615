import React from 'react';
import './ScheduleCall.css';
import { Container } from 'react-bootstrap';
import Common_btn from '../../Common_Component/Common_btn/Common_btn';
import CommonSuccessModal from '../../Common_Component/CommonSuccessModal/CommonSuccessModal';

const ScheduleCall = () => {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <section className='schedule-call'>
            <Container>
                <div className='text-center'>
                    <h4 className='confused-txttt'>Confused? Get Assistance</h4>
                    <Common_btn className="schedule-call-btn mt-4" onClick={() => setModalShow(true)} text="Schedule a Call" />
                    <CommonSuccessModal
                        modalheading={'Thank You for Showing Interest'}
                        modalsubheading={'Our Expert will get back to you soon to wipe off a confusion!'}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </div>
            </Container>
        </section>
    );
}

export default ScheduleCall;
