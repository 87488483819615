import React from 'react'
import './OnlineDegree.css'
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap'


const OnlineDegree = () => {
    return (
        <section className='online-degree'>
            <Container>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <h5 className='heading'>Accredited Online Degree </h5>
                        <p className='text-main mt-4'>Elevate your career with a NAAC A+ accredited Online MBA Program in Human Resource Management, designed by industry leaders for academic success.</p>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
                                <div className='main-class-img'>
                                    <img src={process.env.PUBLIC_URL + '/assests/Images/icon/1.png'} className='img-classs' />
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
                                <div className='main-class-img'>
                                    <img src={process.env.PUBLIC_URL + '/assests/Images/icon/2.png'} className='img-classs' />
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
                                <div className='main-class-img'>
                                    <img src={process.env.PUBLIC_URL + '/assests/Images/icon/3.png'} className='img-classs' />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default OnlineDegree