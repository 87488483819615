import React from 'react'
import './MbaHighlights.css'
import { Col, Row } from 'react-bootstrap'

const MbaHighlights = () => {
    return (
        <section className='online-mba'>
            <div className=''>
                <h5 className='heading'>Online MBA Highlights</h5>
                <p className='text-main '>There are several colleges offering online MBA programs in India. Students can take admission to online MBA courses to gain and upscale their managerial skills. The following table provides a quick look at the details of online MBA courses:</p>
            </div>
            <div>
                <Row>
                    <Col xl={4}>
                        <p className='white-title' >Features</p>
                    </Col>
                    <Col xl={8}>
                        <p className='white-title'>Details</p>
                    </Col>
                </Row>
                <div className='feture-below'>
                    <Row>
                        <Col xl={4}>
                            <p>Course Name</p>
                        </Col>
                        <Col xl={8}>
                            <p>Online MBA</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <p>Course Level</p>
                        </Col>
                        <Col xl={8}>
                            <p>Post Graduation</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <p>Course Duration</p>
                        </Col>
                        <Col xl={8}>
                            <p>1-2 years</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <p>Course Criteria</p>
                        </Col>
                        <Col xl={8}>
                            <ul>
                                <li>Graduation with a minimum 50% score</li>
                                <li>Work experience (for some programmes)</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <p>Entrance Exams</p>
                        </Col>
                        <Col xl={8}>
                            <p>CAT, XAT, MAT, SNAP, and GMAT (for some colleges)</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <p>Admission Process</p>
                        </Col>
                        <Col xl={8}>
                            <p>Merit/entrance exam based</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <p>Course Fee</p>
                        </Col>
                        <Col xl={8}>
                            <p>INR 50,000 - INR 20 lakh</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <p>Top Online MBA Specialisations</p>
                        </Col>
                        <Col xl={8}>
                            <span>Operations Management,</span>
                            <span>Finance,</span>
                            <span>Human Resource Management,</span>
                            <span>Marketing</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <p>Top Online MBA Colleges</p>
                        </Col>
                        <Col xl={8}>
                            <span>Amity University Online,</span>
                            <span>IIMs,,</span>
                            <span>BITS Pilani</span>
                            <span>NMIMS Global Access School for Continuing Education,</span>
                        </Col>
                    </Row>
                </div>
            </div>
        </section>
    )
}

export default MbaHighlights