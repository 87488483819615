import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Col, Row } from 'react-bootstrap';

const placementData = [
    {
        studentsType: "MD/ MS",
        totalStudents: 204,
        studentsPlaced: 180,
        medianPackage: "INR 25.45 LPA"
    },
    {
        studentsType: "BDS",
        totalStudents: 58,
        studentsPlaced: 58,
        medianPackage: "INR 6.55 LPA"
    },
    {
        studentsType: "MDS",
        totalStudents: 42,
        studentsPlaced: 42,
        medianPackage: "INR 9.91 LPA"
    }
];

const Placement = () => {
    return (
        <section className='common-university'>
            <h5 className='uni-sec-title'>DY Patil Pune Placements 2024</h5>
            <p>Dr. D.Y. Patil Vidyapeeth, Pune (Deemed to be University) NIRF 2024 placement report has been released. As per the report, 180 MD/ MS, 58 BDS, and 42 MDS students were placed during 2023 placements. The median package offered to MD/MS students is INR 25.45 LPA. For BDS and MDS courses, the median package stood at INR 6.55 LPA and INR 9.91 LPA, respectively. The key highlights of Dr. D.Y. Patil Vidyapeeth, Pune placements 2023 are tabulated below:</p>
            <Swiper
                spaceBetween={30}
                pagination={{ clickable: true, dynamicBullets: true }}
                breakpoints={{
                    0: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    991: { slidesPerView: 2 },
                    1440: { slidesPerView: 2 },
                }}
                modules={[Pagination]}
                className="myswiper mt-5"
            >
                {placementData.map((data, index) => (
                    <SwiperSlide key={index} className='mb-5'>
                        <div className='yellow-bgggg'>
                            <Row>
                                <Col md={6} sm={6} xs={12}>
                                    <p className='bold-txtt-placement'>Particulars</p>
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                    <p className='bold-txtt-placement'>Placement Statistics (2023)</p>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col md={6} sm={6} xs={12}>
                                    <p>Total students ({data.studentsType})</p>
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                    <p>{data.totalStudents}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} sm={6} xs={12}>
                                    <p>Students placed ({data.studentsType})</p>
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                    <p>{data.studentsPlaced}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} sm={6} xs={12}>
                                    <p className='bold-txtt-placement'>Median package ({data.studentsType})</p>
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                    <p className='bold-txtt-placement'>{data.medianPackage}</p>
                                </Col>
                            </Row>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
}

export default Placement;
