import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Row } from 'react-bootstrap'


const Highlights = () => {
    return (
        <section className='common-univeresity'>
            <h5 className='heading'>DY Patil Talegaon Placements 2022: Highlights</h5>
            <p>The key highlights of DY Patil Talegaon placements 2021 and 2022 are tabulated below:</p>
            <div className='fee-structure'>
                <div className='white-back'>
                    <div className='yellow-bgggg'>
                        <Row>
                            <Col xl={4} lg={4} md={4}>
                                <p className='bold-txtt-blue'>Parameter</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='bold-txtt-blue'>Statistics (2021)</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='bold-txtt-blue'>Statistics (2022)</p>
                            </Col>
                        </Row>
                    </div>
                    <div className='white-bggg-inner'>
                        <Row>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>Highest package</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>INR 43 LPA</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>INR 45 LPA </p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>Median package</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>INR 5.9 LPA(BTech)</p>
                                <p className='normal-txttt'>INR 4.9 LPA(BTech)</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>INR 6.9 LPA(BTech)</p>
                                <p className='normal-txttt'>INR 5.9 LPA(BTech)</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>Companies participated</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>25+</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>50+</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>Students placed</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>600+</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>1300+</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>Popular recruiters</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>Microsoft, SE2, Kickdrum</p>
                            </Col>
                            <Col xl={4} lg={4} md={4}>
                                <p className='normal-txttt'>Amazon, Adobe, Barclays</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                       
                    </div>
                </div>


            </div>
            <div className='mt-4 ms-4'>
                <p className='note-txttt'>NOTE:<span className='note-span'>The aforementioned details are collated from the NIRF report 2023.</span> </p>
            </div>
        </section>
    )
}

export default Highlights