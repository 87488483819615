import React from 'react'
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const HighestPackageTrends = () => {

    const data = {
        labels: ['2020', '2021', '2022'],
        datasets: [
            {
                label: 'Salary (In INR LPA)',
                data: [24, 43, 45,],
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
                barThickness: 100,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'DY Patil Talegaon Highest Package Trends',
                font: {
                    size: 25,
                },
                color: '#707070',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 50,
                title: {
                    display: true,
                    text: 'Salary (In INR LPA)',
                    font: {
                        size: 20,
                    },
                    color: '#707070',
                },
            },
        },
    };

    return (
        <section className='common-univeresity'>
            {/* Highest Package Trends */}
            <h5 className='heading'>DY Patil Talegaon Highest Package Trends</h5>
            <p>The highest package offered to students over the past three years indicates an improving trend. See the below infographic showcasing the DY Patil Ambi highest package trends:</p>
            <div className='white-bggg mt-5'>
                <Bar data={data} options={options} />
            </div>
        </section>
    )
}

export default HighestPackageTrends;
