import React from 'react'
import HomeBanner from './HomeBanner/HomeBanner'
import ScheduleCall from "./ScheduleCall/ScheduleCall";
import Online_Degree from "./Online_Degree/Online_Degree";
import Top_Approved from "./Top_Approved/Top_Approved";
import ConfusedGet from "./ConfusedGet/ConfusedGet";
import TrendingCources from "./TrendingCources/TrendingCources";
import CollegeStep from "./CollegeStep/CollegeStep";
import IndustryExpert from "./IndustryExpert/IndustryExpert";
import LatestNews from "./LatestNews/LatestNews";

const HomePage = () => {
    return (
        <>
            <HomeBanner />
            <ScheduleCall />
            <Online_Degree />
            <Top_Approved />
            <ConfusedGet />
            <TrendingCources />
            <CollegeStep />
            <IndustryExpert />
            <LatestNews />
        </>
    )
}

export default HomePage