import React from 'react'
import { Col, ProgressBar, Row } from 'react-bootstrap'
import ReactStars from 'react-stars'
import './InfraReviews.css'



const ratingChanged = (newRating) => {
    console.log(newRating)
}

const InfraReviews = () => {
    const now = 60;
    return (
        <section className='common-univeresity'>
            <div className='grey-bggg'>
                <h5 className='heading'>DY Patil, Infrastructure Reviews</h5>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className='d-flex'>
                            <h5>Overall Placement Rating</h5>
                            {/* <p>(Out of 5)</p>*/}
                        </div>
                        <div className='d-flex'>
                            <ReactStars
                                count={1}
                                onChange={ratingChanged}
                                size={50}
                                color2={'#ffd700'} />
                            <p className='rating-number mt-3 ms-3'>4.7</p>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div >
                            <h5>Distribution of Rating</h5>
                            <Row className='mt-4'>
                                <Col xl={4} lg={4} md={4} sm={6} xs={6} >
                                    <p className='blue-txtt'>4-5 star</p>
                                </Col>
                                <Col xl={8} lg={8} md={8} sm={6} xs={6} >
                                    <ProgressBar now={now} label={`${now}%`} className="custom-progress-bar" />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xl={4} lg={4} md={4} sm={6} xs={6} >
                                    <p className='blue-txtt'>3-4 star</p>
                                </Col>
                                <Col xl={8} lg={8} md={8} sm={6} xs={6} >
                                    <ProgressBar now={now} label={`${now}%`} className="custom-progress-bar" />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xl={4} lg={4} md={4} sm={6} xs={6} >
                                    <p className='blue-txtt'>2-3 star</p>
                                </Col>
                                <Col xl={8} lg={8} md={8} sm={6} xs={6} >
                                    <ProgressBar now={now} label={`${now}%`} className="custom-progress-bar" />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xl={4} lg={4} md={4} sm={6} xs={6} >
                                    <p className='blue-txtt'>1-2 star</p>
                                </Col>
                                <Col xl={8} lg={8} md={8} sm={6} xs={6} >
                                    <ProgressBar now={now} label={`${now}%`} className="custom-progress-bar" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default InfraReviews