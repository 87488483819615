import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./LoginSignupCommon.css";
import Congrats from "./Congrats";

const EnterOtp = (props) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <section>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common-modalll-class"
      >
        <Modal.Body>
          <Row>
            <Col xl={5} lg={5} md={12} sm={12} xs={12}>
              <div className="first-section">
                <h3 className="signup-txttt">
                  <span className="orange-border">Log</span> In
                </h3>
                <p className="below-txttt ">Welcome back to make your mark!</p>
              </div>
            </Col>
            <Col xl={5} lg={7} md={12} sm={12} xs={12}>
              <div className="orange-bgg">
                <div className="white-bggg">
                  <h3 className="signup-txtttttt mb-3">Enter OTP</h3>
                  <div className="d-flex justify-content-between">
                    <p className="form-txttt">OTP sent on +919876543210</p>
                    <p className="login-txtt">Edit Number</p>
                  </div>
                  <div className="form-main">
                    <Form>
                      <Row className="mt-5">
                        <Col lg={2}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                        <Col lg={2}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                        <Col lg={2}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                        <Col lg={2}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control type="text" />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                    <p className="second-line">
                      <span className="resend-txttt">Resend</span> in 30 seconds
                    </p>
                    <button
                      className="get-btnnn mb-5 mt-5"
                      onClick={() => {
                        
                          setModalShow(true);
                          props.onHide();
                        
                      }}
                    >
                      Continue
                    </button>
                  </div>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assests/Images/Login-Signup/bg-img.png"
                    }
                    className="bg-img"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Congrats show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
};

export default EnterOtp;
