import React from 'react'
import { Col, Row } from 'react-bootstrap'


const CourseDetails = () => {
    return (
        <section className='institute-information'>
            <div>
                <h5 class="lined-heading">Course Details</h5>
            </div>
            <Row>
                <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                    <div className='first-card'>
                        <div className='d-flex justify-content-between'>
                            <p className='normal-txtt'>Course Credential</p>
                            <p className='normal-txtt'>Degree</p>
                        </div>
                        <div className='blk-border'></div>
                        <div className='d-flex justify-content-between mt-2'>
                            <p className='normal-txtt'>Course Level</p>
                            <p className='normal-txtt'>PG</p>
                        </div>
                        <div className='blk-border'></div>
                        <div className='d-flex justify-content-between'>
                            <p className='normal-txtt'>Duration</p>
                            <p className='normal-txtt'>24 Months</p>
                        </div>
                        <div className='blk-border'></div>
                        <div className='d-flex justify-content-between'>
                            <p className='normal-txtt'>Mode</p>
                            <p className='normal-txtt'>Online</p>
                        </div>
                    </div>
                </Col>
                <Col xl={9} lg={9} md={8} sm={12} xs={12}>
                    <Row>
                        <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                            <div className='light-blue-bggg'>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                            <div className='light-blue-bggg'>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                            <div className='light-blue-bggg'>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    )
}

export default CourseDetails