import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './ConfusedGet.css'

const ConfusedGet = () => {
    return (
        <section className='confused-get'>
            <Container>
                <h4 className='heading'>Confused? Get Assistance</h4>
                <Row className='mt-5'>
                    <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                        <button className='confused-btnnn'>I just want a Degree</button>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                        <button className='confused-btnnn'>I want a Promotion</button>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                        <button className='confused-btnnn'>I want a New Job</button>
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                        <button className='confused-btnnn'>I want Expert Advice</button>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ConfusedGet