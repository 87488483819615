import { faChevronDown, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Row } from 'react-bootstrap'


const StudentReviews = () => {
    return (
        <section className='common-univeresity'>
            <div>
                <h5 className='heading mt-4'>What students say about Dr. D.Y. Patil Institute Of Technology</h5>
                <Row className='mt-5'>
                    <Col xl={1}>
                        <div className='grey-bg'>
                            <FontAwesomeIcon icon={faUser} className='user-icon' />
                        </div>
                    </Col>
                    <Col xl={11}>
                        <div className='d-flex justify-content-between'>
                            <p className='bold-txtt-blue'>Anonymous | B.E. in Information Technology - Batch of 2023</p>
                            <p className='verfied-txttt'><img src={process.env.PUBLIC_URL + '/assests/Images/icon/verify.png'} className='verify-img me-1' />Verified</p>
                        </div>
                        <span className='bold-txtt-blue'>Reviewed on 21 Jun 2024</span>
                        <p>Best college for engineering in Pune.</p>
                        <p>The infrastructure of the college is very great. All classrooms are very special, with good-quality benches in classrooms and a green board in each classroom. The auditorium is also available on campus, and there is also a stadium available for students to play games like cricket. Football fields are also available on campus. A canteen is also available in college with great infrastructure.</p>
                        <button className='read-more-btn'>Read More</button>
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col xl={1}>
                        <div className='grey-bg'>
                            <FontAwesomeIcon icon={faUser} className='user-icon' />
                        </div>
                    </Col>
                    <Col xl={11}>
                        <div className='d-flex justify-content-between'>
                            <p className='bold-txtt-blue'>Anonymous | B.E. in Information Technology - Batch of 2023</p>
                            <p className='verfied-txttt'><img src={process.env.PUBLIC_URL + '/assests/Images/icon/verify.png'} className='verify-img me-1' />Verified</p>
                        </div>
                        <span className='bold-txtt-blue'>Reviewed on 21 Jun 2024</span>
                        <p>Best college for engineering in Pune.</p>
                        <p>The infrastructure of the college is very great. All classrooms are very special, with good-quality benches in classrooms and a green board in each classroom. The auditorium is also available on campus, and there is also a stadium available for students to play games like cricket. Football fields are also available on campus. A canteen is also available in college with great infrastructure.</p>
                        <button className='read-more-btn'>Read More</button>
                    </Col>
                </Row>

                <Row className='mt-5'>
                    <Col xl={1}>
                        <div className='grey-bg'>
                            <FontAwesomeIcon icon={faUser} className='user-icon' />
                        </div>
                    </Col>
                    <Col xl={11}>
                        <div className='d-flex justify-content-between'>
                            <p className='bold-txtt-blue'>Anonymous | B.E. in Information Technology - Batch of 2023</p>
                            <p className='verfied-txttt'><img src={process.env.PUBLIC_URL + '/assests/Images/icon/verify.png'} className='verify-img me-1' />Verified</p>
                        </div>
                        <span className='bold-txtt-blue'>Reviewed on 21 Jun 2024</span>
                        <p>Best college for engineering in Pune.</p>
                        <p>The infrastructure of the college is very great. All classrooms are very special, with good-quality benches in classrooms and a green board in each classroom. The auditorium is also available on campus, and there is also a stadium available for students to play games like cricket. Football fields are also available on campus. A canteen is also available in college with great infrastructure.</p>
                        <button className='read-more-btn'>Read More</button>
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col xl={1}>
                        <div className='grey-bg'>
                            <FontAwesomeIcon icon={faUser} className='user-icon' />
                        </div>
                    </Col>
                    <Col xl={11}>
                        <div className='d-flex justify-content-between'>
                            <p className='bold-txtt-blue'>Anonymous | B.E. in Information Technology - Batch of 2023</p>
                            <p className='verfied-txttt'><img src={process.env.PUBLIC_URL + '/assests/Images/icon/verify.png'} className='verify-img me-1' />Verified</p>
                        </div>
                        <span className='bold-txtt-blue'>Reviewed on 21 Jun 2024</span>
                        <p>Best college for engineering in Pune.</p>
                        <p>The infrastructure of the college is very great. All classrooms are very special, with good-quality benches in classrooms and a green board in each classroom. The auditorium is also available on campus, and there is also a stadium available for students to play games like cricket. Football fields are also available on campus. A canteen is also available in college with great infrastructure.</p>
                        <button className='read-more-btn'>Read More</button>
                    </Col>
                </Row>
                <div className='fee-structure text-center mt-4'>
                    <button className='view-course-btnnn'>View More <FontAwesomeIcon icon={faChevronDown} /></button>
                </div>
            </div>
        </section>
    )
}

export default StudentReviews