import React from 'react'
import TrendingCourseBanner from '../TrendingCoursesDetails/TrendingCourseBanner/TrendingCourseBanner'
import FacilitySection from '../TrendingCoursesDetails/FacilitySection/FacilitySection'
import OnlineDegree from '../TrendingCoursesDetails/OnlineDegree/OnlineDegree'
import ProgrammEligibility from '../TrendingCoursesDetails/ProgrammEligibility/ProgrammEligibility'
import LoginToExplore from './LoginToExplore/LoginToExplore'

const WithoutLoginTrendingCourse = () => {
    return (
        <>
            <TrendingCourseBanner />
            <FacilitySection/>
            <OnlineDegree/>
            <ProgrammEligibility/>
            <LoginToExplore />
        </>
    )
}

export default WithoutLoginTrendingCourse