import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./LoginSignupCommon.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import EnterOtp from "./EnterOtp";

const ForgetPassword = (props) => {
  const [phone, setPhone] = useState("");
  const [entermodalShow, setEnterModalShow] = React.useState(false);
  return (
    <section>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common-modalll-class"
      >
        <Modal.Body>
          <Row>
            <Col xl={5} lg={5} md={12} sm={12} xs={12}>
              <div className="first-section">
                <h3 className="signup-txttt">
                  <span className="orange-border">Log</span> In
                </h3>
                <p className="below-txttt ">Welcome back to make your mark!</p>
              </div>
            </Col>
            <Col xl={5} lg={7} md={12} sm={12} xs={12}>
              <div className="orange-bgg">
                <div className="white-bggg">
                  <h3 className="signup-txtttttt mb-5 mt-3">
                    Enter Mobile Number
                  </h3>
                  <div className="form-main">
                    <Form className="form-holder">
                      <Form.Group className="mb-5" controlId="formBasicPhone">
                        <PhoneInput
                          country={"eg"}
                          enableSearch={true}
                          value={phone}
                          onChange={(phone) => setPhone(phone)}
                          containerClass="phone-input-container"
                          inputClass="phone-input-field"
                          buttonClass="phone-input-button"
                        />
                      </Form.Group>
                    </Form>
                    <button
                      className="get-btnnn mb-5 mt-5"
                      onClick={() => {
                        setEnterModalShow(true);
                        props.onHide();
                      }}
                    >
                      Continue
                    </button>
                  </div>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assests/Images/Login-Signup/bg-img.png"
                    }
                    className="bg-img"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <EnterOtp show={entermodalShow} onHide={() => setEnterModalShow(false)} />
    </section>
  );
};

export default ForgetPassword;
