import React from "react";
import CommonBanner from "../Common_Component/CommonBanner/CommonBanner";
import Breadcrumb_holder from "../Common_Component/Breadcrumb_holder/Breadcrumb_holder";

function TermsAndCondition() {
  const breadcrumbs = [
    { label: "Home", path: "/" },
    { label: "Terms  & Condition" },
  ];
  return (
    <>
      <div className="PrivacyPolicy">
        <CommonBanner
        bannerimg={'/assests/Images/All-Banner/terms-banner.png'}
         bannertext={"Terms & Conditions"} />
        <div className="container">
          <Breadcrumb_holder breadcrumbs={breadcrumbs} />
          <div className="PrivacyPolicy_holder">
            <div className="text-holder">
              <h3 className="PrivacyPolicy_heading_text">Introduction </h3>
              <p className="PrivacyPolicy_content_text">
                These terms and conditions (collectively, the "Terms"), along
                with all other terms and legal notices located on
                www.upgraddisha.com (the "Website"), govern your use of the
                Website. The Website is owned and operated by Talentedge
                Education Ventures Pvt. Ltd. a subsidiary of upGrad Education
                Private Limited with its registered business address at Ground
                Floor, Nishuvi 75 Dr. Annie Besant Road Worli, Mumbai – 400018
                (the “Company”) and a part of the upGrad group.
                <br />
                <br />
                These Terms of Use ("Terms") constitute a legally binding
                agreement ("Agreement") between you ("User" or "You") and the
                Company governing your access to and use of the upGrad Disha
                platform, including any subdomains thereof, and any other
                websites through which the platform makes its services available
                (collectively, the "Platform").
              </p>
            </div>

            <div className="text-holder">
              <h3 className="PrivacyPolicy_heading_text">Registration</h3>
              <p className="PrivacyPolicy_content_text">
                By registering on the Platform, you agree to make your contact
                details available to the partners/business associates of the
                Platform. You may be contacted by the Platform or its partners
                for additional information and the registration process through
                email, telephone, and SMS. You may also receive promotional
                emails/special offers from the Platform or any of its partner
                websites/applications.
              </p>
            </div>

            <div className="text-holder">
              <h3 className="PrivacyPolicy_heading_text">Purpose</h3>
              <p className="PrivacyPolicy_content_text">
                The Platform is made available to help students get details,
                discover, and research universities and online courses of their
                interest, as well as for allied services related thereto. The
                Platform also helps connect universities (“Universities”) with
                prospective students who may be interested in them ("Purpose").
              </p>
            </div>

            <div className="text-holder">
              <h3 className="PrivacyPolicy_heading_text">Disclaimers</h3>
              <ul>
                <li>
                  <p className="PrivacyPolicy_content_text">
                    Nature of the Platform: upGrad Disha is a counselling and
                    exploring platform. This platform is to facilitate learners
                    to explore, choose, and apply for online programs. Students
                    will directly apply to the university, and all admission and
                    all post-procedure activities will occur directly with the
                    university. This platform does not collect fees or provide
                    any education services.
                  </p>
                </li>
                <li>
                  <p className="PrivacyPolicy_content_text">
                    No Warranties: The Company expressly disclaims warranties of
                    any kind for any use of or access to the Platform, material,
                    information, links, or content presented on the web pages at
                    the Platform, external websites linked thereto, and external
                    material, information, links, or content linked thereto. The
                    Platform and any related content are provided on an "as is"
                    basis, without warranty of any kind, either express or
                    implied, including, without limitation, the implied
                    warranties of merchantability, fitness for a particular
                    purpose, or non-infringement.
                  </p>
                </li>
              </ul>
            </div>

            <div className="text-holder">
              <h3 className="PrivacyPolicy_heading_text">
                Content and Liability Disclaimer
              </h3>
              <ul>
                <li>
                  <p className="PrivacyPolicy_content_text">
                    Intermediary Status: The Platform acts as an intermediary
                    under sub-clause (w) of Section 2 of the Information
                    Technology Act, 2000. The Company shall not be responsible
                    for any errors or omissions contained on any Company website
                    and reserves the right to make changes without notice.
                  </p>
                </li>
                <li>
                  <p className="PrivacyPolicy_content_text">
                    Views Expressed by Users: Mention of University’s. products
                    or services are for informational purposes only and do not
                    constitute an endorsement or recommendation by the Company.
                    The views expressed by users are their own, and the Company
                    does not endorse them.
                  </p>
                </li>
                <li>
                  <p className="PrivacyPolicy_content_text">
                    Accuracy of Information: While efforts are made to ensure
                    that the content is not misleading, no claim is made as to
                    the accuracy and correctness of the information on the site.
                    If any inaccuracy or improper content is sighted on the
                    Platform, it can be reported to the Company.
                  </p>
                </li>
              </ul>
            </div>

            <div className="text-holder">
              <h3 className="PrivacyPolicy_heading_text">
                Information Sharing
              </h3>
              <p className="PrivacyPolicy_content_text">
                Personal information may be shared with trusted partners and
                educational institutions for relevant services. We do not rent,
                sell, or share personal information for unrelated purposes
                unless required by law.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndCondition;
