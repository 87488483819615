import React from 'react'
import { Accordion } from 'react-bootstrap'

const UsuallyAskedQuetion = () => {
    return (
        <section className='common-univeresity'>
            <div className='blue-bggg'>
                <div className='d-flex'>
                    <img src={'/assests/Images/icon/quetion.png'} className='quetion-icon-imgg' />
                    <div className=''>
                            <h3 className='sub-headdd'>Usually Asked Questions</h3>
                            {/* <div className='borderrr'></div> */}
                        <p>Best BTech Colleges in India 2024: Ranking, Fees, Courses, Admission, Placements, Cutoff</p>
                        <div>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: How to check JEE Main 2024 Session 2 Result?</Accordion.Header>
                                    <Accordion.Body>
                                     
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Q: Where can I get JEE Main answer key 2024 session 2?</Accordion.Header>
                                    <Accordion.Body>                                      
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Q: How many Engineering colleges are there in India?</Accordion.Header>
                                    <Accordion.Body>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Q: Where can I get JEE Main answer key 2024 session 2?</Accordion.Header>
                                    <Accordion.Body>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UsuallyAskedQuetion