import React from "react";
import "./Universities_card.css";

function Universities_card({ universityimage, universitylogo }) {
  return (
    <>
      <div className="Universities_card-holder">
        <div className="container">
          <div className="Universities_card">
            <div className="university-image-holder">
              <img
                src={universityimage}
                className="university-img"
                alt="..."
              />
            </div>

            <div className="university-logo-image-holder">
              <img
                src={universitylogo}
                className="university-logo-img"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Universities_card;
