import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ReactStars from 'react-stars'


const ratingChanged = (newRating) => {
    console.log(newRating)
}
const CommonCourse = () => {
    return (
        <section className='common-univeresity'>
            <div className='blue-bggg'>
                <p className='bold-txtt-blue'>B.E. in Artificial Intelligence and Data Science</p>
                <div className='d-flex'>
                    <p className='normal-txtt'>4 Yrs.</p>
                    <div className='blk-border'></div>
                    <div className='d-flex'>
                        <ReactStars
                            count={1}
                            onChange={ratingChanged}
                            size={20}
                            color2={'#ffd700'} />
                        <p className='normal-txtt'>3.9</p>
                    </div>

                    <p className='normal-txtt'>Degree by UNIPUNE - Savitribai Phule Pune University (SPPU)</p>
                </div>
                <div className='d-flex justify-content-between'>
                    <div>
                        <Row className='mt-3'>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <p className='blur-grey-txtt'>Seats Offered</p>
                                <span className='blur-blkk-txtt'>180</span>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <p className='blur-grey-txtt'>Median Salary</p>
                                <span className='blur-blkk-txtt'>180</span>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <p className='blur-grey-txtt'>Exam Accepted</p>
                                <span className='blur-blkk-txtt'>JEE M.., JEE Ad..</span>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <p className='blur-grey-txtt'>Total tuition Fess</p>
                                <span className='blur-blkk-txtt'>₹ 4 L(Get Fee Details)</span>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <button className='white-btnnn'>Download Brochure</button><br />
                        <button className='orange-btnnn mt-3'>Compare College</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CommonCourse