import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import './CommonSuccessModal.css';
import CelebrationAnimation from './CelebrationAnimation/CelebrationAnimation';

const CommonSuccessModal = ({ modalheading, modalsubheading, onHide, ...props }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onHide();
        }, 3000);

        return () => clearTimeout(timer);
    }, [onHide]);

    return (
        <section className='schedule-modal'>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-class'
            >
                <Modal.Body>
                    <div className='text-center'>
                        <CelebrationAnimation />
                        <h4 className='modal-titleee'>
                            {modalheading}
                        </h4>
                        <p className='modal-txttt'>{modalsubheading}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default CommonSuccessModal;
