import React from 'react'

function Heading_holder({headingtext}) {
  return (
  <>
    <div className='heading-holder'>
    <h1 className="heading">{headingtext}</h1>
    </div>
  </>
  )
}

export default Heading_holder