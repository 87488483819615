import React from 'react';
import './Specialization.css';
import { Col, Row } from 'react-bootstrap';

const specializations = [
    'General Management',
    'Human Resource Management',
    'Operations Management',
    'Finance Management',
    'Business Analytics Management',
    'Retail Management',
    'Supply Chain Management',
    'Banking & Finance Management',
    'Quality Management',
    'International Finance Management',
    'HR Analytics Management',
    'Integrated Marketing Management',
    'Retail Banking Management',
    'Marketing Management',
    'International Business Management',
    'IT Management',
    'Digital Marketing Management',
    'Data Science Management',
    'Project Management',
    'Entrepreneurship & Leadership Mgmnt',
    'Project Management',
    'Entrepreneurship & Leadership Mgmnt',
    'Hospital & Healthcare Management',
    'E-Commerce Management',
    'Business Intelligence & Analytics',
    'Digital Entrepreneurship',
    'Applied Finance Management'
];

const Specialization = () => {
    return (
        <section className='specialization'>
            <h5 className='heading'>Specializations in Online MBA Courses</h5>
            <p className='text-main mt-3'>There are several colleges offering online MBA programs in India. Students can take admission to online MBA courses to gain and upscale their managerial skills. The following table provides a quick look at the details of online MBA courses:</p>
            <div className='mt-4'>
                <Row>
                    {specializations.map((specialization, index) => (
                        <Col xl={3} lg={3} md={4} sm={6} xs={12} key={index}>
                            <div className='yellow-bggg'>
                                <p className='course-nameee'>{specialization}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </section>
    );
};

export default Specialization;
