import React, { useEffect } from 'react';
import './SettingSuccessful.css';
import { Col, Modal, Row } from 'react-bootstrap';

const SettingSuccessful = ({ titletext, subtitletext, onHide, ...props }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onHide();
        }, 3000);

        return () => clearTimeout(timer);
    }, [onHide]);

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='succeeful-modal-class'
        >
            <Modal.Body>
                <Row>
                    <Col md={4} sm={12} xs={12}>
                        <div className='text-center'>
                            <img src={process.env.PUBLIC_URL + '/assests/Images/Profile/girl.png'} className='women-image' alt='Profile' />
                        </div>
                    </Col>
                    <Col md={8} sm={12} xs={12}>
                        <div className='mt-2'>
                            <h6 className='deacti-title'>{titletext}</h6>
                            <p className='deacti-subtitle'>{subtitletext}</p>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default SettingSuccessful;
