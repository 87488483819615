import React from 'react'
import './CommonBanner.css'

const CommonBanner = ({ bannertext, bannerimg }) => {
  return (
    <section className='commonbanner'>
      <div className='main-class-banner'>
        <img src={process.env.PUBLIC_URL + bannerimg} className="banner-img" alt="..." />
        <div className='blk-overlay'></div>
        <div className='title-banner'>
          <h4 className='banner-titleee'>{bannertext}</h4>
        </div>
        <div className="banner-overlay-bottom-up">
          <img src={process.env.PUBLIC_URL + "/assests/Images/Cources/left.png"} className="overlay-img" alt="..." />
        </div>
        <div className="banner-overlay-top-up">
          <img src={process.env.PUBLIC_URL + "/assests/Images/Cources/right.png"} className="overlay-img" alt="..." />
        </div>
      </div>
    </section>
  )
}

export default CommonBanner