import React from "react";
import "./Top_Approved.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import { Grid, Navigation } from "swiper/modules";

const logos = [
  "/assests/Images/Hompage/TopApproved/dpu.png",
  "/assests/Images/Hompage/TopApproved/manipal.png",
  "/assests/Images/Hompage/TopApproved/michigan.png",
  "/assests/Images/Hompage/TopApproved/logonew.png",
  "/assests/Images/Hompage/TopApproved/liverpool.png",
  "/assests/Images/Hompage/TopApproved/imt.png",
  "/assests/Images/Hompage/TopApproved/harvard.png",
  "/assests/Images/Hompage/TopApproved/chandigarh.png",
  "/assests/Images/Hompage/TopApproved/amity.png",
  "/assests/Images/Hompage/TopApproved/dpu.png",
  "/assests/Images/Hompage/TopApproved/manipal.png",
  "/assests/Images/Hompage/TopApproved/michigan.png",
  "/assests/Images/Hompage/TopApproved/logonew.png",
  "/assests/Images/Hompage/TopApproved/liverpool.png",
  "/assests/Images/Hompage/TopApproved/imt.png",
  "/assests/Images/Hompage/TopApproved/harvard.png",
  "/assests/Images/Hompage/TopApproved/chandigarh.png",
  "/assests/Images/Hompage/TopApproved/amity.png"
];

function Top_Approved() {
  return (
    <div className="Top_Approved">
      <div className="container">
        <div className="Top_Approved-holder">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="heading-holder">
                <h3 className="heading-text normal-heading">Top Approved</h3>
                <h3 className="heading-text bold-heading">
                  Online Distance Education Universities
                </h3>
                <h3 className="heading-text color-heading">with Us</h3>
              </div>
              <div className="Approved-text-holder">
                <p>
                  With us, there are several approved online distance education universities that offer a wide range of courses.
                </p>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="university-logo-slider-holder">
                <Swiper
                  slidesPerView={3}
                  grid={{ rows: 3 }}
                  spaceBetween={10}
                  navigation={true}
                  modules={[Grid, Navigation]}
                  className="mySwiper logo-slider"
                >
                  {logos.map((logo, index) => (
                    <SwiperSlide key={index}>
                      <div className="img-holder">
                        <img
                          src={process.env.PUBLIC_URL + logo}
                          className="university-logo-img"
                          alt={`University logo ${index + 1}`}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Top_Approved;
