import React from 'react'
import './AdmissionTab'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import ApplyNowBanner from '../../../../Common_Component/ApplyNowBanner/ApplyNowBanner'
import EligibilityCrieteria from './EligibilityCrieteria'
import GetCounsilling from '../../../../CourseDetailPage/OverViewTab/GetCounsilling/GetCounsilling'

const AdmissionTab = () => {
    return (
        <section className='common-univeresity'>
            <Container>
                <Row>
                    <Col xl={9} lg={8} md={8} sm={12} xs={12}>
                        <h2 className='heading'>DY Patil Pune Admission Process 2024</h2>
                        <p>DY Patil Vidyapeeth Pune admission 2024 will begin soon. The university offers UG & PG courses across the fields of Medicine, Dentistry, Nursing, Physiotherapy, Optometry, Biotechnology, Management, Ayurved and Homeopathy. DY Patil Pune admissions to all the courses are entirely merit-based. As per the admission process, Candidates have to fulfil the basic eligibility criteria and fill the application form on the official website of the university to apply for their desired course. </p>
                        <p>The university conducts its own entrance exam for most of the courses, however for DY Patil MBA admission is also done based on scores of entrance exams such as CAT, MAT, XAT, CMAT, MAH CET, etc. For courses such as MBBS, BAMS and BTech, candidates need to qualify for NEET UG & JEE Main exams, respectively. Read more about the DY Patil Pune admissions 2024 in the below article.</p>
                        <div className='blue-bggg mt-5'>
                            <h3 className='heading'>DY Patil Pune Admission 2024: Highlights</h3>
                            <p>Candidates seeking admission to DY Patil can check out the updated information related to the DY Patil Pune course admissions 2024 in the following highlights section:</p>
                        </div>
                        <div className='fee-structure mt-5'>
                            <div className='white-back'>
                                <div className='yellow-bgggg'>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <p className='bold-txtt-blue'>Particulars</p>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <p className='bold-txtt-blue'>DY Patil Pune Admission Highlights 2024</p>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='white-bggg-inner'>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <p className='normal-txttt'>Programmes Offered</p>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <p className='normal-txttt'>Online</p>
                                        </Col>
                                    </Row>
                                    <div className='dash-border mt-2'></div>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <p className='normal-txttt'>How to apply?</p>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <p className='normal-txttt'>25+</p>
                                        </Col>
                                    </Row>
                                    <div className='dash-border mt-2'></div>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <p className='normal-txttt'>How to apply?</p>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>

                                            <p className='normal-txttt'>Online at <span className='blue-txtt-small'>(https://admissions.dpu.edu.in/)</span> </p>

                                        </Col>
                                    </Row>
                                    <div className='dash-border mt-2'></div>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <p className='normal-txttt'>Popular programmes</p>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <p className='normal-txttt'>UG, PG & Certificate-</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <EligibilityCrieteria />
                        <GetCounsilling/>
                    </Col>

                    <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                        <ApplyNowBanner />
                        <div className='mt-2'>
                            <ApplyNowBanner />
                        </div>
                        <div className='mt-2'>
                            <ApplyNowBanner />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AdmissionTab