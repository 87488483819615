import React, { useState } from "react";
import "./RequestCallBack.css";
import { Form } from "react-bootstrap";
import Common_btn from "../../../Common_Component/Common_btn/Common_btn";

const RequestCallBack = () => {
  return (
    <>
      <section className="RequestCallBack">
        <div className="RequestCallBack-form-holder">
          <div className="form-heading-holder">
            <h3>Request a Call Back</h3>
          </div>
          <Form className="form-holder">
            <div className="row">
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Control type="name" placeholder="Enter Full Name*" />
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="mail" placeholder="Enter Email" />
                </Form.Group>
              </div>
              <div className="col-lg-6 col-md-12 ">
                <Form.Group className="mb-3" controlId="formBasicNumber">
                  <Form.Control type="number" placeholder="Phone Number" />
                </Form.Group>
              </div>

              <div className="col-lg-6 col-md-12 ">
                <Form.Group className="mb-3" controlId="formBasicNumber">
                  <Form.Control type="number" placeholder="Phone Number" />
                </Form.Group>
              </div>

              <div className="col-lg-6 col-md-12 ">
                <Form.Group className="mb-3" controlId="formBasicNumber">
                  <Form.Control type="text" placeholder="Phone Number" />
                </Form.Group>
              </div>

              <div className="col-lg-6 col-md-12 ">
                <Form.Group className="mb-3" controlId="formBasicNumber">
                  <Form.Control type="text" placeholder="Phone Number" />
                </Form.Group>
              </div>
            </div>

            <Common_btn className={"Submit-btn"} text={"Submit"} />
          </Form>
        </div>

      </section>
    </>
  );
};

export default RequestCallBack;
