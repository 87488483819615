import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ApplyNowBanner from '../../../../Common_Component/ApplyNowBanner/ApplyNowBanner'
import GetCounsilling from '../../../../CourseDetailPage/OverViewTab/GetCounsilling/GetCounsilling'

const RankingTabs = () => {
    return (
        <section className='common-univeresity'>
            <Container>
                <Row>
                    <Col xl={9} lg={8} md={8} sm={12} xs={12}>
                        <h5 className='heading'>DY Patil, Ranking Highlight</h5>
                        <p>DY Patil Pune holds rankings across diverse categories from numerous prominent national ranking entities, namely NIRF, etc. These ranking bodies have granted DY Patil Pune good ranks. Students can view the table to learn more about the DY Patil Pune rankings:</p>
                        <div className='white-bggg'>
                            <h5 className='heading'>DY Patil, publishername Ranking 1991 </h5>
                            <Row>
                                <Col xl={2} lg={2} md={3} sm={4} xs={4} >
                                <img src={process.env.PUBLIC_URL+''} />
                                </Col>
                                <Col xl={10} lg={10} md={9} sm={8} xs={4} >
                                    <p>Considering the aggregate score, DY Patil Pune has been placed in a good position by the NIRF 2023 under the 'Dental' category. By the provided parameters such as Faculty Quality Weightage and Employability Weightage, the college is ranked well when compared with other universities It has been observed that in the 'Dental' category the institute's ranks have shown a fluctuating trend when evaluated against the ranking of the prior year. In 2023, DY Patil Pune's rank is #3, the same as last year's rank in the 'Dental' category by the NIRF ranking. In the 'Dental' category, DY Patil Pune ranked in the top 3 in the country for the 3rd consecutive year in 2023.</p>
                                    <div className='text-end'>
                                        <button>Read More</button>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                        <GetCounsilling />
                    </Col>

                    <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                        <ApplyNowBanner />
                        <div className='mt-2'>
                            <ApplyNowBanner />
                        </div>
                        <div className='mt-2'>
                            <ApplyNowBanner />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default RankingTabs