import React from 'react'
import './ApplyNowBanner.css'

const ApplyNowBanner = () => {
    return (
        <section className='apply-now-banner'>
            <div>
                <img src={process.env.PUBLIC_URL + '/assests/Images/All-Banner/applynowbanner.png'} className='banner-imggg' />
            </div>
        </section>
    )
}

export default ApplyNowBanner