import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../CollegeInfo.css'

const TabulatedHighlight = () => {
    return (
        <section className='common-univeresity'>
            <Container>
                <div>
                    <h4 className='uni-sec-title'>Tabulated below are the Dr. D. Y. Patil Univerisity highlights 2024:</h4>
                </div>
                <div className='white-bggg mt-5'>
                    <Row>
                        <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p className='sub-headdd'>Particulars</p>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p className='sub-headdd'>Statistics</p>
                                </Col>
                            </Row>
                            <div className='dash-border'></div>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p>Establishment year | Ownership</p>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p>1984 | Private Autonomous College</p>
                                </Col>
                            </Row>
                            <div className='dash-border'></div>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p>Student intake</p>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p>UG : 840</p>
                                </Col>
                            </Row>
                            <div className='dash-border'></div>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p>Affiliated to</p>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p>Savitribai Phule Pune University</p>
                                </Col>
                            </Row>
                            <div className='dash-border'></div>
                            <Row>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p>Rankings</p>
                                </Col>
                                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <p>Ranked 45 by India Today 2021 for Private Engineering</p>
                                    <p>Ranked 70 by India Today 2020 for Engineering</p>
                                </Col>
                            </Row>
                            <div className='dash-border'></div>
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <div className='yellow-bgggg'>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className='bold-txttt'>Number of courses</p>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p>22</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className='bold-txttt'>Student-faculty ratio</p>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p>5.6:1</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className='bold-txttt'>Top recruiters</p>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p>TCS, Infosys Technologies, Capgemini</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className='bold-txttt'>Highest placement package (2021)</p>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p>Rs 12.5 LPA</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p className='bold-txttt'>Total Collaborations</p>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <p>40</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default TabulatedHighlight