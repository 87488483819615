import React, { useState } from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import ApplyNowBanner from '../../../Common_Component/ApplyNowBanner/ApplyNowBanner'
import GetCounsilling from '../../OverViewTab/GetCounsilling/GetCounsilling'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
const faqItems = [
    {
        question: "1. Is there an entrance test for admission?",
        answer: (
            <ul>
                <li>
                    <p>
                        No. There is no entrance test for the admission. However, there is a
                        selection process to enroll in ICFAI’s online MBA Program.
                    </p>
                </li>
                <li>
                    <p>
                        Candidates will undergo a selection process, comprising Micro
                        Presentation and a Personal Interview, which will be conducted
                        online within a specified time window. For further details, please
                        visit on{" "}
                        <Link to="https://link.com/in" target="_blank">
                            www.link.com
                        </Link>
                    </p>
                </li>
            </ul>
        ),
    },
    {
        question: "2. Is there an entrance test for admission?",
        answer: (
            <ul>
                <li>
                    <p>
                        No. There is no entrance test for the admission. However, there is a
                        selection process to enroll in ICFAI’s online MBA Program.
                    </p>
                </li>
                <li>
                    <p>
                        Candidates will undergo a selection process, comprising Micro
                        Presentation and a Personal Interview, which will be conducted
                        online within a specified time window. For further details, please
                        visit on{" "}
                        <Link to="https://link.com/in" target="_blank">
                            www.link.com
                        </Link>
                    </p>
                </li>
            </ul>
        ),
    },
    {
        question: "3. Is there an entrance test for admission?",
        answer: (
            <ul>
                <li>
                    <p>
                        No. There is no entrance test for the admission. However, there is a
                        selection process to enroll in ICFAI’s online MBA Program.
                    </p>
                </li>
                <li>
                    <p>
                        Candidates will undergo a selection process, comprising Micro
                        Presentation and a Personal Interview, which will be conducted
                        online within a specified time window. For further details, please
                        visit on{" "}
                        <Link to="https://link.com/in" target="_blank">
                            www.link.com
                        </Link>
                    </p>
                </li>
            </ul>
        ),
    },
    {
        question: "4. Is there an entrance test for admission?",
        answer: (
            <ul>
                <li>
                    <p>
                        No. There is no entrance test for the admission. However, there is a
                        selection process to enroll in ICFAI’s online MBA Program.
                    </p>
                </li>
                <li>
                    <p>
                        Candidates will undergo a selection process, comprising Micro
                        Presentation and a Personal Interview, which will be conducted
                        online within a specified time window. For further details, please
                        visit on{" "}
                        <Link to="https://link.com/in" target="_blank">
                            www.link.com
                        </Link>
                    </p>
                </li>
            </ul>
        ),
    },
    {
        question: "5. Is there an entrance test for admission?",
        answer: (
            <ul>
                <li>
                    <p>
                        No. There is no entrance test for the admission. However, there is a
                        selection process to enroll in ICFAI’s online MBA Program.
                    </p>
                </li>
                <li>
                    <p>
                        Candidates will undergo a selection process, comprising Micro
                        Presentation and a Personal Interview, which will be conducted
                        online within a specified time window. For further details, please
                        visit on{" "}
                        <Link to="https://link.com/in" target="_blank">
                            www.link.com
                        </Link>
                    </p>
                </li>
            </ul>
        ),
    },
];

const FaqTab = () => {

    const [openKey, setOpenKey] = useState(null);

    const toggleIcon = (key) => (openKey === key ? faCircleMinus : faCirclePlus);

    const handleSelect = (key) => {
        setOpenKey(openKey === key ? null : key);
    };

    return (
        <section >
            <Container>
                <Row>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12} >
                        <ApplyNowBanner />
                        <div>
                            <ApplyNowBanner />
                        </div>
                    </Col>
                    <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                        <div className='Faq'>
                            <div className='faq-content-holder'>
                                <div className="faq-holder">
                                    <Accordion activeKey={openKey} onSelect={handleSelect}>
                                        {faqItems.map((item, index) => (
                                            <Accordion.Item eventKey={index.toString()} key={index}>
                                                <Accordion.Header>
                                                    {item.question}
                                                    <FontAwesomeIcon
                                                        icon={toggleIcon(index.toString())}
                                                        className="fa-icon"
                                                    />
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="text-holder">{item.answer}</div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                      
                        <GetCounsilling />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default FaqTab