import React from 'react'
import './HomeBanner.css'
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

const HomeBanner = () => {
  return (
      <section className="home-banner">
          <video
              src={process.env.PUBLIC_URL + "/assests/Video/home-banner.mp4"} alt="..."
              muted
              autoPlay
              loop
              className="d-block w-100 video-class-grid"
          />
          <div className="search-fieldd">
              <InputGroup className="mb-3">
                  <Form.Control
                      placeholder="Search by Course / University"
                      aria-label="Search by Course / University"
                      aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text id="basic-addon2"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
              </InputGroup>
              <Nav variant="pills" defaultActiveKey="first" className="d-flex justify-content-center">
                  <Nav.Item>
                      <Nav.Link as={Link} to='/course-detail' eventKey="first">MBA</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                      <Nav.Link eventKey="second">BBA</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                      <Nav.Link eventKey="third">B.Tech</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                      <Nav.Link eventKey="fourth">M.Tech</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                      <Nav.Link eventKey="fifth">Medical</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                      <Nav.Link eventKey="sixth" >
                          Design
                      </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                      <Nav.Link eventKey="seventh" >
                          More
                      </Nav.Link>
                  </Nav.Item>
              </Nav>

          </div>
          <div className="banner-overlay-bottom-up">
              <img src={process.env.PUBLIC_URL + "/assests/Images/Hompage/banner/banner1.png"} className="overlay-img" alt="..." />
          </div>
          <div className="banner-overlay-top-up">
              <img src={process.env.PUBLIC_URL + "/assests/Images/Hompage/banner/banner2.png"} className="overlay-img" alt="..." />
          </div>
      </section>
  )
}

export default HomeBanner