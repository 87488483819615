import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import AllFiltersTab from "../AllFiltersTab/AllFiltersTab";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './PopularColleges.css';

const collegeData = [
    {
        name: "Dr. D. Y. Patil University",
        location: "Pune",
        ranking: "#1 NIRF",
        coursesOffered: "1 Course",
        totalFees: "₹20 L",
        examsAccepted: "- /-",
        medianSalary: "- /-",
        logoSrc: "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png"
    },
    {
        name: "Dr. D. Y. Patil University",
        location: "Pune",
        ranking: "#1 NIRF",
        coursesOffered: "1 Course",
        totalFees: "₹20 L",
        examsAccepted: "- /-",
        medianSalary: "- /-",
        logoSrc: "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png"
    },
    {
        name: "Dr. D. Y. Patil University",
        location: "Pune",
        ranking: "#1 NIRF",
        coursesOffered: "1 Course",
        totalFees: "₹20 L",
        examsAccepted: "- /-",
        medianSalary: "- /-",
        logoSrc: "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png"
    },
    {
        name: "Dr. D. Y. Patil University",
        location: "Pune",
        ranking: "#1 NIRF",
        coursesOffered: "1 Course",
        totalFees: "₹20 L",
        examsAccepted: "- /-",
        medianSalary: "- /-",
        logoSrc: "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png"
    },
    {
        name: "Dr. D. Y. Patil University",
        location: "Pune",
        ranking: "#1 NIRF",
        coursesOffered: "1 Course",
        totalFees: "₹20 L",
        examsAccepted: "- /-",
        medianSalary: "- /-",
        logoSrc: "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png"
    },
    {
        name: "Dr. D. Y. Patil University",
        location: "Pune",
        ranking: "#1 NIRF",
        coursesOffered: "1 Course",
        totalFees: "₹20 L",
        examsAccepted: "- /-",
        medianSalary: "- /-",
        logoSrc: "/assests/Images/Hompage/trending-cources/DPU-COL Logo png.png"
    },

];

const PopularColleges = () => {
    return (
        <section className="popular-college">
            <Container>
                <Row>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                        <AllFiltersTab />
                    </Col>
                    <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                        <div className="main-class-grey d-flex justify-content-between mb-3">
                            <div className="d-flex">
                                <p className="results-txttt me-3">5,999 Results</p>
                                <p className="best-course-txtt">Best MBA Colleges in India 2024: Ranking, Fees...</p>
                            </div>
                            <div>
                                <label htmlFor="options">Sort by: </label>
                                <select id="options" name="options">
                                    <option value="popularity">
                                        <Form>
                                            <Form.Check type="radio" label='Popularity' />
                                        </Form>
                                    </option>
                                    <option value="fees">Fees</option>
                                    <option value="faculty">Faculty</option>
                                </select>
                            </div>
                        </div>
                        {collegeData.map((college, index) => (
                            <div className="brow-bggg mt-4" key={index} >
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <img src={process.env.PUBLIC_URL + college.logoSrc} className="uni-img me-2" alt="college logo" />
                                        <div>
                                            <p className="university-name">{college.name}</p>
                                            <div className="d-flex mt-1">
                                                <p className="below-txttt"><FontAwesomeIcon icon={faLocationDot} /> {college.location}</p>
                                                <div className="blk-border"></div>
                                                <div className="below-txttt">{college.ranking}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <Row>
                                        <Col xl={2} lg={2} md={3} sm={3} xs={6}>
                                            <span className="below-txttt ms-0">Courses Offered</span>
                                            <span className="sub-txtt-main">{college.coursesOffered}</span>
                                        </Col>
                                        <Col xl={2} lg={2} md={3} sm={3} xs={6}>
                                            <span className="below-txttt ms-0">Total Tuition Fees</span>
                                            <span className="sub-txtt-main">{college.totalFees}</span>
                                        </Col>
                                        <Col xl={2} lg={2} md={3} sm={3} xs={6}>
                                            <span className="below-txttt ms-0">Exams Accepted</span>
                                            <div>
                                                <span className="sub-txtt-main">{college.examsAccepted}</span>
                                            </div>
                                        </Col>
                                        <Col xl={2} lg={2} md={3} sm={3} xs={6}>
                                            <span className="below-txttt ms-0">Median Salary</span>
                                            <div>
                                                <span className="sub-txtt-main">{college.medianSalary}</span>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                            <span className="below-txttt ms-0"></span>
                                            <button className="download-btn me-1">Download Brochure</button>
                                            <button className="compare-btn">Compare College</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ))}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PopularColleges;
