import React, {useState} from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './LoginSignupCommon.css'
import Login from './Login';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const Signup = (props) => {
    const [phone, setPhone] = useState("");
    const [loginmodalShow, setLoginModalShow] = React.useState(false);
    return (
        <section>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='common-modalll-class'
            >
                <Modal.Body>
                    <Row>
                        <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                            <div className='first-section'>
                                <h3 className='signup-txttt'><span className='orange-border'>Sign</span> Up</h3>
                                <p className='below-txttt '>Few simple clicks, to make your mark!</p>
                            </div>
                        </Col>
                        <Col xl={5} lg={7} md={12} sm={12} xs={12}>
                            <div className='orange-bgg'>
                                <div className='white-bggg'>
                                    <h3 className='signup-txtttttt mb-3'>Sign Up</h3>
                                    <div className='form-main'>
                                        <Form className='form-holder'>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="text" placeholder="Enter Full Name*" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicPhone">
                                                <PhoneInput
                                                    country={"eg"}
                                                    enableSearch={true}
                                                    value={phone}
                                                    onChange={(phone) => setPhone(phone)}
                                                    containerClass="phone-input-container"
                                                    inputClass="phone-input-field"
                                                    buttonClass="phone-input-button"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="email" placeholder="Enter Email" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="password" placeholder="Enter Password" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="confirm password" placeholder="Confirm Password" />
                                            </Form.Group>
                                        </Form>

                                        <button className='get-btnnn mb-4'>Get OTP</button>
                                        <p className='form-txttt'>Already have an account? <span className='login-txtt' onClick={() => setLoginModalShow(true)}>Log In</span></p>

                                        <Login
                                            show={loginmodalShow}
                                            onHide={() => setLoginModalShow(false)}
                                        />
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Check
                                                type='checkbox'
                                                label='By Signing up, you agree terms & conditons and PrivacyPolicy'
                                            />
                                        </Form.Group>
                                    </div>
                                    <img src={process.env.PUBLIC_URL + '/assests/Images/Login-Signup/bg-img.png'} className='bg-img' />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default Signup
