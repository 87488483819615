import React from 'react'
import './CourseDetailsTab.css'
import { Container, Nav, Row, Tab } from 'react-bootstrap'
import PopularCOlleges from './PopularColleges/PopularColleges'
import Breadcrumb_holder from '../../Common_Component/Breadcrumb_holder/Breadcrumb_holder'
import OverViewTab from '../OverViewTab/OverViewTab'
import FaqTab from './FaqTab/FaqTab'

const CourseDetailsTab = () => {
  const breadcrumbs = [
    { label: "Home", path: "/" },
    { label: "MBA" }
  ];
  return (
    <section className='course-deatils-tab'>
      <Container>
        <div className='mt-4 mb-4'>
          <Breadcrumb_holder breadcrumbs={breadcrumbs} />
        </div>
        <div className='text-center'>
          <h4 className='details-tab-title'>Online MBA: Course Fees, Admission 2024, Syllabus, Top Colleges, Career Options</h4>
        </div>
        <div >
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Nav variant="pills" className="flex-row justify-content-center mt-4" >
                <Nav.Item>
                  <Nav.Link eventKey="first">POPULAR COLLEGES</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">TOP RANKED COLLEGES</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">FEATURED COLLEGES</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="four">OVERVIEW</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="five">FAQs</Nav.Link>
                </Nav.Item>
              </Nav>
              <div className='blk-borderrr'></div>
              <Tab.Content>
                <Tab.Pane eventKey="first"><PopularCOlleges /></Tab.Pane>
                <Tab.Pane eventKey="second"><PopularCOlleges /></Tab.Pane>
                <Tab.Pane eventKey="third">First tab content</Tab.Pane>
                <Tab.Pane eventKey="four"><OverViewTab/></Tab.Pane>
                <Tab.Pane eventKey="five"><FaqTab/></Tab.Pane>
              </Tab.Content>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </section>
  )
}

export default CourseDetailsTab