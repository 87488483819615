import React from 'react'
import './OverViewFirstSection.css'

const OverViewFirstSection = () => {
    return (
        <>
            <section className='overview-first-section'>
                <div className='txttt'>
                    <p>Online MBA is a two-year online degree program in Business Administration that is beneficial for those who cannot afford relocation and money to take offline MBA classes. Many working professionals prefer online MBA courses since they have the liberty to complete the MBA degree from their place and time of convenience.</p>
                    <p>This two-year master's degree in business administration can be completed online. Some colleges may ask students to visit the campus within the term for workshops and cultural understanding of the institute. However, there is no need to regularly visit a physical campus when it comes to online MBA degree courses.</p>
                </div>
                <div className='mt-5 mb-5'>
                    <img src={process.env.PUBLIC_URL+ '/assests/Images/Cources/girls.png'} className='girls-img' />
                </div>
                <div className='txttt'>
                    <p>The biggest advantage of an Online MBA is its flexibility and convenience of completing it from any other location at your own pace. An online MBA degree allows students to connect to live sessions and rewatch pre-recorded sessions for their convenience.</p>   
                    <p>The popularity of MBA online courses in India has skyrocketed in recent years. Numerous B-schools, including the Indian Institute of Management (IIMs), have started offering the online MBA degree in response to its increasing popularity. </p>  
                    <p>There are several degree courses in Online MBA programs including MBA in digital marketing, human resources, marketing, healthcare and finance among other fields. Online MBA graduates have the opportunity to opt for managerial jobs in several domains based on their MBA specialization. Online MBA admissions are provided based on certain eligibility criteria (discussed below)</p>
                    <p>For some colleges, admission is done on the basis of online entrance examinations such as CAT, XAT, SNAP, and GMAT. Some colleges conduct their own examination while some only take eligibility criteria and graduation scores into consideration. The average fee for an Online MBA in India ranges from INR 50,0000 to INR 20 lakh.</p>
                </div>
            </section>
        </>
    )
}

export default OverViewFirstSection