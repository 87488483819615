import React from 'react'
import './LoginToExplore.css'
import { Col, Container, Row } from 'react-bootstrap'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LoginToExplore = () => {
    return (
        <section className='login-to-explore'>
            <Container>
                <div className='text-center'>
                    <button className='white-btnnn'>Login to Explore</button>
                </div>
                <Row className='mt-5'>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <div className='white-bgg'>
                            <div className='brownn-bggg'>
                                <FontAwesomeIcon icon={faLock} className='clock-icon' />
                            </div>
                            <p className='text-main'>Eligibility</p>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <div className='white-bgg'>
                            <div className='brownn-bggg'>
                                <FontAwesomeIcon icon={faLock} className='clock-icon' />
                            </div>
                            <p className='text-main'>Credits</p>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <div className='white-bgg'>
                            <div className='brownn-bggg'>
                                <FontAwesomeIcon icon={faLock} className='clock-icon' />
                            </div>
                            <p className='text-main'>Semester</p>
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        
                            <div className='white-bgg'>
                                <div className='brownn-bggg'>
                                    <FontAwesomeIcon icon={faLock} className='clock-icon' />
                                </div>
                                <p className='text-main'>Subjects</p>
                            </div>
                       

                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default LoginToExplore