
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';

const DeleteEducationDataModal = (props) => {
  

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='succeeful-modal-class change-pass-modal'
        >
            <Modal.Body>
                <div className='text-center mt-2'>
                    <h6 className='deacti-title'>Delete</h6>
                    <p className='deacti-subtitle'>Are you sure you want to delete?</p>
                </div>
                <div className='text-center'>
                    <button className='orange-btnnn me-2'>Save</button>
                    <button className='white-btnnn'>Cancel</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default DeleteEducationDataModal;
