import React from "react";
import { Nav, Tab } from "react-bootstrap";
import "./PgCources.css";
import UniverSitySlider from "../UniverSitySlider/UniverSitySlider";

const PgCources = () => {
  return (
    <section className="pg-cources">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Nav variant="pills" className="cources-tablist mt-4" >
          <Nav.Item>
            <Nav.Link eventKey="first">MBA</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second">MCA</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="third">M.Com.</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="four">M.A</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="five">M.Sc.</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            <UniverSitySlider />
          </Tab.Pane>
          <Tab.Pane eventKey="second">  <UniverSitySlider /></Tab.Pane>
          <Tab.Pane eventKey="third"> <UniverSitySlider /></Tab.Pane>
          <Tab.Pane eventKey="four"> <UniverSitySlider /></Tab.Pane>
          <Tab.Pane eventKey="five"> <UniverSitySlider /></Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </section>
  );
};

export default PgCources;
