import React, {useState, useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./UniverSitySlider.css";
import InnerCources from "../PgCources/InnerCources";
import { Nav, Row, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const universities = [
  {
    id: 1,
    eventKey: "first",
    imgSrc: "/assests/Images/University/dpu.png",
  },
  {
    id: 2,
    eventKey: "second",
    imgSrc: "/assests/Images/University/amityyyy.png",
  },
  {
    id: 3,
    eventKey: "third",
    imgSrc: "/assests/Images/University/cu-logo.png",
  },
  {
    id: 4,
    eventKey: "four",
    imgSrc: "/assests/Images/University/dpu.png",
  },
  {
    id: 5,
    eventKey: "five",
    imgSrc: "/assests/Images/University/amityyyy.png",
  },
  {
    id: 6,
    eventKey: "six",
    imgSrc: "/assests/Images/University/dpu.png",
  },
  {
    id: 7,
    eventKey: "seven",
    imgSrc: "/assests/Images/University/dpu.png",
  },
  {
    id: 8,
    eventKey: "eight",
    imgSrc: "/assests/Images/University/amityyyy.png",
  },
];



const UniverSitySlider = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);

  useEffect(() => {
    if (swiperInstance) {
      const handleSlideChange = () => {
        const videos = document.querySelectorAll('.video-review');
        videos.forEach(video => video.pause());
      };

      swiperInstance.on('slideChange', handleSlideChange);
      swiperInstance.on('transitionEnd', handleSlideChange);

      return () => {
        if (swiperInstance) {
          swiperInstance.off('slideChange', handleSlideChange);
          swiperInstance.off('transitionEnd', handleSlideChange);
        }
      };
    }
  }, [swiperInstance]);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  return (
    <section className="univer-slider mt-5">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Nav variant="pills" className="ps-3">
            <Swiper
              slidesPerView={3}
              spaceBetween={15}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              breakpoints={{
                0: { slidesPerView: 3 },
                320: { slidesPerView: 3 },
                485: { slidesPerView: 4 },
                768: { slidesPerView: 5 },
                991: { slidesPerView: 4 },
                1440: { slidesPerView: 7 },
              }}
              className="myswiper"

            >
              {universities.map((university) => (
                <SwiperSlide key={university.id}>
                  <Nav.Item>
                    <Nav.Link eventKey={university.eventKey}>
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + university.imgSrc}
                          className="uni-img"
                          alt="university"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="silder-btn">
              <div
                className="back-btn"
                onClick={() => handleMainSliderClick("prev")}
              >
                <div className='blue-border'>
                  <FontAwesomeIcon icon={faAngleRight} className='icon-btnnn' />

                </div>
              </div>
              <div
                className="next-btn"
                onClick={() => handleMainSliderClick("next")}
              >
                <FontAwesomeIcon icon={faAngleLeft} className='icon-btnnn' />
              </div>
            </div>
          </Nav>
          <Tab.Content className="mt-lg-5 mt-0">
            {universities.map((university) => (
              <Tab.Pane key={university.id} eventKey={university.eventKey}>
                <InnerCources />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Row>
      </Tab.Container>
    </section>
  );
};

export default UniverSitySlider;
