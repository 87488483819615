import React from 'react'
import ProfileBanner from './ProfileBanner/ProfileBanner'
import ProfileData from './ProfileData/ProfileData'
import FilledProfileData from './FilledProfileData/FilledProfileData'


const Profile = () => {
    return (
        <>
            <ProfileBanner />
            {/* <ProfileData /> */}
            <FilledProfileData/>
        </>
    )
}

export default Profile