import React from 'react'
import UniversityDetailsBanner from '../UniversityDetailsPage/UniversityDetailsBanner/UniversityDetailsBanner'
import UniversitySecond from './UniversitySecond/UniversitySecond'

const UniversityPage = () => {
    return (
        <>
            <UniversityDetailsBanner />
            <UniversitySecond />
        </>
    )
}

export default UniversityPage