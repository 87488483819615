import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './LoginSignupCommon.css';
import CongratsCelebration from './Animation/CongratsCelebration';

const Congrats = (props) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            if (props.onHide) {
                props.onHide(); 
            }
        }, 3000); 
        return () => clearTimeout(timer);
    }, [props]);

    return (
        <section>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='common-modalll-class'
            >
                <Modal.Body>
                    <Row>
                        <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                            <div className='first-section'>
                                <h3 className='signup-txttt'><span className='orange-border'>Cong</span>rats</h3>
                                <p className='below-txttt '>You took first step towards your success!</p>
                            </div>
                        </Col>
                        <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                            <CongratsCelebration />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default Congrats;
