import React from 'react'
import FacilitySection from './FacilitySection/FacilitySection'
import TrendingCourseBanner from './TrendingCourseBanner/TrendingCourseBanner'
import LatestNews from '../HomePage/LatestNews/LatestNews'
import OnlineDegree from './OnlineDegree/OnlineDegree'
import ProgrammEligibility from './ProgrammEligibility/ProgrammEligibility'
import CourseSyallabus from './CourseSyallabus/CourseSyallabus'

const TrendingCoursesDetails = () => {
    return (
        <>
            <TrendingCourseBanner />
            <FacilitySection />
            <OnlineDegree />
            <ProgrammEligibility />
            <CourseSyallabus />
            <LatestNews />
        </>
    )
}

export default TrendingCoursesDetails