import React, {useState, useEffect} from "react";
import { useLocation, Link } from "react-router-dom";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLinkedin,
    faFacebook,
    faSquareInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { Container, Navbar } from "react-bootstrap";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Nav from "react-bootstrap/Nav";
import Universities_Modal from "../Common_Component/Common_modal/Universities_Modal/Universities_Modal";
import Login from "../LoginSignup/Login";
import Signup from "../LoginSignup/Signup";

function Header() {
    const [modalShow, setModalShow] = React.useState(false);
    const [loginmodalShow, setLoginModalShow] = React.useState(false);
    const [signupmodalShow, setSignupModalShow] = React.useState(false);

    const location = useLocation();
    const [activeLink, setActiveLink] = useState("home");

    useEffect(() => {
        const path = location.pathname.substring(1);
        setActiveLink(path || "home");
    }, [location.pathname]);

    const handleNavItemClick = (selectedLink) => {
        setActiveLink(selectedLink);
        const navbarToggle = document.querySelector(".navbar-toggler");
        if (navbarToggle && window.innerWidth <= 992) {
            navbarToggle.click();
        }
    };



    return (
        <>
            <section className="main-header">
                <div className="black-header-top">
                    <div className="d-flex">
                        <p className="header-txttt me-3">
                            Phone : 1800-267-8961 / +91 96077 55557
                        </p>
                        <div className="border-white"></div>
                        <p className="header-txttt ms-3">
                            Working: Mon - Sat : 9:30 - 18:30
                        </p>
                    </div>
                    <div className="d-flex">
                        <p className="header-txttt me-4 " >
                            <FontAwesomeIcon className="me-2" icon={faEnvelope} /> inquiry@profcyma.com
                        </p>
                        <div>
                            <a
                                href="https://www.linkedin.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon
                                    className="header-txttt-icon"
                                    icon={faLinkedin}
                                />
                            </a>
                            <a
                                href="https://www.facebook.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon
                                    className="header-txttt-icon"
                                    icon={faFacebook}
                                />
                            </a>
                            <a
                                href="https://www.instagram.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon
                                    className="header-txttt-icon"
                                    icon={faSquareInstagram}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <Container>
                    <div className="second-header">
                        <Navbar collapseOnSelect expand="lg">
                            <Link to={"/"}>
                                <Navbar.Brand>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL + "/assests/Images/Logo/logo.png"
                                        }
                                        className="logo-img"
                                        alt="..."
                                    />
                                </Navbar.Brand>
                            </Link>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav>
                                    <Nav.Link
                                        as={Link}
                                        to="/cources" 
                                        className={location.pathname === "/cources" ? "active" : ""}
                                        onClick={() => handleNavItemClick("cources")} // Pass the correct selectedLink
                                    >
                                        Courses
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setModalShow(true)}>
                                        Universities
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/contact-us"
                                        className={location.pathname === "/contact-us" ? "active" : ""}
                                        onClick={() => handleNavItemClick("contact-us")} 
                                    >
                                        Contact Us
                                    </Nav.Link>
                                </Nav>
                                <div className="mt-3 mt-md-0">
                                    <button className="login-btnnn me-4" onClick={() => setLoginModalShow(true)}>Log In</button>
                                    <button className="signin-btnnn me-4" onClick={() => setSignupModalShow(true)}>Sign Up</button>
                                    <Link to='/profile'> <button className="profile-btn">NK</button></Link>
                                </div>
                                <Login
                                    show={loginmodalShow}
                                    onHide={() => setLoginModalShow(false)}
                                />
                                <Signup
                                    show={signupmodalShow}
                                    onHide={() => setSignupModalShow(false)}
                                />
                                <div className="mobile-view-divv mt-3">
                                    <div>
                                        <p className="header-txttt">
                                            Phone : 1800-267-8961 / +91 96077 55557
                                        </p>
                                        <p className="header-txttt">
                                            Working: Mon - Sat : 9:30 - 18:30
                                        </p>
                                    </div>
                                </div>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </Container>
            </section>
            <Universities_Modal show={modalShow} onHide={() => setModalShow(false)} />
        </>
    );
}

export default Header;
