import React from "react";
import "./CommonCourceCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";


const CommonCourceCard = ({
  imgSrc,
  courserating,
  universityLogo,
  universityName,
  courseName,
}) => {
  return (
    <section className="trending-cources">
      <div className="trending-cource-card mb-5">
      
          <div className="main-img-class">
            <img
              src={process.env.PUBLIC_URL + imgSrc}
              className="trending-img"
              alt="..."
            />
            <div className="offer-text">
              <FontAwesomeIcon icon={faStar} /> {courserating}
            </div>
            <div className="white-bgggg">
              <img
                src={process.env.PUBLIC_URL + universityLogo}
                className="uni-img"
                alt="..."
              />
            </div>
          </div>
       
        <div className="text-center">
          <p className="university-txtt">{universityName}</p>
          <p className="course-name">{courseName}</p>
        </div>
        <div className=" mt-4">
          <button className="download-brochure">Download Brochure</button>
          <button className="compare-college">Compare College</button>
        </div>
      </div>
    </section>
  );
};

export default CommonCourceCard;
