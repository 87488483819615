import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ReactStars from 'react-stars'
import ProgressBar from 'react-bootstrap/ProgressBar';
import './PlacementRating.css'



const ratingChanged = (newRating) => {
    console.log(newRating)
}
const PlacementRating = () => {
    const now = 60;
    return (
        <section className='common-univeresity'>
            <Row>
                <Col xl={4} lg={4} md={4} sm={6} xs={6}>
                    <div className='d-flex'>
                        <h5>Overall Placement Rating</h5>
                        {/* <p>(Out of 5)</p>*/}
                    </div>
                    <div className='d-flex'>
                        <ReactStars
                            count={1}
                            onChange={ratingChanged}
                            size={50}
                            color2={'#ffd700'} />
                        <p className='rating-number mt-3 ms-3'>4.7</p>
                    </div>
                    <div >
                        <h5>Distribution of Rating</h5>
                        <Row className='mt-4'>
                            <Col xl={4} lg={4} md={4} sm={6} xs={6} >
                                <p className='blue-txtt'>4-5 star</p>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={6} xs={6} >
                                <ProgressBar now={now} label={`${now}%`} className="custom-progress-bar" />
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col xl={4} lg={4} md={4} sm={6} xs={6} >
                                <p className='blue-txtt'>3-4 star</p>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={6} xs={6} >
                                <ProgressBar now={now} label={`${now}%`} className="custom-progress-bar" />
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col xl={4} lg={4} md={4} sm={6} xs={6} >
                                <p className='blue-txtt'>2-3 star</p>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={6} xs={6} >
                                <ProgressBar now={now} label={`${now}%`} className="custom-progress-bar" />
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col xl={4} lg={4} md={4} sm={6} xs={6} >
                                <p className='blue-txtt'>1-2 star</p>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={6} xs={6} >
                                <ProgressBar now={now} label={`${now}%`} className="custom-progress-bar" />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xl={8} lg={8} md={8} sm={6} xs={6}>
                    <div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default PlacementRating