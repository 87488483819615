import React from "react";
import "./Online_degree_hover_card.css"

function Online_degree_hover_card() {
  return (
    <>
      <div className="Online_degree_hover_card">
        <div className="row">
          <div className="col-md-3">
            <div className="img-holder">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assests/Images/Hompage/onlinedegree/onlinedegree_img4.png"
                }
                className="online-degree-img"
              />
            </div>
          </div>

          <div className="col-md-9">
            <div className="text-holder">
              <div className="card-heading-holder">
                <h3>Cost-Effective</h3>
              </div>
              <div className="content-holder">
                <p>
                  Online programs offer a more cost-effective solution since
                  they leverage digital resources for education, which
                  eliminates the need for commuting expenses and reduces
                  payments for campus facilities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Online_degree_hover_card;
