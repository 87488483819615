import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../CollegeInfo.css'

const Highlights = () => {
    return (
        <section className='common-univeresity'>
            <Container>

                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className='yellow-bgggg'>
                            <h5 className='sub-headdd'>Dr. D. Y. Patil Vidyapeeth, Pune Highlights</h5>
                            <p>D.Y. Patil College of Engineering or DYPCOE is a private college located in Pune, Maharashtra. Affiliated with Savitribai Phule Pune University, it came into existence in 1984. D.Y. Patil College of Engineering offers a total of 22 courses in the Engineering stream at UG and PG levels across nine departments.DYPCOE courses are recognised by the AICTE. Moreover, the college is also accredited by NAAC with an ‘A’ grade</p>
                            <p>Over the years, DYPCOE has been approved by different ranking agencies like it has been ranked 45 by India Today for Private Engineering in 2021. D.Y. Patil College of Engineering has collabora ted with 40 companies to strengthen the industry-academia relationship. Also, the college also has an Innovation Cell to promote innovation among the students where they are encouraged to convert their raw ideas into a concept and finally into a prototype. Tabulated below are the D.Y. Patil College of Engineering highlights 2022:</p>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <img src={process.env.PUBLIC_URL + '/assests/Images/University/DY.png'} className='uni-img' />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Highlights