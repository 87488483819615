// Breadcrumb_holder.js
import React from 'react';
import './Breadcrumb_holder.css';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

const Breadcrumb_holder = ({ breadcrumbs }) => {
  return (
    <Nav className="Breadcrumb_holder">
      <ul>
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index}>
            {breadcrumb.path ? (
              <Link to={breadcrumb.path}>{breadcrumb.label}</Link>
            ) : (
              breadcrumb.label
            )}
          </li>
        ))}
      </ul>
    </Nav>
  );
};

export default Breadcrumb_holder;
