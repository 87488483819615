import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import * as animationData from './celebration.json';

function CelebrationAnimation() {
    const [isModalOpen, setIsModalOpen] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsModalOpen(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        isModalOpen && (
            <section className='main'>
                <div className='vdo-btn'>
                    <Lottie className="me-auto"
                        height={120}
                        width={200}
                        options={defaultOptions}
                    />
                </div>
            </section>
        )
    );
}

export default CelebrationAnimation;