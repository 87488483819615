import React from 'react'

const WithoutLoginBanner = () => {
    return (
        <section className='without-login'>
            
        </section>
    )
}

export default WithoutLoginBanner