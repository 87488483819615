import React from 'react'
import SettingBanner from './SettingBanner/SettingBanner'
import AccountSetting from './AccountSetting/AccountSetting'

const Setting = () => {
    return (
        <>
            <SettingBanner />
            <AccountSetting />
        </>
    )
}

export default Setting