import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Form, Modal, Nav, Tab } from 'react-bootstrap'
import Select from 'react-select';


// Mode
const modeoptions = [
    { value: 'Online', label: 'Online' },
    { value: 'Offline', label: 'Offline' },
];

const streamoptions = [
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Diploma', label: 'Diploma' },
];

const specializationoptions = [
    { value: 'Computer Engineering', label: 'Computer Engineering' },
    { value: 'Mba', label: 'Mba' },
];
const CourseLevelOne = (props) => {
    const [showForm, setShowForm] = useState(false);
    const [showDetails, setshowDetails] = useState(false);
    const [selectedModeOption, setSelectedModeOption] = useState(null);
    const [selectedStreamOption, setSelectedStreamOption] = useState(null);
    const [SpecializationOptions, setSpecializationOptions] = useState(null);
    return (
        <section>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className='change-pass-modal common-modalll-class'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='modal-header-class'>
                        Create Education Preferences
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='form-holder'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Course Level*</Form.Label>
                        </Form.Group>
                        <div>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="pills" className="flex-row">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">UG</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">PG</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Advanced Masters</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="four">Doctorate</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="five">Post Doctorate</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="six">Certificate</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Tab.Container>
                            <div className='brown-border mt-4 mb-3'></div>
                            <div className='d-flex justify-content-between mt-3'>
                                <p className='title-details'>Admission Year, City, Budget</p>
                                <p className='blue-txttt' onClick={() => setShowForm(!showForm)}>
                                    <FontAwesomeIcon icon={faPlus} /> Add
                                </p>
                            </div>
                            {showForm && (
                                <div>
                                    <Form>
                                        {/* <Form.Label>Admission Year,City,Budget</Form.Label> */}
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="text" placeholder="Year of Admission" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="text" placeholder="City" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="text" placeholder="Budget" />
                                        </Form.Group>
                                    </Form>
                                    <div className='brown-border mt-4 mb-3'></div>
                                    <div className='d-flex justify-content-between mt-3'>
                                        <p className='title-details '>Stream, Course & Mode</p>
                                        <p className='blue-txttt' onClick={() => setshowDetails(!showDetails)}>
                                            <FontAwesomeIcon icon={faPlus} /> Add
                                        </p>
                                    </div>
                                </div>
                            )}
                            {showDetails && (
                                <Form className='form-holder'>
                                    <Select
                                        defaultValue={selectedModeOption}
                                        onChange={setSelectedModeOption}
                                        options={modeoptions}
                                        placeholder="Mode of Study"
                                        className='mb-3'
                                    />
                                    <Select
                                        defaultValue={selectedStreamOption}
                                        onChange={setSelectedStreamOption}
                                        options={streamoptions}
                                        placeholder="Stream"
                                        className='mb-3'
                                    />
                                    <Select
                                        defaultValue={SpecializationOptions}
                                        onChange={setSpecializationOptions}
                                        options={specializationoptions}
                                        placeholder="Specialization"
                                        className='mb-3'
                                    />
                                   
                                </Form>
                            )}

                        </div>
                        <div className='text-center mt-5'>
                            <button className='orange-btnnn me-2'>Save</button>
                            <button className='white-btnnn'>Cancel</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default CourseLevelOne