import React from 'react'
import SecondSection from './SecondSection/SecondSection'
import { Col, Container, Row } from 'react-bootstrap'
import ApplyNowBanner from '../../../../Common_Component/ApplyNowBanner/ApplyNowBanner'
import StudentReviews from '../InfrastructureTab/StudentReviews/StudentReviews'
import InfraReviews from '../InfrastructureTab/InfraReviews/InfraReviews'

const FacultyTab = () => {
    return (
        <section className='common-univeresity'>
            <Container>
                <Row>
                    <Col xl={9} lg={8} md={8} sm={12} xs={12}>
                        <InfraReviews/>
                        <p>All faculty members at Dr D Y Patil Vidyapeeth Pune are very good and highly qualified. The faculty members have complete knowledge of their subject & years of research experience at top universities abroad. They provide practical knowledge so that students can understand more precisely. Tabulated below is the list of faculty members along with their designation and department:</p>
                        <SecondSection />
                        <div className='blk-border'></div>
                        <StudentReviews/>
                    </Col>

                    <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                        <ApplyNowBanner />
                        <div className='mt-2'>
                            <ApplyNowBanner />
                        </div>
                        <div className='mt-2'>
                            <ApplyNowBanner />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default FacultyTab