import React from 'react'
import { Col, Row } from 'react-bootstrap'
import PdfAnimation from './PdfAnimation/PdfAnimation'

const PlacementBrochure = () => {
    return (
        <section className='common-univeresity'>
            <h5 className='heading'>DY Patil Talegaon Placement Brochure 2022</h5>
            <p>The placement details for the 2022 drive are released on the DY Patil Ambi. Click on the below link to download the report:</p>
            <Row className='mt-5'>
                <Col lg={6} md={6} sm={6} xs={6}>
                    <PdfAnimation />
                </Col>
                <Col lg={6} md={6} sm={6} xs={6}>
                    <div className='text-center'>
                        <h5 className='sub-headdd'>DY Patil Talegaon Placement Report 2022</h5>
                        <button className='download-pdf'>Download PDF</button>
                    </div>                  
                </Col>
            </Row>
        </section>
    )
}

export default PlacementBrochure