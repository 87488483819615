import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Faq.css";
import CommonBanner from "../Common_Component/CommonBanner/CommonBanner";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import Common_btn from "../Common_Component/Common_btn/Common_btn";
import { Form } from "react-bootstrap";
import Breadcrumb_holder from "../Common_Component/Breadcrumb_holder/Breadcrumb_holder";

const faqItems = [
  {
    question: "1. Is there an entrance test for admission?",
    answer: (
      <ul>
        <li>
          <p>
            No. There is no entrance test for the admission. However, there is a
            selection process to enroll in ICFAI’s online MBA Program.
          </p>
        </li>
        <li>
          <p>
            Candidates will undergo a selection process, comprising Micro
            Presentation and a Personal Interview, which will be conducted
            online within a specified time window. For further details, please
            visit on{" "}
            <Link to="https://link.com/in" target="_blank">
              www.link.com
            </Link>
          </p>
        </li>
      </ul>
    ),
  },
  {
    question: "2. Is there an entrance test for admission?",
    answer: (
      <ul>
        <li>
          <p>
            No. There is no entrance test for the admission. However, there is a
            selection process to enroll in ICFAI’s online MBA Program.
          </p>
        </li>
        <li>
          <p>
            Candidates will undergo a selection process, comprising Micro
            Presentation and a Personal Interview, which will be conducted
            online within a specified time window. For further details, please
            visit on{" "}
            <Link to="https://link.com/in" target="_blank">
              www.link.com
            </Link>
          </p>
        </li>
      </ul>
    ),
  },
  {
    question: "3. Is there an entrance test for admission?",
    answer: (
      <ul>
        <li>
          <p>
            No. There is no entrance test for the admission. However, there is a
            selection process to enroll in ICFAI’s online MBA Program.
          </p>
        </li>
        <li>
          <p>
            Candidates will undergo a selection process, comprising Micro
            Presentation and a Personal Interview, which will be conducted
            online within a specified time window. For further details, please
            visit on{" "}
            <Link to="https://link.com/in" target="_blank">
              www.link.com
            </Link>
          </p>
        </li>
      </ul>
    ),
  },
  {
    question: "4. Is there an entrance test for admission?",
    answer: (
      <ul>
        <li>
          <p>
            No. There is no entrance test for the admission. However, there is a
            selection process to enroll in ICFAI’s online MBA Program.
          </p>
        </li>
        <li>
          <p>
            Candidates will undergo a selection process, comprising Micro
            Presentation and a Personal Interview, which will be conducted
            online within a specified time window. For further details, please
            visit on{" "}
            <Link to="https://link.com/in" target="_blank">
              www.link.com
            </Link>
          </p>
        </li>
      </ul>
    ),
  },
  {
    question: "5. Is there an entrance test for admission?",
    answer: (
      <ul>
        <li>
          <p>
            No. There is no entrance test for the admission. However, there is a
            selection process to enroll in ICFAI’s online MBA Program.
          </p>
        </li>
        <li>
          <p>
            Candidates will undergo a selection process, comprising Micro
            Presentation and a Personal Interview, which will be conducted
            online within a specified time window. For further details, please
            visit on{" "}
            <Link to="https://link.com/in" target="_blank">
              www.link.com
            </Link>
          </p>
        </li>
      </ul>
    ),
  },
];

function Faq() {
  const [openKey, setOpenKey] = useState(null);

  const toggleIcon = (key) => (openKey === key ? faCircleMinus : faCirclePlus);

  const handleSelect = (key) => {
    setOpenKey(openKey === key ? null : key);
  };


  const breadcrumbs = [
    { label: "Home", path: "/" },
    { label: "Frequently Asked Questions" }
  ];

  return (
    <>
      <div className="Faq">
        <CommonBanner
          bannerimg={'/assests/Images/All-Banner/terms-banner.png'}
          bannertext={"Frequently Asked Questions"} />

        <div className="container">
          <Breadcrumb_holder breadcrumbs={breadcrumbs} />
          <div className="faq-content-holder">
            <div className="row">
              <div className="col-md-6">
                <div className="faq-holder">
                  <Accordion activeKey={openKey} onSelect={handleSelect}>
                    {faqItems.map((item, index) => (
                      <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>
                          {item.question}
                          <FontAwesomeIcon
                            icon={toggleIcon(index.toString())}
                            className="fa-icon"
                          />
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="text-holder">{item.answer}</div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
              <div className="col-md-6">
                <div className="Any-Questions">
                  <div className="img-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assests/Images/Faq/faq.png"
                      }
                      className="faq-img"
                      alt="faq-img"
                    />
                  </div>
                  <div className="text-holder">
                    <h3 className="faq-heading">Any Questions?</h3>
                    <p className="faq-subheading">
                      We are here for you, until your doubts are cleared!
                    </p>
                  </div>

                  <Form className="form-holder">
                    <Form.Group className="mb-3" controlId="formBasicMsg">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Write your query here"
                      />
                    </Form.Group>

                    <Common_btn className={"send-btn"} text={"Submit"} />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
