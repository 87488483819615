import React from 'react'
import './CollegeInfo.css'
import Highlights from './Highlights/Highlights'
import TabulatedHighlight from './TabulatedHighlight/TabulatedHighlight'
import PopularProgramm from './PopularProgramm/PopularProgramm'

const CollegeInfo = () => {
    return (
        <>
            <Highlights />
            <TabulatedHighlight />
            <PopularProgramm />
        </>
    )
}

export default CollegeInfo