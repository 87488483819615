import React from 'react'
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Col, Row } from 'react-bootstrap';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const PlacedTrends = () => {

    const data = {
        labels: ['2020', '2021', '2022'],
        datasets: [
            {
                label: 'Placement (In Numbers)',
                data: [600, 650, 1300,],
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
                barThickness: 100,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'DY Patil Talegaon Student Placed Trends',
                font: {
                    size: 25,
                },
                color: '#707070',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 1400,
                title: {
                    display: true,
                    text: 'Placement (In Numbers)',
                    font: {
                        size: 20,
                    },
                    color: '#707070',
                },
            },
        },
    };

    return (
        <section className='common-univeresity'>
            {/* Highest Package Trends */}
            <h5 className='heading'>DY Patil Talegaon Batch Placed 2022</h5>
            <p>The institute has released the 2022 placement details on its official website. Students can have a look at the 2022 placement data given in the table below:</p>
            <div className='fee-structure'>
                <div className='white-back'>
                    <div className='yellow-bgggg'>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <p className='bold-txtt-blue'>Particulars</p>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <p className='bold-txtt-blue'>Statistics (2022)</p>
                            </Col>
                        </Row>
                    </div>
                    <div className='white-bggg-inner'>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <p className='normal-txttt'>Students placed</p>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <p className='normal-txttt'>1300+</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <p className='normal-txttt'>Companies participated</p>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                <p className='normal-txttt'>50+</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                    </div>
                </div>
            </div>
            {/* Placed Trends */}
            <h5 className='heading mt-5'>DY Patil Talegaon Batch Placed Trends</h5>
            <p>Students placed was the same in 2020 and 2021. However, in 2022 it increased by nearly 117%. On the contrary, a fluctuating trend was observed in the participation of companies over the past three years. For detailed analysis check the below table mentioning the DY Patil Ambi placement trends:</p>
            <div className='fee-structure mt-5'>
                <div className='white-back'>
                    <div className='yellow-bgggg'>
                        <Row>
                            <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                <p className='bold-txtt-blue'>Parameter</p>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                <p className='bold-txtt-blue'>2020</p>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                <p className='bold-txtt-blue'>2021</p>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                <p className='bold-txtt-blue'>2022</p>
                            </Col>
                        </Row>
                    </div>
                    <div className='white-bggg-inner'>
                        <Row>
                            <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                <p className='normal-txttt'>Students placed</p>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                <p className='normal-txttt'>600+</p>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                <p className='normal-txttt'>600+ </p>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                <p className='normal-txttt'>1300+ </p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                <p className='normal-txttt'>Companies participated</p>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                <p className='normal-txttt'>40+</p>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                <p className='normal-txttt'>25+ </p>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                <p className='normal-txttt'>50+</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                    </div>
                </div>


            </div>
            <p className='mt-5'>An increasing trend was observed in the placement offers made by popular companies over the past three years. See the below infographic for DY Patil Talegaon students placed trends:</p>
            <div className='white-bggg mt-5'>
                <Bar data={data} options={options} />
            </div>
        </section>
    )
}

export default PlacedTrends;
