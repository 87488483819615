import React from 'react'
import CommonBanner from '../../../../../Common_Component/CommonBanner/CommonBanner'

const ActivityStatusBanner = () => {
    return (
        <section>
            <CommonBanner />
        </section>
    )
}

export default ActivityStatusBanner