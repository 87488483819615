import React from 'react'
import ApplyNowBanner from '../../Common_Component/ApplyNowBanner/ApplyNowBanner'
import { Col, Container, Row } from 'react-bootstrap'
import OverViewFirstSection from './OverViewFirstSection/OverViewFirstSection'
import MbaHighlights from './MbaHighlights/MbaHighlights'
import OfferingCourse from './OfferingCourse/OfferingCourse'
import Specialization from './Specialization/Specialization'
import GetCounsilling from './GetCounsilling/GetCounsilling'
import PopularComparision from './PopularComparision/PopularComparision'


const OverViewTab = () => {
    return (
        <section>
            <Container>
                <Row>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12} >
                        <ApplyNowBanner />
                        <div>
                            <ApplyNowBanner />
                        </div>
                    </Col>
                    <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                        <OverViewFirstSection />
                        <MbaHighlights />
                        <OfferingCourse />
                        <Specialization />
                        <GetCounsilling />
                        <PopularComparision />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default OverViewTab