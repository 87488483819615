import React, { useState } from 'react'
import { Col, Form, Modal, Nav, Row, Tab } from 'react-bootstrap'
import './LevelOne.css'
import { faPen, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeleteEducationDataModal from '../DeleteEducationDataModal/DeleteEducationDataModal'


const LevelOne = (props) => {
    const [showForm, setShowForm] = useState(false);
    const [deletemodal, setDeleteModal] = useState(false);
    return (
        <section>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className='change-pass-modal common-modalll-class'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='modal-header-class'>
                        Education Background
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='form-holder'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Course Level*</Form.Label>
                        </Form.Group>
                        <div>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="pills" className="flex-row">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">10</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">12</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">UG</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="four">PG</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="five">PHD</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Tab.Container>
                        </div>
                        <div className='brown-border mt-4 mb-3'></div>
                        <div className='d-flex justify-content-between'>
                            <p className='title-details'>Work Experience</p>
                            <p className='blue-txttt' onClick={() => setShowForm(!showForm)}>
                                <FontAwesomeIcon icon={faPlus} /> Add
                            </p>
                        </div>
                        {showForm && (
                            <div>
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control type="text" placeholder="Board*" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control type="text" placeholder="School*" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control type="date" placeholder="Passout Year" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control type="text" placeholder="Grading System*" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control type="text" placeholder="Score" />
                                    </Form.Group>
                                </Form>

                                <div className='grey-bgggg mt-5 mb-5'>
                                    <div className='d-flex justify-content-between'>
                                        <span className='user-deatils-first'>BBA Corporate Finance</span>
                                        <div>
                                            <FontAwesomeIcon icon={faTrashCan} onClick={() => setDeleteModal(true)} className='social-icon me-2' />
                                            <FontAwesomeIcon icon={faPen} className='social-icon'  />
                                        </div>
                                        <DeleteEducationDataModal
                                            show={deletemodal}
                                            onHide={() => setDeleteModal(false)}
                                        />
                                    </div>
                                    <div>
                                        <span className='user-deatils-secon'>Dr. D.Y. Patil Vidyapeeth, Pune</span>
                                        <p className='user-deatils'>Scored 8.0 CGPA,2023</p>
                                    </div>
                                </div>

                            </div>
                        )}
                        <div className='text-center'>
                            <button className='orange-btnnn me-2'>Save</button>
                            <button className='white-btnnn'>Cancel</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default LevelOne