import React from 'react'
import { Button } from 'react-bootstrap'
import "./Common_btn.css"

function Common_btn({className, text, onClick}) {
  return (
  <>
    <div className='common-btn-holder'>
        <Button className={className} onClick={onClick}>{text}</Button>
    </div>
  </>
  )
}

export default Common_btn