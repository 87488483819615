import React from 'react'
import CommonBanner from '../../Common_Component/CommonBanner/CommonBanner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap'
import './UniversityDetailsBanner.css'

const UniversityDetailsBanner = () => {
    return (
        <section className='university-banner'>
            <CommonBanner bannerimg={'/assests/Images/All-Banner/universitybanner.png'} />
            <div className='banner-divvv'>
                <Row>
                    <Col lg={5} md={4} sm={6} xs={3}>
                        <div className='main-img'>
                            <img src={process.env.PUBLIC_URL + '/assests/Images/University/dpu.png'} className='uni-imgg' alt="university logo" />
                        </div>
                    </Col>
                    <Col lg={7} md={8} sm={6} xs={9}>
                        <p className='uni-txtt'>Dr. D. Y. Patil Univerisity</p>
                        <div className='d-flex'>
                            <p className='txtt'><FontAwesomeIcon icon={faLocationDot} /> Pimpri, Pune</p>
                            <div className='white-border'></div>
                            <p className='txtt'>#1 NIRF</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default UniversityDetailsBanner