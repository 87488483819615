import React from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'

const AdmissionDate = () => {
  return (
    <section className='common-univeresity'>
      <div>
        <h5 className='uni-sec-title'>DY Patil Pune Admission Dates 2024</h5>
        <p>Dr. D. Y. Patil, Pune, offers various Undergraduate, Postgraduate, and Ph.D. courses in various specialisations. Admission to these courses is based on merit or various national, state, and university-level entrance exams. Candidates can look at the table below to know more about the important dates for admission to Dr D Y Patil Vidyapeeth:</p>
      </div>
      <div className='Faq'>
        <div className='faq-content-holder'>
          <div className='faq-holder'>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>MBA/PGDM Admissions 2024</Accordion.Header>
                <Accordion.Body>
                  <p>5 Courses | 2 years</p>
                  <p><img src={process.env.PUBLIC_URL + '/assests/Images/icon/Icon feather-check-square.png'} className='mark-icon me-2' />Eligibility</p>
                  <p>Undergraduate Degree with 50% aggregate</p>
                  <p>Accepting Exams: MAH CET, MAT, XAT, CAT, CMAT, ATMA, AIET</p>
                  <div className='dark-bgg-yellow'>
                    <Row>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className='white-bggg'>
                          <div className='d-flex justify-content-between'>
                            <p className='normal-txtt'><img src={process.env.PUBLIC_URL + '/assests/Images/icon/desk-chair.png'} className='chair-img me-2' />Seat Intake</p>
                            <p className='normal-txtt'>540</p>
                          </div>
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className='white-bggg'>
                          <div className='d-flex justify-content-between'>
                            <p className='normal-txtt'><img src={process.env.PUBLIC_URL + '/assests/Images/icon/database.png'} className='chair-img me-2' />Total Tuition Fees</p>
                            <p className='normal-txtt'>₹ 1.3L - 6.1L</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div>
                    </div>

                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>B.E. / B.Tech Admissions 2024</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>MBBS Admissions 2024</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                  minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

          </div>

        </div>

      </div>
    </section>
  )
}

export default AdmissionDate