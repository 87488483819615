import React from 'react'
import './PopularComparision.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import ReactStars from "react-rating-stars-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';

const PopularComparision = () => {

    const ratingChanged = (newRating) => {
        console.log(newRating);
    };
    return (
        <section className='popular-comparision'>
            <h5 className='heading'>Popular comparisons for Online MBA</h5>
            <Swiper
                spaceBetween={50}
                pagination={{ clickable: true, dynamicBullets: true }}
                breakpoints={{
                    0: { slidesPerView: 1 },
                    768: { slidesPerView: 1 },
                    991: { slidesPerView: 1.5},
                    1440: { slidesPerView: 2 },
                    1600: { slidesPerView: 2 },
                }}
                modules={[Pagination]}
                className="myswiper mt-5"
            >
                <SwiperSlide className='mb-5' >
                    <div className='blue-bggg'>
                        <Row className='section-class'>
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <div className='first-card'>
                                    <div className='grey-bgggg text-center'>
                                        <img src={process.env.PUBLIC_URL + '/assests/Images/University/dpu.png'} className='uni-img' />
                                    </div>
                                    <div className='below-section'>
                                        <p className='uni-txttt'>Dr. D. Y. Patil Vidyapeeth, Pune</p>
                                        <p className='course-txtt'>MBA in Human Resources</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex'>
                                                <ReactStars
                                                    count={1}
                                                    onChange={ratingChanged}
                                                    size={15}
                                                    activeColor="#ffd700"
                                                />
                                                <p className='course-txtt'>4.5</p>
                                            </div>
                                            <div className='d-flex '>
                                                <FontAwesomeIcon icon={faMessage} className='icon mt-1 me-1' />
                                                <span className='course-txtt'>(53)</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <div className='first-card'>
                                    <div className='grey-bgggg text-center'>
                                        <img src={process.env.PUBLIC_URL + '/assests/Images/University/cu-logo.png'} className='uni-img' />
                                    </div>
                                    <div className='below-section'>
                                        <p className='uni-txttt'>Chandigarh University, Pune</p>
                                        <p className='course-txtt'>MBA in Human Resources</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex'>
                                                <ReactStars
                                                    count={1}
                                                    onChange={ratingChanged}
                                                    size={15}
                                                    activeColor="#ffd700"
                                                />
                                                <p className='course-txtt'>4.5</p>
                                            </div>
                                            <div className='d-flex '>
                                                <FontAwesomeIcon icon={faMessage} className='icon mt-1 me-1' />
                                                <span className='course-txtt'>(53)</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                            <div className='vc-class'>
                                <p className='vs-txtt'>Vs.</p>
                            </div>
                        </Row>
                        <div className='text-center'>
                            <button className='white-btnnn mt-4'>Compare</button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='blue-bggg'>
                        <Row className='section-class'>
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <div className='first-card'>
                                    <div className='grey-bgggg text-center'>
                                        <img src={process.env.PUBLIC_URL + '/assests/Images/University/dpu.png'} className='uni-img' />
                                    </div>
                                    <div className='below-section'>
                                        <p className='uni-txttt'>Dr. D. Y. Patil Vidyapeeth, Pune</p>
                                        <p className='course-txtt'>MBA in Human Resources</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex'>
                                                <ReactStars
                                                    count={1}
                                                    onChange={ratingChanged}
                                                    size={15}
                                                    activeColor="#ffd700"
                                                />
                                                <p className='course-txtt'>4.5</p>
                                            </div>
                                            <div className='d-flex '>
                                                <FontAwesomeIcon icon={faMessage} className='icon mt-1 me-1' />
                                                <span className='course-txtt'>(53)</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <div className='first-card'>
                                    <div className='grey-bgggg text-center'>
                                        <img src={process.env.PUBLIC_URL + '/assests/Images/University/cu-logo.png'} className='uni-img' />
                                    </div>
                                    <div className='below-section'>
                                        <p className='uni-txttt'>Chandigarh University, Pune</p>
                                        <p className='course-txtt'>MBA in Human Resources</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex'>
                                                <ReactStars
                                                    count={1}
                                                    onChange={ratingChanged}
                                                    size={15}
                                                    activeColor="#ffd700"
                                                />
                                                <p className='course-txtt'>4.5</p>
                                            </div>
                                            <div className='d-flex '>
                                                <FontAwesomeIcon icon={faMessage} className='icon mt-1 me-1' />
                                                <span className='course-txtt'>(53)</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                            <div className='vc-class'>
                                <p className='vs-txtt'>Vs.</p>
                            </div>
                        </Row>
                        <div className='text-center'>
                            <button className='white-btnnn mt-4'>Compare</button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='blue-bggg'>
                        <Row className='section-class'>
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <div className='first-card'>
                                    <div className='grey-bgggg text-center'>
                                        <img src={process.env.PUBLIC_URL + '/assests/Images/University/dpu.png'} className='uni-img' />
                                    </div>
                                    <div className='below-section'>
                                        <p className='uni-txttt'>Dr. D. Y. Patil Vidyapeeth, Pune</p>
                                        <p className='course-txtt'>MBA in Human Resources</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex'>
                                                <ReactStars
                                                    count={1}
                                                    onChange={ratingChanged}
                                                    size={15}
                                                    activeColor="#ffd700"
                                                />
                                                <p className='course-txtt'>4.5</p>
                                            </div>
                                            <div className='d-flex '>
                                                <FontAwesomeIcon icon={faMessage} className='icon mt-1 me-1' />
                                                <span className='course-txtt'>(53)</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <div className='first-card'>
                                    <div className='grey-bgggg text-center'>
                                        <img src={process.env.PUBLIC_URL + '/assests/Images/University/cu-logo.png'} className='uni-img' />
                                    </div>
                                    <div className='below-section'>
                                        <p className='uni-txttt'>Chandigarh University, Pune</p>
                                        <p className='course-txtt'>MBA in Human Resources</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex'>
                                                <ReactStars
                                                    count={1}
                                                    onChange={ratingChanged}
                                                    size={15}
                                                    activeColor="#ffd700"
                                                />
                                                <p className='course-txtt'>4.5</p>
                                            </div>
                                            <div className='d-flex '>
                                                <FontAwesomeIcon icon={faMessage} className='icon mt-1 me-1' />
                                                <span className='course-txtt'>(53)</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                            <div className='vc-class'>
                                <p className='vs-txtt'>Vs.</p>
                            </div>
                        </Row>
                        <div className='text-center'>
                            <button className='white-btnnn mt-4'>Compare</button>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>

        </section>
    )
}

export default PopularComparision