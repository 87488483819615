
import React from 'react'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Form, InputGroup, Nav, Row, Tab } from 'react-bootstrap'
import CollegeInfo from './UniversityInnerTab/CollegeInfo/CollegeInfo'
import CoursesAndFees from './UniversityInnerTab/CoursesAndFees/CoursesAndFees'
import AdmissionTab from './UniversityInnerTab/AdmissionTab/AdmissionTab'
import RankingTabs from './UniversityInnerTab/RankingTabs/RankingTabs'
import PlacementTab from './UniversityInnerTab/PlacementTab/PlacementTab'
import FacultyTab from './UniversityInnerTab/FacultyTab/FacultyTab'
import OverallRating from './UniversityInnerTab/OverallRating/OverallRating'
import InfrastructureTab from './UniversityInnerTab/InfrastructureTab/InfrastructureTab'


const UniversityDetailsTab = () => {
  return (
    <section className='course-deatils-tab'>
      <Container>
        <Row className='justify-content-center'>
          <Col lg={5}>
            <div className='search-fieldd'>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search by Course / Specialization / University"
                  aria-label="Search by Course / Specialization / University"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Text id="basic-addon2"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
              </InputGroup>
            </div>
          </Col>
        </Row>       
        <div >
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Nav variant="pills" className="flex-row justify-content-center mt-4" >
                <Nav.Item>
                  <Nav.Link eventKey="first">College Info</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Courses & Fees</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Admission</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="four">Rankings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="five">Placements</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="six">Overall Ratings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="seven">Infrastructure</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="eight">Faculty</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="nine">Compare</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first"><CollegeInfo/></Tab.Pane>
                <Tab.Pane eventKey="second"><CoursesAndFees/></Tab.Pane>
                <Tab.Pane eventKey="third"><AdmissionTab/></Tab.Pane>
                <Tab.Pane eventKey="four"><RankingTabs/></Tab.Pane>
                <Tab.Pane eventKey="five"><PlacementTab/></Tab.Pane>
                <Tab.Pane eventKey="six"><OverallRating/></Tab.Pane>
                <Tab.Pane eventKey="seven"><InfrastructureTab/></Tab.Pane>
                <Tab.Pane eventKey="eight"><FacultyTab/></Tab.Pane>
                <Tab.Pane eventKey="nine"></Tab.Pane>
              </Tab.Content>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </section>
  )
}

export default UniversityDetailsTab