import React from 'react'
import { Col, Row } from 'react-bootstrap'

const AveragePackage = () => {
    return (
        <section className='common-univeresity '>
            <h5 className='heading'>DY Patil Talegaon Average Package 2022</h5>
            <p>The institute has not released its average package details of 2022 placements. Meanwhile, students can have a look at the median package offered during DY Patil Ambi placements 2022 to get an estimate:</p>
            <div className='fee-structure mt-5'>
                <div className='white-back'>
                    <div className='yellow-bgggg'>
                        <Row>
                            <Col xl={4} lg={4} md={4}>
                                <p className='bold-txtt-blue'>Course</p>
                            </Col>
                            <Col xl={8} lg={8} md={8}>
                                <p className='bold-txtt-blue'>Median Package (2022)</p>
                            </Col>
                        </Row>
                    </div>
                    <div className='white-bggg-inner'>
                        <Row>
                            <Col xl={4} lg={4} md={4}>
                                <p className='blue-txtt'>B.Tech</p>
                            </Col>
                            <Col xl={8} lg={8} md={8}>
                                <p className='normal-txttt'>INR 6.3 LPA</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                        <Row>
                            <Col xl={4} lg={4} md={4}>
                                <div className='d-flex'>

                                    <p className='blue-txtt'>M.Tech </p>
                                    <span className='normal-txttt ms-4'>Median package</span>
                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={8}>
                                <p className='normal-txttt'>INR 5.4 LPA</p>
                            </Col>
                        </Row>
                        <div className='dash-border mt-2'></div>
                    </div>
                </div>
            </div>
            <div className='mt-4 ms-4'>
                <p className='note-txttt'>NOTE:<span className='note-span'>The aforementioned details are collated from the NIRF report 2023.</span> </p>
            </div>
        </section>
    )
}

export default AveragePackage