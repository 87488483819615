import React from "react";
import "./Online_Degree.css";
import Heading_holder from "../../Common_Component/Heading_holder/Heading_holder";
import Online_degree_card from "../../Common_Component/Online_degree_card/Online_degree_card";
import Online_degree_hover_card from "../../Common_Component/Online_degree_card/Online_degree_hover_card/Online_degree_hover_card";

const cardData = [
  {
    image: "/assests/Images/Hompage/onlinedegree/onlinedegree_img1.png",
    cardtext: "Cost-Effective",
  },
  {
    image: "/assests/Images/Hompage/onlinedegree/onlinedegree_img2.png",
    cardtext: "Cost-Effective",
  },
  {
    image: "/assests/Images/Hompage/onlinedegree/onlinedegree_img3.png",
    cardtext: "Cost-Effective",
  },
  {
    image: "/assests/Images/Hompage/onlinedegree/onlinedegree_img4.png",
    cardtext: "Cost-Effective",
  },
];

function Online_Degree() {
  return (
    <div className="Online_Degree">
      <div className="container">
        <Heading_holder
          headingtext={"...and so, you should go for Online Degree"}
        />
        <div className="degree">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row">
                {cardData.map((card, index) => (
                  <>
                    <div className="col-md-3 " key={index}>
                      <div className="main-card-holder">
                        <Online_degree_card
                          image={process.env.PUBLIC_URL + card.image}
                          cardtext={card.cardtext}
                        />
                        <div className="hover-card-holder">
                          <div className="row justify-content-center">
                            <div className="col-md-10">
                              <Online_degree_hover_card />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Online_Degree;
