import React from 'react'
import './AllFilterTab.css'
import { Accordion, Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import ApplyNowBanner from '../../../Common_Component/ApplyNowBanner/ApplyNowBanner'

const AllFiltersTab = () => {
  return (
    <section className='filter-tab'>
      <div className='clear-all-tab d-flex justify-content-between'>
        <p className='clear-all-txtt'><img src={process.env.PUBLIC_URL + '/assests/Images/icon/filter.png'} className='filter-icon me-2' /> All Filters</p>
        <p className='clear-all-txtt-span'>Clear All</p>
      </div>
      <Form>
        <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlInput1">
          <Form.Control type="text" placeholder="Enter Keywords" />
        </Form.Group>
        <p></p>
      </Form>
      <div className='below-section'>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Location</Accordion.Header>
            <Accordion.Body>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <Form className='form-holder'>
                <Form.Check type='checkbox' label='Delhi' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Pune' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Mumbai' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Nagpur' />
              </Form>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className='mt-3'>
            <Accordion.Header>Specialization</Accordion.Header>
            <Accordion.Body>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <Form className='form-holder'>
                <Form.Check type='checkbox' label='Delhi' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Pune' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Mumbai' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Nagpur' />
              </Form>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className='mt-3'>
            <Accordion.Header>Total Fees</Accordion.Header>
            <Accordion.Body>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <Form className='form-holder'>
                <Form.Check type='checkbox' label='Delhi' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Pune' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Mumbai' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Nagpur' />
              </Form>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className='mt-3'>
            <Accordion.Header>Mode of Study</Accordion.Header>
            <Accordion.Body>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <Form className='form-holder'>
                <Form.Check type='checkbox' label='Delhi' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Pune' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Mumbai' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Nagpur' />
              </Form>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className='mt-3'>
            <Accordion.Header>Ownership</Accordion.Header>
            <Accordion.Body>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <Form className='form-holder'>
                <Form.Check type='checkbox' label='Delhi' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Pune' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Mumbai' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Nagpur' />
              </Form>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className='mt-3'>
            <Accordion.Header>Exam Accepted</Accordion.Header>
            <Accordion.Body>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <Form className='form-holder'>
                <Form.Check type='checkbox' label='Delhi' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Pune' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Mumbai' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Nagpur' />
              </Form>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className='mt-3'>
            <Accordion.Header>Rating</Accordion.Header>
            <Accordion.Body>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <Form className='form-holder'>
                <Form.Check type='checkbox' label='Delhi' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Pune' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Mumbai' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Nagpur' />
              </Form>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7" className='mt-3'>
            <Accordion.Header>Course</Accordion.Header>
            <Accordion.Body>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <Form className='form-holder'>
                <Form.Check type='checkbox' label='Delhi' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Pune' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Mumbai' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Nagpur' />
              </Form>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8" className='mt-3'>
            <Accordion.Header>Credential</Accordion.Header>
            <Accordion.Body>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <Form className='form-holder'>
                <Form.Check type='checkbox' label='Delhi' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Pune' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Mumbai' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Nagpur' />
              </Form>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9" className='mt-3'>
            <Accordion.Header>Crouse Level</Accordion.Header>
            <Accordion.Body>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <Form className='form-holder'>
                <Form.Check type='checkbox' label='Delhi' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Pune' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Mumbai' />
                <div className='blk-border'></div>
                <Form.Check type='checkbox' label='Nagpur' />
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className='mt-3'>
        <ApplyNowBanner />
      </div>

    </section>
  )
}

export default AllFiltersTab
