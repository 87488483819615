import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    Title,
} from 'chart.js';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    Title
);

const Alumni = () => {
    const data = {
        labels: [
            'Engineering',
            'Information Technology',
            'Business Development',
            'Operations',
            'Education'
        ],
        datasets: [
            {
                label: 'DY Patil Talegaon Alumni Placements',
                data: [53, 21, 11, 9, 6],
                backgroundColor: [
                    'rgba(54, 162, 235, 0.6)',
                    'orange',
                    '#707070',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 205, 86, 0.6)',
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(201, 203, 207, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 205, 86, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    font: {
                        size: 14,
                    },
                },
            },
            title: {
                display: true,
                text: 'DY Patil Talegaon Alumni Placements',
                font: {
                    size: 25,
                },
            },
        },
    };

    return (
        <section className='common-univeresity'>
            <h5 className='heading'>DY Patil Talegaon Alumni Placements</h5>
            <p>Here's the distribution of DY Patil Talegaon alumni across various fields:</p>
            <div className='white-bggg mt-5'>
                <div style={{ width: '450px', margin: '0 auto' }}>
                    <Pie data={data} options={options} />
                </div>
            </div>
        </section>
    );
};

export default Alumni;
