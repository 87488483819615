

import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const PackageTrends = () => {
    const data = {
        labels: ['BTech', 'MTech'],
        datasets: [
            {
                label: '2020',
                data: [5.7, 4.5],
                backgroundColor: 'rgba(54, 162, 235, 0.6)', 
            },
            {
                label: '2021',
                data: [5.9, 4.9],
                backgroundColor: 'orange', 
            },
            {
                label: '2022',
                data: [6.3, 5.4],
                backgroundColor: '#707070', 
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'DY Patil Talegaon Median Package Trends',
                font: {
                    size: 25,
                },
                color: '#707070',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 7,
                title: {
                    display: true,
                    text: 'Salary (In INR LPA)',
                    font: {
                        size: 20,
                    },
                    color: '#707070',
                },
            },
        },
    };

    return (
        <section className='common-univeresity'>
            <h5 className='heading'>DY Patil Talegaon Median Package Trends</h5>
            <p>The median package offered to students for BTech and MTech over the past three years.</p>
            <div className='white-bggg mt-5'>
                <Bar data={data} options={options} />
            </div>
        </section>
    );
};

export default PackageTrends;
