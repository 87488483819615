import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./CollegeStep.css";

const CollegeStep = () => {
    return (
        <>
            <section className="CollegeStep">
                <div className="banner-image">
                    <img
                        src={process.env.PUBLIC_URL + "/assests/Images/Hompage/CollegeStep/stepbanner7.png"}
                        className="banner2"
                        alt="Banner"
                    />
                    {/* <div className="overlay"></div> */}
                    <Container>
                        <Row className="justify-content-center">
                            <Col xxl={12}>
                                <div className="content">
                                    <p className="heading">Profcyma College Compare</p>
                                    <h4 className="onesteppp">Your One Stop Solution Guide</h4>
                                    <p className="sub-text">Few Simple Click, to make your mark!</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="main-step-below-section">
                        <Container>
                            <Col lg={12} >
                                <Row className="mx-auto justify-content-center">
                                    <Col lg={2} md={4} sm={6} xs={12}>
                                        <div className="main-white-bgg">
                                            <div className="white-bgg">
                                                <h2 className="white-bgg-title">Step 1</h2>
                                                <p className="select-txttt">Select your desired course</p>
                                            </div>
                                            <div className="border-bottom-orange"></div>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={4} sm={6} xs={12}>
                                        <div className="main-white-bgg">
                                            <div className="white-bgg">
                                                <h2 className="white-bgg-title">Step 2</h2>
                                                <p className="select-txttt">Fill the basic details</p>
                                            </div>
                                            <div className="border-bottom-orange"></div>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={4} sm={6} xs={12}>
                                        <div className="main-white-bgg">
                                            <div className="white-bgg">
                                                <h2 className="white-bgg-title">Step 3</h2>
                                                <p className="select-txttt">Explore your options</p>
                                            </div>
                                            <div className="border-bottom-orange"></div>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={4} sm={6} xs={12}>
                                        <div className="main-white-bgg">
                                            <div className="white-bgg">
                                                <h2 className="white-bgg-title">Step 4</h2>
                                                <p className="select-txttt">Compare and get the best</p>
                                            </div>
                                            <div className="border-bottom-orange"></div>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={4} sm={6} xs={12}>
                                        <div className="main-white-bgg">
                                            <div className="white-bgg">
                                                <h2 className="white-bgg-title">Step 5</h2>
                                                <p className="select-txttt">With you, in your journey</p>
                                            </div>
                                            <div className="border-bottom-orange"></div>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={4} sm={6} xs={12}>
                                        <div className="main-white-bgg">
                                            <div className="white-bgg">
                                                <h2 className="white-bgg-title">Step 6</h2>
                                                <p className="select-txttt">With you, in your journey</p>
                                            </div>
                                            <div className="border-bottom-orange"></div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Container>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CollegeStep;
