import React, { useState } from 'react';
import './DeactivateAccount.css';
import { Form, Modal } from 'react-bootstrap';
import SettingSuccessful from '../../../../Common_Component/SettingSuccessful/SettingSuccessful';

const DeactivateAccount = (props) => {
    const [selectedReason, setSelectedReason] = useState('');
    const [settingsuccessful, SetSettingSuccessful] = useState(false)

    const handleReasonChange = (e) => {
        setSelectedReason(e.target.value);
    };

    return (
        <section>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className='change-pass-modal common-modalll-class'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='modal-header-class'>
                        Deactivate Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img src={process.env.PUBLIC_URL + '/assests/Images/Profile/women.png'} className='women-image' alt='Profile' />
                    </div>
                    <div className='text-center mt-2'>
                        <h6 className='deacti-title'>Take a break from Profcyma College Compare for a while!</h6>
                        <p className='deacti-subtitle'>You'll be unsubscribing to all emails and notifications with information on colleges and exams. You'll regain access once you log in</p>
                    </div>
                    <div className='mt-5'>
                        <h6 className='deacti-title'>Tell us why you want to deactivate your account</h6>
                        <Form>
                            <Form.Check
                                type='radio'
                                label='I am getting many calls/Messages. You can choose which mail you receive from Profcyma College Compare go to email preference setting to control'
                                value='callsMessages'
                                checked={selectedReason === 'callsMessages'}
                                onChange={handleReasonChange}
                            />
                            <Form.Check
                                type='radio'
                                label='I have already taken admission in college'
                                value='admission'
                                checked={selectedReason === 'admission'}
                                onChange={handleReasonChange}
                            />
                            <Form.Check
                                type='radio'
                                label='Found the exam information that I was looking for'
                                value='examInfo'
                                checked={selectedReason === 'examInfo'}
                                onChange={handleReasonChange}
                            />
                            <Form.Check
                                type='radio'
                                label='I dont like College Recommendations I get'
                            value='collegeRecommendations'
                            checked={selectedReason === 'collegeRecommendations'}
                            onChange={handleReasonChange}
                            />
                            <Form.Check
                                type='radio'
                                label='Other'
                                value='other'
                                checked={selectedReason === 'other'}
                                onChange={handleReasonChange}
                            />
                            {selectedReason === 'other' && (
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows={3} className='ms-0 ms-md-4 mt-3' />
                                </Form.Group>
                            )}
                        </Form>
                    </div>
                    <div className='text-center'>
                        <button className='orange-btnnn me-2 mt-4' onClick={() => SetSettingSuccessful(true)}>Confirm</button>
                        <button className='white-btnnn'>Cancel</button>
                    </div>
                    <SettingSuccessful
                        titletext='Account Deactivated Successfully!'
                        subtitletext='Hopping to see you back soon!'
                        show={settingsuccessful}
                        onHide={() => SetSettingSuccessful(false)}
                    />
                </Modal.Body>
            </Modal>
        </section>
    );
};

export default DeactivateAccount;
